import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { LoadingButton } from '../../components/common';
import { FormBootstrapInput } from '../../components/form';
import ProfileActions from '../../redux/profile';
import { ChangePasswordSchema } from './schema';

const INITIAL_VALUES = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
};

function ChangePasswordForm() {
  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting }) => {
    await dispatch(ProfileActions.changePassword(values.currentPassword, values.newPassword));
    setSubmitting(false);
  };

  return (
    <div className="change-password-form formik-wrap">
      <div className="header">
        <h4>Change Your Password</h4>
      </div>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={ChangePasswordSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-control">
              <label className="form-label" htmlFor="currentPassword">Current Password</label>
              <Field component={FormBootstrapInput} type="password" name="currentPassword" autoComplete="off" />
            </div>

            <div className="form-control">
              <label className="form-label" htmlFor="newPassword">New Password</label>
              <Field component={FormBootstrapInput} type="password" name="newPassword" autoComplete="off" />
            </div>

            <div className="form-control">
              <label className="form-label" htmlFor="confirmNewPassword">Confirm New Password</label>
              <Field component={FormBootstrapInput} type="password" name="confirmNewPassword" autoComplete="off" />
            </div>

            <div className="form-control form-submit">
              <LoadingButton loading={isSubmitting} size="large" type="submit">Change Password</LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ChangePasswordForm;
