import api from './api';
import { shop } from '../libs/storage';

export default () => {
  const $ = api;
  const getStore = () => {
    const search = document.location.search;
    return $.get(`/auth/shopify/store${search}`);
  }

  const getProducts = (limit, page_info) => $.get(`/app/shopify/products/list?shop=${shop()}&limit=${limit}&page_info=${page_info}`);
  const searchProducts = (query, cursor) => $.post(`/app/shopify/products/search?shop=${shop()}`, { query, cursor });

  // Linking user and store
  const addUser = (shopDomain, storeId) => $.post(`/app/stores/${storeId}/add-user?shop=${shopDomain}`);

  // Getting stores for agency
  const getAgencyStores = () => $.get(`/app/stores/get-agency-stores`);
  // Switch stores for agency
  const switchStore = (shop) => $.post(`/app/stores/switch-store?shop=${shop()}`);
  
  // Customer Tags
  const getCustomerTags = () => $.post(`/app/customer-tags?shop=${shop()}`);

  return {
    getStore,
    getProducts,
    searchProducts,
    addUser,
    getAgencyStores,
    switchStore,
    getCustomerTags
  };
};
