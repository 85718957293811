import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { LoadingButton } from '../../components/common';
import { FormBootstrapInput } from '../../components/form';
import ProfileActions, { ProfileSelectors } from '../../redux/profile';
import { PersonalInformationSchema } from './schema';
import { splitName } from '../../libs/utils';

function PersonalInformationForm() {
  const dispatch = useDispatch();
  const user = useSelector(ProfileSelectors.selectProfile);

  const INITIAL_VALUES = {
    name: `${user.firstName} ${user.lastName}`.trim(),
    email: user.email
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const { firstName, lastName } = splitName(values.name);
    const p = {
      firstName,
      lastName,
      email: values.email
    }

    await dispatch(ProfileActions.updateProfile(p));
    setSubmitting(false);
  };

  return (
    <div className="personal-information-form formik-wrap">
      <div className="header">
        <h4>Personal Information</h4>
      </div>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={PersonalInformationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-control">
              <label className="form-label" htmlFor="name">Your Name</label>
              <Field component={FormBootstrapInput} name="name" autoComplete="off" />
            </div>
            <div className="form-control">
              <label className="form-label" htmlFor="email">Email Address</label>
              <Field component={FormBootstrapInput} name="email" autoComplete="off" />
            </div>
            <div className="form-control form-submit">
              <LoadingButton loading={isSubmitting} type="submit">Update Profile</LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default PersonalInformationForm;
