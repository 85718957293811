import React from 'react';
import AuthLayout from '../../containers/layout/AuthLayout';
import { Link as ReactLink } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import {
  Box,
  Paper,
  Button
} from '@material-ui/core';
import { getItem } from '../../libs/storage-wrapper';

function VerifyEmail() {

  const authEmail = getItem('AUTH_EMAIL');

  return (
    <AuthLayout name="auth verify-email-page">
      <Box component={Paper} p={6} elevation={0} className="box-shadow login-box">
        <h2>Please verify your email</h2>
        <Alert className="static-alert" icon={false} severity="success">You're almost there! We've sent an email to:<br/><b>{authEmail}</b></Alert>
        <p>The key to unlocking the power of your first-party data is in your inbox. Simply click on the link in the email. If you don't see it, you may need to <b>check your spam</b> folder.</p>
        <p>Still can't find the email?</p>

        <Button fullWidth={true} component={ReactLink} to='/resend-email'>Resend Email</Button>

        <div className="login-footer">
          <p>Already have an account? <ReactLink to="/login">Sign in here</ReactLink></p>
        </div>
      </Box>
    </AuthLayout>
  );
}

export default VerifyEmail;
