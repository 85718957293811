import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as ReactLink } from "react-router-dom";
import {
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import CheckIcon from "@material-ui/icons/Check";
import MainLayout from "../../containers/layout/MainLayout";
import global from "../../redux/GlobalRedux";
import StoreActions, { StoreSelectors } from "../../redux/store/index";
import ProfileActions from "../../redux/profile";
import { formatDate } from "../../libs/utils";
import {
  LoadingContainer,
  LoadingButton,
  Alert,
} from "../../components/common";
import { getUserId } from "../../libs/storage";
import shopify from "../../libs/shopify";
import history from "../../libs/history";

function AgencyStores() {
  const dispatch = useDispatch();
  const rows = useSelector(StoreSelectors.selectAgencyStores);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const user = getUserId();
  const availableStores = rows ? 5 + user.extraStores - rows.length : 0;

  useEffect(() => {
    const loadAgencyStores = () => {
      dispatch(StoreActions.loadAgencyStores());
    };

    loadAgencyStores();
  }, [dispatch]);

  const handleSwitchAccount = (shop) => () => {
    dispatch(StoreActions.switchStore(shop));
  };

  const handleUpgrade = () => {
    setLoading(true);
    return shopify.addExtra().then(
      async (res) => {
        setLoading(false);
        await dispatch(ProfileActions.loadProfile());
        history.push("/my-stores/add" + window.location.search);
      },
      (err) => {
        dispatch(
          global.setAlert({
            message: err.message,
            severity: "error",
          })
        );
        setLoading(false);
      }
    );
  };

  const renderTableRow = (row, i) => (
    <TableRow component="div" key={i}>
      <TableCell component="div" scope="row" align="center">
        {i + 1}
      </TableCell>
      <TableCell component="div" align="left">
        {row.name}
      </TableCell>
      <TableCell component="div" align="left">
        {row.domain}
      </TableCell>
      <TableCell component="div" align="left">
        {row.details.shop_owner}
      </TableCell>
      <TableCell component="div" align="left">
        {row.email}
      </TableCell>
      <TableCell component="div" align="left">
        {formatDate(row.createdAt)}
      </TableCell>
      <TableCell component="div" align="center" className="row-actions">
        <Box className="action-menu">
          {user.selectedStore === row.id ? (
            <label className="action-label">
              <CheckIcon /> Selected
            </label>
          ) : (
            <Link onClick={handleSwitchAccount(row.domain)}>
              Switch to this store
            </Link>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );

  const renderTable = () => (
    <TableContainer component={Paper} elevation={0}>
      <Table className="table no-spacing filled-th" component="div">
        <TableHead component="div">
          <TableRow component="div">
            <TableCell component="div" align="center" width="140px">
              ID
            </TableCell>
            <TableCell component="div" align="left" width="18%">
              Name
            </TableCell>
            <TableCell component="div" align="left" width="18%">
              Domain
            </TableCell>
            <TableCell component="div" align="left" width="14%">
              Owner Name
            </TableCell>
            <TableCell component="div" align="left" width="14%">
              Owner Email
            </TableCell>
            <TableCell component="div" align="left" width="14%">
              Date Added
            </TableCell>
            <TableCell component="div">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody component="div">
          {rows && rows.map((row, i) => renderTableRow(row, i))}
        </TableBody>
      </Table>
      {rows && rows.length === 0 && (
        <div className="no-rows">No Stores Added</div>
      )}
    </TableContainer>
  );

  return (
    <MainLayout className="my-stores-page" title="My Stores">
      <LoadingContainer />
      <Alert openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} />

      <div className="agency-info">
        <InfoIcon /> You have UNLIMITED plan for up to 5 stores and then
        $9.99/mo for each additional store.
      </div>

      <Box className="add-account">
        {availableStores === 0 ? (
          <>
            <LoadingButton
              loading={loading}
              className="add-row"
              onClick={handleUpgrade}
            >
              Upgrade and Add Store
            </LoadingButton>
          </>
        ) : (
          <>
            <Button
              className="add-row"
              to="/my-stores/add"
              component={ReactLink}
            >
              Add Store
            </Button>
          </>
        )}
      </Box>

      {renderTable()}

      {rows && (
        <div className="agency-info">
          <InfoIcon /> You can add {availableStores} more stores without extra
          charges.
        </div>
      )}
    </MainLayout>
  );
}

export default AgencyStores;
