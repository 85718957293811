import api from './api';
import { shop } from '../libs/storage';

export default () => {
  const $ = api;
  const getAba = id => $.get(`/app/abas/${id}?shop=${shop()}`);
  const getAbas = () => $.get(`/app/abas?shop=${shop()}`);
  const create = params => $.post(`/app/abas?shop=${shop()}`, params);
  const update = (id, params) => $.put(`/app/abas/${id}?shop=${shop()}`, params);
  const deleteAba = id => $.delete(`/app/abas/${id}?shop=${shop()}`);

  return {
    getAba,
    getAbas,
    create,
    update,
    deleteAba
  };
};
