import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Helmet } from "react-helmet";

function MainLayout(props) {
  const { title, children, ...rest } = props;
  const admin = window.location.pathname.startsWith('/admin') ? 1 : 0;

  return (
    <div className={`main-wrapper ${props.pclass}`}>
      <Box display="flex" flexDirection="column" className="main-layout">
        <Box {...rest}>
          {title && children}
        </Box>
        {!admin && (
          <Helmet>
            <script async id="ze-snippet" defer src="https://static.zdassets.com/ekr/snippet.js?key=58e4fdd7-ed71-4fb6-813f-dd78eb2bcbca"></script>
          </Helmet>
        )}
      </Box>
    </div>
  );
}

MainLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  pclass: PropTypes.string
};

export default MainLayout;
