import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as ReactLink } from "react-router-dom";
import {
  Link,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Divider,
} from "@material-ui/core";
import GlobalRedux, { GlobalSelectors } from "../../../redux/GlobalRedux";
import { ProfileSelectors } from "../../../redux/profile";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getItem } from "../../../libs/storage-wrapper";

function AccountMenu() {
  const dispatch = useDispatch();
  const user = useSelector(ProfileSelectors.selectProfile);
  const store = getItem("STORE");
  const isEmbedded = useSelector(GlobalSelectors.selectIsEmbedded);

  /* Account Menu */
  const [accountMenuOpen, setAccountMenuOpen] = React.useState(false);
  const anchorAccountMenuRef = React.useRef(null);
  const handleAccountMenuToggle = () => {
    setAccountMenuOpen((prevOpen) => !prevOpen);
  };
  const handleAccountMenuClose = (event) => {
    if (
      anchorAccountMenuRef.current &&
      anchorAccountMenuRef.current.contains(event.target)
    ) {
      return;
    }
    setAccountMenuOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevAccountMenuOpen = React.useRef(accountMenuOpen);
  React.useEffect(() => {
    if (
      prevAccountMenuOpen.current === true &&
      accountMenuOpen === false &&
      anchorAccountMenuRef.current
    ) {
      anchorAccountMenuRef.current.focus();
    }
    prevAccountMenuOpen.current = accountMenuOpen;
  }, [accountMenuOpen]);
  /* End of Account Menu */

  const logout = (event) => {
    handleAccountMenuClose(event);
    dispatch(GlobalRedux.logout());
  };

  return (
    <div className="dropdown-menu" open={accountMenuOpen}>
      <Button
        className="white-btn"
        ref={anchorAccountMenuRef}
        aria-controls={accountMenuOpen ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleAccountMenuToggle}
        endIcon={<ExpandMoreIcon />}
      >
        {user.pricing === "agency" && store
          ? `${store.name}`
          : `${user.firstName} ${user.lastName}`.trim()}
      </Button>
      <Popper
        open={accountMenuOpen}
        anchorEl={anchorAccountMenuRef.current}
        role={undefined}
        placement="bottom-end"
        className="action-menu"
        transition
        disablePortal
        style={{ width: "200px" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className="box-shadow-2">
              <ClickAwayListener onClickAway={handleAccountMenuClose}>
                <MenuList autoFocusItem={accountMenuOpen} id="menu-list-grow">
                  <MenuItem onClick={handleAccountMenuClose}>
                    <ReactLink to={"/settings/my-profile"}>
                      My Profile
                    </ReactLink>
                  </MenuItem>
                  <MenuItem onClick={handleAccountMenuClose}>
                    <Link
                      href={"https://synctap.zendesk.com/hc/en-us"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Help
                    </Link>
                  </MenuItem>
                  {!isEmbedded && <Divider /> }
                  {!isEmbedded && (
                    <MenuItem onClick={logout}>
                      <Link>Logout</Link>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default AccountMenu;
