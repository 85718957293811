import { createReducer, createActions } from 'reduxsauce';
import apiCreate from '../../services/ad_account';
import global from '../GlobalRedux';
import { setItem } from '../../libs/storage-wrapper';

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setAdAccounts: ['adAccounts'],
  setAdAccountsByPlatform: ['adAccountsByPlatform'],
  setAdAccountsForConnection: ['adAccountsForConnection'],
  setPixels: ['pixels'],
});

Creators.loadAdAccounts = () => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));
    
    const api = apiCreate();
    const resp = await api.getAdAccounts();
    if (resp.ok) {
      const adAccounts = resp.data;
      
      dispatch(Creators.setAdAccounts(adAccounts));
      setItem("AD_ACCOUNTS", adAccounts);
    } else {
      dispatch(global.apiError(resp));
    }
    dispatch(global.setSubLoading(false));
  };
};

Creators.loadAdAccountsByPlatform = () => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));
    
    const api = apiCreate();
    const resp = await api.getAdAccountsByPlatform();
    if (resp.ok) {
      const adAccounts = resp.data;
      
      dispatch(Creators.setAdAccountsByPlatform(adAccounts));
    } else {
      dispatch(global.apiError(resp));
    }
    dispatch(global.setSubLoading(false));
  };
};

Creators.loadAdAccountsForConnection = (partner, accessToken) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.getAdAccountsForConnection(partner, accessToken);
    if (resp.ok) {
      resp.data.length === 0 ?
        dispatch(global.setAlert({
          message: "We couldn't find any ad accounts to connect. Please try again.",
          severity: "error",
        })) :
        dispatch(Creators.setAdAccountsForConnection(resp.data));
    } else {
      dispatch(global.apiError(resp));
    }
    dispatch(global.setSubLoading(false));
  };
};

Creators.loadPixels = () => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.getPixels();
    if (resp.ok) {
      const result = resp.data && resp.data.map(v => {
        v.name = `${v.name} (${v.id})`;
        return v;
      });

      dispatch(Creators.setPixels(result));
    } else {
      dispatch(global.apiError(resp));
    }
    dispatch(global.setSubLoading(false));
  };
};

Creators.create = (params, callback = null) => {
  return async dispatch => {
    const api = apiCreate();
    const resp = await api.create(params);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your ad account has been added successfully!",
        severity: "success",
      }));
    } else {
      dispatch(global.setAlert({
        message: resp.data.message,
        severity: "error",
      }));
      dispatch(global.apiError(resp));
    }

    if (callback) callback();
    dispatch(global.setDataRefresh(Math.random()));
  };
};

Creators.connectAccount = (params) => {
  return async dispatch => {
    const api = apiCreate();
    const resp = await api.connectAccount(params);
    if (resp.ok) {
      dispatch(Creators.loadAdAccountsByPlatform());
    } else {
      dispatch(global.apiError(resp));
    }
  };
};

Creators.connectAdAccount = (params) => {
  return async dispatch => {
    const api = apiCreate();
    const resp = await api.connectAdAccount(params);
    if (resp.ok) {
      dispatch(Creators.loadAdAccountsByPlatform());
    } else {
      dispatch(global.apiError(resp));
    }
  };
};

Creators.disconnectAccount = (id) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.disconnectAccount(id);
    if (resp.ok) {
      dispatch(Creators.loadAdAccountsByPlatform());
    } else {
      dispatch(global.apiError(resp));
      dispatch(global.setSubLoading(false));
    }
  };
};

Creators.disconnectAdAccount = (id) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.disconnectAdAccount(id);
    if (resp.ok) {
      dispatch(Creators.loadAdAccountsByPlatform());
    } else {
      dispatch(global.apiError(resp));
      dispatch(global.setSubLoading(false));
    }
  };
};

Creators.acceptTermsAndConditions = (id) => {
  return async dispatch => {
    const api = apiCreate();
    const resp = await api.acceptTermsAndConditions(id);
    if (resp.ok) {
      dispatch(Creators.loadAdAccountsByPlatform());
    } else {
      dispatch(global.apiError(resp));
    }
  };
};

Creators.finishSetup = (id) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.finishSetup(id);
    if (resp.ok) {
      return true;
    } else {
      dispatch(global.apiError(resp));
      dispatch(global.setSubLoading(false));
    }
  };
};

Creators.refresh = id => {
  return async dispatch => {
    const api = apiCreate();
    const resp = await api.refresh(id);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your ad account has been resynced!",
        severity: "success",
      }));
    } else {
      dispatch(global.setAlert({
        message: resp.data.message,
        severity: "error",
      }));
      dispatch(global.apiError(resp));
    }
  };
};

Creators.delete = id => {
  return async dispatch => {
    dispatch(global.setButtonLoading(true));
    
    const api = apiCreate();
    const resp = await api.deleteAdAccount(id);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your ad account has been deleted!",
        severity: "success",
      }));
    } else {
      dispatch(global.setAlert({
        message: resp.data.message,
        severity: "error",
      }));
      dispatch(global.apiError(resp));
    }

    dispatch(global.setButtonLoading(false));
    dispatch(global.setDataRefresh(Math.random()));
  };
};

export const AdAccountTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const AdAccountSelectors = {
  selectAdAccounts: state => state.ad_account.adAccounts,
  selectAdAccountsByPlatform: state => state.ad_account.adAccountsByPlatform,
  selectAdAccountsForConnection: state => state.ad_account.adAccountsForConnection,
  selectPixels: state => state.ad_account.pixels,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  adAccounts: null,
  adAccountsByPlatform: null,
  adAccountsForConnection: null,
  pixels: null,
};

/* ------------------- Reducers --------------------- */
export const setAdAccounts = (state, { adAccounts }) => ({
  ...state,
  adAccounts
});

export const setAdAccountsByPlatform = (state, { adAccountsByPlatform }) => ({
  ...state,
  adAccountsByPlatform
});

export const setAdAccountsForConnection = (state, { adAccountsForConnection }) => ({
  ...state,
  adAccountsForConnection
});

export const setPixels = (state, { pixels }) => ({
  ...state,
  pixels
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_AD_ACCOUNTS]: setAdAccounts,
  [Types.SET_AD_ACCOUNTS_BY_PLATFORM]: setAdAccountsByPlatform,
  [Types.SET_AD_ACCOUNTS_FOR_CONNECTION]: setAdAccountsForConnection,
  [Types.SET_PIXELS]: setPixels
});
