const open = url => new Promise((resolve, reject) => {
  const myWindow = window
  const left = (window.screen.width - 1100) / 2
  const top = (window.screen.height - 800) / 4

  window.addEventListener('message', event => {
    const { data } = event;
    if (!data.status) return;

    if (event.origin.startsWith(`${process.env.REACT_APP_API_BASE}`) && data.status) { 
      data.status === "success" ? 
        myWindow.oauthSuccess(event.data) :
        myWindow.oauthFail(event.data)
    }
  });

  const authWindow = window.open(
    `${url}`,
    'oauth2',
    `height=800,width=1100,top=${top},left=${left}`
  )

  // watch close event
  const interval = setInterval(() => {
    if (authWindow.closed) {
      reject({status: 'error', message: "The process has been cancelled."})
      clearInterval(interval)
    }
  }, 500)

  if (window.focus) {
    authWindow.focus()
  }

  // make success, fail functions
  myWindow.oauthSuccess = data => {
    clearInterval(interval)
    authWindow.close()
    resolve(data)
  }

  myWindow.oauthFail = data => {
    clearInterval(interval)
    authWindow.close()
    reject(data)
  }
});

export default {
  open
}