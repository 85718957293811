import React from 'react';
import { useDispatch } from 'react-redux';
import StoreActions from '../../../redux/store/index';
import global from '../../../redux/GlobalRedux';
import { Formik, Form, Field } from 'formik';
import { AddShopifySchema } from '../../stores/schema';
import { LoadingButton } from '../../../components/common';
import {
  Box,
  InputAdornment
} from '@material-ui/core';
import { FormBootstrapInput } from '../../../components/form';
import shopify from '../../../libs/shopify';

const INITIAL_VALUES = {
  storeUrl: ''
};

export default function ConnectShopify(props) {
  const {
    store,
    setStore,
    handleComplete,
    showFormHeader,
  } = props;
  const dispatch = useDispatch();

  const handleSubmit = async (params) => {
    const shop = `${params.storeUrl}.myshopify.com`;

    return shopify.install(shop).then(async res => {
      const __store = await dispatch(StoreActions.addUserToStore(shop, res.storeId));
      setStore(__store);
      handleComplete(true, __store.adAccounts);
    }, err => {
      dispatch(global.setAlert({
        message: err.message,
        severity: "error"
      }));
    });
  };

  const renderStoreInfo = () => {
    if (!store) return null;
    return (
      <div className="connected-store">
        <div className="image">
          <img src="/images/shopify.png" alt="Shopify Logo" />
        </div>
        <div className="store-info">
          <h2 className="store-name">{store.details.name} was connected.</h2>
          <div className="store-url"><a href={`https://${store.domain}`} target="_blank" rel="noopener noreferrer">https://{store.domain}</a></div>
        </div>
      </div>
    );
  }

  const renderForm = (props) => {
    const { isSubmitting } = props;

    return (
      <Form>
        { showFormHeader && (
          <Box className="form-header">
            <h2>Enter your Shopify URL <img src="/images/shopify.png" alt="Shopify Logo" /></h2>
            <p className="title-note">If your store is <b>https://awesome-stuff.myshopify.com</b>, just enter <b>awesome-stuff</b>. You will automatically start on a free 14-day trial after approving the installation from Shopify.</p>
          </Box>
        )}
        
        <div className="form-control store large">
          <label className="form-label" htmlFor="storeUrl"></label>
          <Field
            component={FormBootstrapInput}
            name="storeUrl"
            InputProps={{
              endAdornment: <InputAdornment position="end">.myshopify.com</InputAdornment>,
            }}
          />
        </div>
        <div className="form-control form-submit store">
          <LoadingButton loading={isSubmitting} size="large" type="submit" className="connect">CONNECT STORE</LoadingButton>
        </div>
        <div className="form-footer">
        </div>
      </Form>
    );
  }

  return (
    <Box className="connect-shopify">
      {(store && store.accessToken && store.status !== 'needs-reauth') ? (
        renderStoreInfo()
      ) : (
        <Formik
          enableReinitialize
          initialValues={INITIAL_VALUES}
          onSubmit={handleSubmit}
          validationSchema={AddShopifySchema}
        >
          {props => renderForm(props)}
        </Formik>
      )}
    </Box>
  );
}