import React from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import AdAccountActions from '../../../redux/ad_account';
import { LoadingButton } from '../../../components/common';
import DoneIcon from '@material-ui/icons/Done';

export default function TermsAndConditions(props) {
  const {
    finishSetup,
    facebookAccount,
    googleAccount,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const acceptTermsAndConditions = async () => {
    setLoading(true);
    await dispatch(AdAccountActions.acceptTermsAndConditions(facebookAccount.id));
    setLoading(false);
  }

  if (facebookAccount) {
    return (
      <Box className="terms-and-conditions">
        {facebookAccount.tosAccepted ? (
          <>
            <p className="title-note">You have accepted Facebook's <a href={`https://business.facebook.com/ads/manage/customaudiences/tos/?act=${facebookAccount.adAccountId}`} target="_blank" rel="noopener noreferrer">terms of service</a> for using custom audiences.</p>
            <div className="welcome-section">
              <h2><DoneIcon /> Almost ready! Click "Finish Setup" to start using SyncTap.</h2>
              <LoadingButton
                onClick={finishSetup}
              >
                FINISH SETUP
              </LoadingButton>
            </div>
          </>
        ) : (
          <>
            <p className="title-note">Please accept Facebook's <a href={`https://business.facebook.com/ads/manage/customaudiences/tos/?act=${facebookAccount.adAccountId}`} target="_blank" rel="noopener noreferrer">terms of service</a> for using custom audiences.</p>
            <LoadingButton
              loading={loading}
              onClick={acceptTermsAndConditions}
            >
              I've accepted
            </LoadingButton>
          </>
        )}
      </Box>
    );
  } else if (googleAccount) {
    return (
      <Box className="terms-and-conditions">
        <>
          <div className="welcome-section">
            <h2><DoneIcon /> Almost ready! Click "Finish Setup" to start using SyncTap.</h2>
            <LoadingButton
              onClick={finishSetup}
            >
              FINISH SETUP
            </LoadingButton>
          </div>
        </>
      </Box>
    );
  }
}