import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { LoadingContainer } from '../../components/common';
import vars from '../../libs/vars';
import { setItem } from '../../libs/storage-wrapper';

function StripeDirectCheckout() {
  const dispatch = useDispatch();

  useEffect(() => {
    const redirectToCheckout = async () => {
      const stripePromise = loadStripe(vars.REACT_APP_STRIPE_PUBLISHABLE_KEY);
      const stripe = await stripePromise;
      const response = await fetch(`${vars.REACT_APP_API_BASE}/stripe/create-direct-session?pricing=agency&test=1`, { method: 'POST' });
      const session = await response.json();

      setItem('SESSION_ID', session.id);

      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        alert('Failed due to your browser or network error - ' + result.error.message);
      }
    };

    redirectToCheckout();
  }, [dispatch]);

  return (
    <LoadingContainer />
  );
}

export default StripeDirectCheckout;
