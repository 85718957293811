const InputFieldStyles = theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '&.Mui-error input ~ fieldset': {
      borderColor: `${theme.palette.error.main} !important`,
      borderWidth: 1,
    },
    '& input:valid:focus ~ fieldset': {
      borderWidth: 1,
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    '&.Mui-error ~ p': {
      color: `${theme.palette.error.main} !important`,
    },
    '& input ~ fieldset': {
      border: `1px solid #DDE0E6 !important`,
      transition: 'border-color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    height: '42px',
    padding: '4px 12px',
  },
  input: {
    borderRadius: 0,
    backgroundColor: theme.palette.common.white,
    border: 0,
    fontSize: 14,
    width: '100%',
    padding: 0,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: theme.typography.fontFamily,
    boxSizing: 'border-box',
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#666'
    }
  }
});

export default InputFieldStyles;