import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AdAccountActions, { AdAccountSelectors } from '../../../redux/ad_account';
import {
  FormHelperText,
} from '@material-ui/core';
import { Select } from '../../../components/common';

export default function PixelSelect(props) {
  const { pixel, setFieldValue, errors, touched } = props;

  const dispatch = useDispatch();
  const options = useSelector(AdAccountSelectors.selectPixels);
  
  useEffect(() => {
    const loadPixels = () => {
      dispatch(AdAccountActions.loadPixels());
    };
    
    loadPixels();
  }, [dispatch]);
  
  if (!options) return null;
  if (options.length === 1) return null;

  return (
    <div className="form-control">
      <label>Select pixel</label>
      <Select
        id="pixel-select"
        name="pixel-select"
        value={pixel}
        onChange={ (event) => {
          setFieldValue("pixel", event.target.value);
        }}
        options={options}
        placeholder="Pixels"
        width="500px"
        size="large"
      />
      {errors.pixel && touched.pixel && <FormHelperText error>{errors.pixel}</FormHelperText>}
    </div>
  );
}
