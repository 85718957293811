import api from './api';
import { shop } from '../libs/storage';

export default () => {
  const $ = api;
  const getLookalike = id => $.get(`/app/lookalikes/${id}?shop=${shop()}`);
  const getLookalikes = () => $.get(`/app/lookalikes?shop=${shop()}`);
  const create = params => $.post(`/app/lookalikes?shop=${shop()}`, params);
  const update = (id, params) => $.put(`/app/lookalikes/${id}?shop=${shop()}`, params);
  const deleteLookalike = id => $.delete(`/app/lookalikes/${id}?shop=${shop()}`);

  return {
    getLookalike,
    getLookalikes,
    create,
    update,
    deleteLookalike
  };
};
