import React from "react";
import { getItem } from "../../../libs/storage-wrapper";

export default function Platform({ row }) {
  return (
    <div className="platform-logos">
      {row.platforms &&
        row.platforms.map((v, i) => {
          if (v === "Facebook")
            return (
              <a
                key={i}
                href={`https://business.facebook.com/adsmanager/audiences?act=${getItem(
                  "AdAccountId"
                )}&ids=${row.customAudienceId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`/images/icons/${v.toLowerCase()}-logo@24x.svg`}
                  alt={v}
                />
              </a>
            );

          return null;
        })}
      {row.platforms &&
        row.platforms.map((v, i) => {
          if (v === "Google")
            return (
              <a
                key={i}
                href={`https://business.facebook.com/adsmanager/audiences?act=${getItem(
                  "AdAccountId"
                )}&ids=${row.customAudienceId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`/images/icons/${v.toLowerCase()}-logo@32x.png`}
                  alt={v}
                />
              </a>
            );

          return null;
        })}
    </div>
  );
}
