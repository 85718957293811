import { createReducer, createActions } from 'reduxsauce';
import apiCreate from '../../services/aba';
import global from '../GlobalRedux';

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setAba: ['aba'],
  setAbas: ['abas'],
});

Creators.loadAba = (id) => {
  return async dispatch => {
    if (id) {
      dispatch(global.setSubLoading(true));

      const api = apiCreate();
      const resp = await api.getAba(id);
      if (resp.ok) {
        dispatch(Creators.setAba(resp.data));
      } else {
        dispatch(global.apiError(resp));
      }
      
      dispatch(global.setSubLoading(false));
    } else {
      dispatch(Creators.setAba(null));
    }
  };
};

Creators.loadAbas = () => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.getAbas();
    if (resp.ok) {
      dispatch(Creators.setAbas(resp.data));
    } else {
      dispatch(global.apiError(resp));
    }
    
    dispatch(global.setSubLoading(false));
  };
};

Creators.delete = id => {
  return async dispatch => {
    dispatch(global.setButtonLoading(true));
    
    const api = apiCreate();
    const resp = await api.deleteAba(id);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your custom audience has been deleted!",
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setButtonLoading(false));
    dispatch(global.setDataRefresh(Math.random()));
  };
};

export const AbaTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const AbaSelectors = {
  selectAba: state => state.aba.aba,
  selectAbas: state => state.aba.abas
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  aba: null,
  abas: []
};

/* ------------------- Reducers --------------------- */
export const setAba = (state, { aba }) => ({
  ...state,
  aba
});

export const setAbas = (state, { abas }) => ({
  ...state,
  abas
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ABA]: setAba,
  [Types.SET_ABAS]: setAbas
});
