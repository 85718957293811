import { createReducer, createActions } from "reduxsauce";
import userApi from "../../services/user";
import storeApi from "../../services/store";
import global from "../GlobalRedux";
import {
  getUserToken,
  setUserToken,
  setUserId,
  setStoreId,
} from "../../libs/storage";
import { setItem } from "../../libs/storage-wrapper";

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setProfile: ["profile"],
});

const setProfileInfo = (data) => {
  setUserId(data);

  if (data.store) {
    setStoreId(data.store.domain);
    setItem("AD_ACCOUNTS", data.store.adAccounts);
    setItem("STORE", data.store);

    const adAccounts = data.store.adAccounts;
    if (!adAccounts) return;

    let fbAccounts = adAccounts.filter((v) => v.partner === "Facebook");
    if (fbAccounts.length > 0) {
      setItem("AdAccountId", fbAccounts[0].adAccountId);
    }
  } else {
    setItem("STORE", data.store);
  }
};

Creators.loadProfile = (isEmbedded) => {
  return async (dispatch) => {
    if (window.location.pathname === "/login") return;

    dispatch(global.setInitialLoading(true));

    let resp = null;
    if (window.location.pathname === "/install" || isEmbedded) {
      // Accessing from Shopify
      resp = await storeApi().getStore();

      if (resp.ok) {
        const user = resp.data.user;
        setUserToken(resp.data.token);
        setUserId(user);
        setProfileInfo(user);
        dispatch(Creators.setProfile(user));
      } else {
        dispatch(global.apiError(resp));
      }
    } else {
      // Accessing from app login directly
      if (getUserToken()) {
        resp = await userApi().getProfile();
        if (resp.ok) {
          setItem("EMBEDDED", false);
          setProfileInfo(resp.data);
          dispatch(Creators.setProfile(resp.data));
        } else {
          dispatch(global.apiError(resp));
        }
      }
    }

    dispatch(global.setInitialLoading(false));
  };
};

Creators.updateProfile = (params) => {
  return async (dispatch) => {
    const resp = await userApi().updateProfile(params);

    if (resp.ok) {
      const user = resp.data.user;
      setUserId(user);
      dispatch(Creators.setProfile(user));
      dispatch(
        global.setAlert({
          message: "Profile updated!",
          severity: "success",
        })
      );
    } else {
      dispatch(
        global.setAlert({
          message: resp.data,
          severity: "error",
        })
      );
    }
  };
};

Creators.changePassword = (currentPassword, newPassword) => {
  return async (dispatch) => {
    const resp = await userApi().changePassword(currentPassword, newPassword);

    if (resp.ok) {
      dispatch(
        global.setAlert({
          message: "Password updated!",
          severity: "success",
        })
      );
    } else {
      dispatch(
        global.setAlert({
          message: resp.data,
          severity: "error",
        })
      );
    }
  };
};

export const ProfileTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const ProfileSelectors = {
  selectProfile: (state) => state.profile.profile,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  profile: null,
};

/* ------------------- Reducers --------------------- */
export const setProfile = (state, { profile }) => ({
  ...state,
  profile,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PROFILE]: setProfile,
});
