import React from 'react';

export default function LookalikePreview(props) {
  const { values } = props;
  const { customAudience, countries, audienceSizes } = values; 

  if (!customAudience || !countries.length) return null;

  const size = countries.length * audienceSizes.length;
  let prevAudienceSize = 0;

  const genName = (p, t, e, customAudience) => {
    const prefix = values.namePrefix ? `${values.namePrefix} - ` : ''
    const suffix = values.nameSuffix ? ` - ${values.nameSuffix}` : ''
    return `${prefix}SyncTap Lookalike (${p.code}, ${t}-${e}%) - ${customAudience.name}${suffix}`
  }

  return (
    <div className="ca-preview form-control">
      <label>Preview ({size})</label>
      <ul className="ca-preview-list">
        {countries.map(p => {
          return audienceSizes.map(e => {
            const t = prevAudienceSize;
            Math.max(...audienceSizes) === e ? prevAudienceSize = 0 : prevAudienceSize = e;
            return (<li key={`${p.id}${e}`}>{`${genName(p, t, e, customAudience)}`}</li>);
          })
        })}
      </ul>
    </div>
  )
}