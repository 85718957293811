import { mixed, object, string, number, array, boolean } from 'yup';

export const CreateCbaValidationSchema = object().shape({
  name: string().required('Audience name is required!'),
  platforms: array().of(string()).required('Platform is required!'),
  operator: string().oneOf(['any', 'all']),
  criterias: array()
    .of(
      object().shape({
        field: string().required('Field is required!'),
        op: string()
          .when('field', {
            is: (field) => ['order-count', 'total-spent', 'average-order-value', 'product', 'tag', 'became-customer'].indexOf(field) >= 0,
            then: string().required('Operator is required!'),
            otherwise: string().notRequired(),
          }),
        value: mixed()
          .when('field', {
            is: (field) => ['order-count', 'last-purchase', 'no-purchases'].indexOf(field) >= 0,
            then: number().required().positive().integer(),
          })
          .when('field', {
            is: (field) => ['total-spent', 'average-order-value'].indexOf(field) >= 0,
            then: string().required()
              .test(
                'is-decimal',
                'invalid decimal',
                value => (value + "").match(/^[0-9]+([,.][0-9]+)?$/),
              ),
          })
          .when('field', {
            is: (field) => ['product'].indexOf(field) >= 0,
            then: array().of(object()).required(),
          })
          .when('field', {
            is: (field) => ['tag'].indexOf(field) >= 0,
            then: array().of(string()).required(),
          })
          .when('field', {
            is: (field) => ['became-customer'].indexOf(field) >= 0,
            then: string().required(),
          }),
        // value1: mixed()
        //   .when('field', {
        //     is: (field) => ['became-customer'].indexOf(field) >= 0,
        //     then: string().required(),
        //   }),
      })
    ),
  template: boolean(),
});

export const CreateAbaValidationSchema = object().shape({
  products: array().of(string())
    .when('events', {
      is: (events) => events.indexOf('page-view') >= 0,
      then: array().of(string()).notRequired(),
      otherwise: array().of(string()).required(),
    }),
  events: array().of(string()).required('Event is required!'),
  days: string().required('Day is required!')
});

export const CreateLookalikeFacebookValidationSchema = object().shape({
  customAudience: object().shape({
    id: string().required(),
    name: string().required(),
  }).required('Custom audience is required!'),
  countries: array().of(string()).required('At least one country is required!'),
  audienceSizes: number().required('Audience size is required!'),
});

export const CreateLookalikeGoogleValidationSchema = object().shape({
  customAudience: object().shape({
    id: string().required(),
    name: string().required(),
  }).required('Custom audience is required!'),
});