import numeral from 'numeral';
import moment from 'moment-timezone';
import { getItem } from './storage-wrapper';
import { getUserId } from './storage';

export function round(n) {
  if (n === null || n === 0) return 0;
  return n.toFixed(2);
}

export function truncate( n, useWordBoundary = false ) {
  if (this.length <= n) { return this; }
  var subString = this.substr(0, n-1);
  return (useWordBoundary 
     ? subString.substr(0, subString.lastIndexOf(' ')) 
     : subString) + "...";
};

export const percent = (a, b) => {
  const value = a ? formatNumber((b / a) * 100) : 0

  return numeral(value).format(`,0.00`) + '%';
}

export const formatNumber = (num, prefix = '') => {
  if (!num || isNaN(num)) {
    return `${prefix}0`
  }

  return numeral(num).format(`${prefix},0.[00]`);
}

export const hexColor = str => {
  var hash = 0, i = 0;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
  }
  var color = '#';
  for (i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 255;
      color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

export const formatCurrency = num => formatNumber(num, '$')

export const formatDate = (
  date,
  format = 'MM/DD/YY HH:mm:ss'
) => {
  return date ? 
    moment(date)
      .tz(process.env.REACT_APP_TIME_ZONE)
      .format(format)
    : '';
}

export const formatTime = date => formatDate(date, 'MM/DD/YY HH:mm:ss');

export const unix2Date = unix => moment.unix(unix).format("YYYY-MM-DD HH:mm:ss");

export const params = key => new URLSearchParams(window.location.search).get(key);

export const reapplyNum = rows => {
  rows.map((row, n) => {
    row.num = n < 9 ? `0${n + 1}` : `${n + 1}`;
    return row;
  });

  return rows;
}

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const refineDays = days => {
  let _days = [];
  days = days.replace(/\s/g, '').split(",");
  days = days.map(v => parseInt(v));

  days.forEach(function(item) {
    if (!isNaN(item) && _days.indexOf(item) < 0) {
      _days.push(item);
    }
  });

  _days.sort(function(a, b){
    return a - b;
  });

  return _days.join(',');
}

export const addHttps = url => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = "https://" + url;
  }
  return url;
}

export const getActiveDays = user => {
  // user -> store info including createdAt date
  if (user && user.createdAt) {
    return moment().diff(moment(user.createdAt), 'days') + 1;
  } else {
    return 0;
  }
}

export const splitName = name => {
  return {
    firstName: name.split(' ').slice(0, 1).join(' ').trim(),
    lastName: name.split(' ').slice(1).join(' ').trim()
  }
}

export const isAdAccountConnected = user => {
  if (user) {
    const ad = getItem('AD_ACCOUNTS');
    if (ad && ad.length) return true;
  }

  return false;
}

export const redirectTo = to => {
  if (window.location.pathname !== to) window.location.href = to;
}

export const getFullName = () => {
  const user = getUserId();

  return `${user.firstName} ${user.lastName}`.trim();
}