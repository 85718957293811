import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Slider } from '@material-ui/core';
import { SimpleSelect } from '../../../components/common';
import { AudienceSize } from '../../../libs/const';
import ReactTooltip from "react-tooltip";
import InfoIcon from '@material-ui/icons/Info';

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
    marginLeft: '10px',
    marginRight: '10px',
    width: 'calc(100% - 20px)',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#FFF',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 0,
  },
  rail: {
    height: 8,
    borderRadius: 0,
  },
})(Slider);

const SliderMarks = Array.from(Array(21), (x, i) => ({value: i, label: `${i}%`}));

const SliderValueText = (value) => {
  return `${value}%`;
}

const InitialAudienceSizes = (size, value) => {
  let spaces = Array.from(Array(20), (x, i) => i + 1).filter(x => !size.includes(x));
  
  if (size.length > value) {
    size.splice(value);
    return size;
  } else {
    if (Math.max(...size) === 20) {
      spaces.splice(value - size.length);
      return [...size, ...spaces];
    } else if ((20 - Math.max(...size)) >= (value - size.length)) {
      return [...size, ...Array.from(Array(value - size.length), (x, i) => i + 1 + Math.max(...size))];
    } else {
      spaces.splice(value - size.length);
      return [...size, ...spaces];
    }
  }
}

export default function AudienceSizeSelect(props) {
  const { value, setFieldValue } = props;

  const handleAudienceSizeChange = () => e => {
    setFieldValue("audienceSizes", InitialAudienceSizes(value, e.target.value));
  }

  const handleSliderChange = (event, newValue) => {
    setFieldValue("audienceSizes", newValue);
  };

  return (
    <div className="as-container form-control">
      <label>Select number of lookalike audiences</label>
      
      <div className="audience-size-select-wrap">
        <div className="row">
          <label>
            Number of lookalike audiences
            <InfoIcon
              data-tip='You can create multiple lookalikes with different levels of similarity to your source. This allows you to bid differently for audiences with different conversion values. <a href="https://www.facebook.com/business/help/924049801000037">Learn More</a>'
              data-for="tooltip_audience_size"
              data-html={true}
            />
          </label>
          
          <ReactTooltip
            id="tooltip_audience_size"
            place="right"
            type="dark"
            effect="solid"
            clickable
            delayHide={400}
          />
          <SimpleSelect
            id="audience-size-select"
            value={value.length}
            options={AudienceSize}
            onChange={handleAudienceSizeChange()}
            width="120px"
            size="small"
          />
        </div>
        <PrettoSlider
          valueLabelDisplay="auto"
          value={value}
          marks={SliderMarks}
          max={20}
          getAriaLabel={() => 'Pretto Slider'}
          getAriaValueText={SliderValueText}
          onChange={handleSliderChange}
          step={1}
          classes={{ track: `tw${Math.max(...value)}` }}
        />
        <p className="field-desc">Audience size ranges from 1% to 20% of the combined population of your selected locations. A 1% lookalike consists of the people most similar to your lookalike source. Increasing the percentage creates a bigger, broader audience.</p>
      </div>
    </div>
  )
}