import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ExitToApp as LoginIcon } from '@material-ui/icons';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@material-ui/core';
import MainLayout from '../../../containers/layout/MainLayout';
// import { ConfirmDialog } from '../../../components/common';
import { LoadingContainer, Alert } from '../../../components/common';
import { GlobalSelectors } from '../../../redux/GlobalRedux';
import UserActions, { UserSelectors } from '../../../redux/admin/user';
// import { DialogInitialOpenState } from '../../../libs/const';
import { formatDate } from '../../../libs/utils';
// import AddDialog from './AddDialog';

export default function Users() {
  const dispatch = useDispatch();
  const refresh = useSelector(GlobalSelectors.selectDataRefresh);
  const rows = useSelector(UserSelectors.selectUsers);

  useEffect(() => {
    const loadUsers = () => {
      dispatch(UserActions.loadUsers());
    };

    loadUsers();
  }, [dispatch, refresh]);

  // const [selectedRow, setSelectedRow] = React.useState(null);
  // const [openDialog, setOpenDialog] = React.useState(DialogInitialOpenState);
  // const handleCloseDialog = name => () => {
  //   setOpenDialog({ ...openDialog, [name]: false });
  // };

  // const handleAddUser = () => event => {
  //   setOpenDialog({ ...openDialog, ...{'add': true} });
  // }

  // const deleteUser = async () => {
  //   await dispatch(UserActions.delete(selectedRow.id));
  //   handleCloseDialog('delete')();
  // }

  const godLogin = async (row) => {
    await dispatch(UserActions.godLogin(row.id));
  }

  const renderTableRow = (row, i) => (
    <TableRow component="div" key={i}>
      <TableCell component="div" scope="row" align="center">{row.id}</TableCell>
      <TableCell component="div" align="left">{row.firstName} {row.lastName}</TableCell>
      <TableCell component="div" align="left">{row.email}</TableCell>
      <TableCell component="div" align="left">{row.status}</TableCell>
      <TableCell component="div" align="left">{formatDate(row.createdAt)}</TableCell>
      <TableCell component="div" align="center" className="row-actions">
        <Box className="action-menu">
          <IconButton
            aria-label="Login"
            onClick={() => godLogin(row)}
          >
            <LoginIcon fontSize="small" />
          </IconButton>
          {/* <IconButton
            aria-label="Edit"
            onClick={handleOpenDialog('edit', row)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="Delete"
            onClick={handleOpenDialog('delete', row)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton> */}
        </Box>
      </TableCell>
    </TableRow>
  )

  const renderTable = () => (
    <TableContainer component={Paper} elevation={0}>
      <Table className="table no-spacing filled-th" component="div">
        <TableHead component="div">
          <TableRow component="div">
            <TableCell component="div" align="center" width="140px">ID</TableCell>
            <TableCell component="div" align="left">Name</TableCell>
            <TableCell component="div" align="left">Email</TableCell>
            <TableCell component="div" align="left">status</TableCell>
            <TableCell component="div" align="left" style={{minWidth: '100px'}}>Created At</TableCell>
            <TableCell component="div">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody component="div">
          {rows.map((row, i) => renderTableRow(row, i))}
        </TableBody>
      </Table>
      {!rows.length && <div className="no-rows">No Data Found</div>}
    </TableContainer>
  )

  return (
    <MainLayout className="users-page" title="Users">
      <Alert />
      <LoadingContainer />
      {/* <Box className="page-header">
        <h3>Users</h3>
        <Button
          size="small"
          className="add-row"
          onClick={handleAddUser()}
        >Add</Button>
      </Box> */}

      { renderTable() }

      {/*openDialog.add && (
        <AddDialog
          handleCloseDialog={handleCloseDialog('add')}
        />
      )*/}
      {/*openDialog.edit && (
        <AddDialog
          row={selectedRow}
          handleCloseDialog={handleCloseDialog('edit')}
        />
      )*/}
      {/*openDialog.delete && (
        <ConfirmDialog
          handleCloseDialog={handleCloseDialog('delete')}
          handleConfirmDialog={deleteUser}
          confirmButton="Delete"
          confirmColor="secondary"
          title="Delete User"
          content="Are you sure you want to permanently delete this user?"
        />
      )*/}
    </MainLayout>
  )
}