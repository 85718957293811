import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputFieldStyles from '../../styles/input';

const inpuFieldtStyles = theme => InputFieldStyles(theme);

function BootstrapInput(props) {
  const { name, classes, InputProps, ...rest } = props;
  const inputProps = {...InputProps, ...{classes: classes}};

  return (
    <TextField
      id={name}
      InputLabelProps={{
        shrink: false
      }}
      InputProps={inputProps}
      {...rest}
    />
  );
}

export default withStyles(inpuFieldtStyles)(BootstrapInput);