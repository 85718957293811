import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ThemeProvider, StylesProvider } from "@material-ui/styles";
import CssBaseLine from "@material-ui/core/CssBaseline";
import Admin from "./Admin";
import App from "./App";
import configureStore from "./redux/store";
import history from "./libs/history";
import theme from "./styles/theme";
import "./styles/theme.scss";
// import { PolarisProvider } from "./providers/PolarisProvider";
import { AppBridgeProvider } from "./providers/AppBridgeProvider";

const store = configureStore();
const admin = window.location.pathname.startsWith("/admin") ? 1 : 0;

const Main = () => (
  <Provider store={store}>
    <Router history={history}>
      <AppBridgeProvider>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <CssBaseLine />
            {admin ? <Admin /> : <App />}
          </StylesProvider>
        </ThemeProvider>
      </AppBridgeProvider>
    </Router>
  </Provider>
);

ReactDOM.render(<Main />, document.getElementById("root"));
