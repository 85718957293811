import React from 'react';
import AuthLayout from '../../containers/layout/AuthLayout';
import SignUpForm from '../../containers/auth/SignUpForm';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { LoadingContainer } from '../../components/common';

function SignUp() {

  return (
    <AuthLayout name="auth sign-up-page">
      <LoadingContainer />
      <Box component={Paper} p={6} elevation={0} className="box-shadow login-box">
        <SignUpForm />
      </Box>      
    </AuthLayout>
  );
}

export default SignUp;
