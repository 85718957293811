import { object, string } from 'yup';

export const PersonalInformationSchema = object().shape({
  name: string().required('Your name is required!'),
  email: string().required('Email is required!')
});

export const ChangePasswordSchema = object().shape({
  currentPassword: string()
    .test('current-password-required', 'Current password is required!', function(value) {
      return this.parent.newPassword && this.parent.newPassword === this.parent.confirmNewPassword ?
        value && value.length > 0 : true;
    }),
  newPassword: string()
    .when('currentPassword', {
      is: (currentPassword) => currentPassword && currentPassword.length > 0,
      then: string().required('New password is required').min(8, 'Too short')
    }),
  confirmNewPassword: string()
    .when('newPassword', {
      is: (newPassword) => newPassword && newPassword.length > 0,
      then: string().required('Confirm new password is required')
        .test('passwords-match', 'Passwords must match!', function(value) {
          return this.parent.newPassword === value;
        })
    })
});
