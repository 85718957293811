import { createMuiTheme } from '@material-ui/core/styles';

const primaryColor = '#52af77';
const buttonColor = '#1E6CEB';
const buttonHoverColor = '#225FC3';
const errorColor = '#FF1818';
const mainFontFamily = 'Roboto, Helvetica, Arial, sans-serif';
const inputHeight = 42;
const borderRadius = 0;

const theme = createMuiTheme({
  props: {
    MuiTextField: {
      fullWidth: true,
      variant: 'outlined',
      // margin: 'dense'
    },
    MuiInputLabel: {
      shrink: true
    },
    MuiButtonBase: {
      // disableRipple: true,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    error: {
      main: errorColor,
    }
  },
  typography: {
    fontFamily: mainFontFamily,
  },
  overrides: {
    MuiButton: {
      text: {
        background: buttonColor,
        borderRadius: borderRadius,
        border: 0,
        color: '#FFFFFF',
        height: inputHeight,
        padding: '0 30px',
        boxShadow: 'none',
        textTransform: 'inherit',
        verticalAlign: 'top',

        '&:hover': {
          color: '#FFFFFF',
          background: buttonHoverColor,
          opacity: 1,
        },
      },
    },
  },
});

export default theme;
