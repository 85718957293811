import { combineReducers } from 'redux';
import { reducer as global } from './GlobalRedux';
import { reducer as auth } from './auth';
import { reducer as ad_account } from './ad_account';
import { reducer as profile } from './profile';
import { reducer as audience } from './audience';
import { reducer as product } from './product';
import { reducer as template } from './template';
import { reducer as aba } from './aba';
import { reducer as lookalike } from './lookalike';
import { reducer as store } from './store/index';
import { reducer as client } from './admin/client';
import { reducer as user } from './admin/user';
import { reducer as customerTag } from './customer-tag';

const reducers = combineReducers({
  global,
  auth,
  ad_account,
  profile,
  audience,
  product,
  template,
  aba,
  lookalike,
  store,
  client,
  user,
  customerTag
});

export default reducers;
