import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as ReactLink } from 'react-router-dom';
import {
  Box,
  Button,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import GlobalActions, { GlobalSelectors } from '../../redux/GlobalRedux';
import AdAccountActions from '../../redux/ad_account';
import { ProfileSelectors } from '../../redux/profile';
import { getItem } from '../../libs/storage-wrapper';

const PricingInfo = ({ pricingErrors }) => {
  if (Object.keys(pricingErrors).length === 0) return null;

  const firstError = pricingErrors[Object.keys(pricingErrors)[0]];
  
  return (
    <Box className="alert-box">
      <MuiAlert
        elevation={0}
        severity="warning"
      >
        <strong>Warning!</strong>
        <div className="alert-text">
          { firstError } Please upgrade your plan <ReactLink to="/pricing" className="alert-link">here</ReactLink>.
        </div>
      </MuiAlert>
    </Box>
  )
}

const tosAccepted = adAccounts => {
  for (let a of adAccounts) {
    if (a.partner === 'Facebook' && !a.tosAccepted) return { tosAccepted: 0, link: `https://business.facebook.com/ads/manage/customaudiences/tos/?act=${a.adAccountId}` };
  }

  return { tosAccepted: 1, link: '' };
}

export default function Alert({openSnackbar, setOpenSnackbar}) {
  const dispatch = useDispatch();
  const user = useSelector(ProfileSelectors.selectProfile);
  const alert = useSelector(GlobalSelectors.selectAlert);
  const adAccounts = getItem('AD_ACCOUNTS');
  const [permAlertOff, setPermAlertOff] = React.useState(false);

  useEffect(() => {
    return () => {
      dispatch(GlobalActions.setAlert(null));
    }
  }, [dispatch]);

  const renderPermAlert = () => {
    if (!user) return null;
    return (
      <div className="perm-alert">
        <PricingInfo pricingErrors={user.pricingErrors} />
      </div>
    );
  }

  const renderNeedsShopifyAuthAlert = () => {
    if (!user) return null;
    if (user.store && user.store.status === 'needs-reauth') {
      return (
        <div className="perm-alert">
          <Box className="alert-box">
            <MuiAlert
              elevation={0}
              severity="warning"
            >
              <strong>Warning!</strong>
              <div className="alert-text">
                Shopify token is expired or invalid. Please re-connect Shopify. Please go to "My Account" page to re-connect.
                {/* <ReactLink to="/pricing" className="alert-link">here</ReactLink> */}
              </div>
            </MuiAlert>
          </Box>
        </div>
      );
    }
    return null;
  }
  const renderPriorityAlert = () => {
    if (!adAccounts) return null;
    if (['/active-account', '/setup'].includes(window.location.pathname)) return null;
    const tos = tosAccepted(adAccounts);

    return (
      <>
        {adAccounts && adAccounts.length === 0 && (
          <Box className="alert-box">
            <MuiAlert
              elevation={0}
              severity="warning"
            >
              <strong>Warning!</strong>
              <div className="alert-text">
                Please connect your ad accounts on "My Accounts" page to get started.
              </div>
            </MuiAlert>
          </Box>
        )}
        {(!tos.tosAccepted && !permAlertOff) && (
          <Box className="alert-box">
            <MuiAlert
              elevation={0}
              severity="warning"
            >
              <strong>Warning!</strong>
              <div className="alert-text" style={{display: 'block'}}>
                <div style={{marginBottom: '10px'}}>
                  {`Please accept Facebook's terms of service for using Custom Audiences at `}<a href={tos.link} target="_blank" rel="noopener noreferrer">{tos.link}</a>.
                  After that please refresh this page or click on the following accept button.
                </div>
                <Button
                  size="small"
                  onClick={() => {
                    setPermAlertOff(true);
                    dispatch(AdAccountActions.loadAdAccounts());
                  }}
                >
                  I've accepted
                </Button>
              </div>
            </MuiAlert>
          </Box>
        )}
      </>
    );
  }

  const renderAlert = () => {
    const { message, severity, disableClose, icon } = alert;
    const handleClose = disableClose ? undefined : () => {
      dispatch(GlobalActions.setAlert(null));
    }

    return (
      <Box className="alert-box">
        <MuiAlert elevation={0} message={message} severity={severity} onClose={handleClose} icon={icon === null ? true : icon}>
          <strong>{severity.charAt(0).toUpperCase() + severity.slice(1)}!</strong>
          <div className="alert-text">
            <span dangerouslySetInnerHTML={{__html: message}} />
            {alert.link && (
              <ReactLink to={alert.link} className="alert-link">{alert.linkText}</ReactLink>
            )}
          </div>
        </MuiAlert>
      </Box>
    )
  }

  const renderSnackbar = () => {
    const { message, severity, anchorOrigin, autoHideDuration, onClose } = alert;

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnackbar(false);
      if (onClose) onClose();
    };

    return (
      <Snackbar
        open={openSnackbar}
        autoHideDuration={autoHideDuration || 6000}
        anchorOrigin={anchorOrigin || { vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
        key={message}
      >
        <MuiAlert elevation={3} variant="filled" severity={severity} onClose={handleClose}>
          <div className="alert-text">
            <span dangerouslySetInnerHTML={{__html: message}} />
            {alert.link && (
              <ReactLink to={alert.link} className="alert-link">{alert.linkText}</ReactLink>
            )}
          </div>
        </MuiAlert>
      </Snackbar>
    )
  }

  return (
    <>
      {renderPermAlert()}
      {renderPriorityAlert()}
      {renderNeedsShopifyAuthAlert()}
      {alert && renderAlert()}
      {alert && renderSnackbar()}
    </>
  );
}