import api from './api';
import { shop } from '../libs/storage';

export default () => {
  const $ = api;
  const getAudience = id => $.get(`/app/audiences/${id}?shop=${shop()}`);
  const getAudiences = () => $.get(`/app/audiences?shop=${shop()}`);
  const getCustomAudiences = (platform) => $.get(`/app/${platform.toLowerCase()}/custom-audiences?shop=${shop()}`);
  const create = params => $.post(`/app/audiences?shop=${shop()}`, params);
  const update = (id, params) => $.put(`/app/audiences/${id}?shop=${shop()}`, params);
  const deleteAudience = id => $.delete(`/app/audiences/${id}?shop=${shop()}`);
  const createLookalike = params => $.post(`/app/${params.platforms[0].toLowerCase()}/create-lookalike?shop=${shop()}`, params);
  const createWebsite = params => $.post(`/app/facebook/create-website?shop=${shop()}`, params);
  // const refresh = id => $.post(`/integrations/${id}/extend-token`);
  // const deleteIntegration = id => $.delete(`/integrations/${id}`);

  return {
    getAudience,
    getAudiences,
    getCustomAudiences,
    create,
    update,
    deleteAudience,
    createLookalike,
    createWebsite,
    // deleteIntegration,
    // refresh,
  };
};
