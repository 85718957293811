import React from 'react';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = props => makeStyles( theme => {
  if (props.size === 'small') {
    return {
      root: {
      },
      icon: {
        width: 16,
        height: 16,
        backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='3.5' stroke='%23A0A0A0'/%3E%3C/svg%3E%0A\");",
      },
      checkedIcon: {
        '&:before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='3.5' stroke='%231E6CEB'/%3E%3Cpath d='M5 7.77778L7.15385 10L12 5' stroke='%231E6CEB' stroke-linecap='round'/%3E%3C/svg%3E%0A\");",
          content: '""',
        },
        'input:hover ~ &': {
          // backgroundColor: '#106ba3',
        },
      },
    }
  } else if (props.color === 'primary') {
    return {
      root: {
      },
      icon: {
        width: 16,
        height: 16,
        backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='1.96154' stroke='%23101E47'/%3E%3C/svg%3E%0A\");",
      },
      checkedIcon: {
        '&:before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='1.96154' stroke='%23101E47'/%3E%3Cpath d='M4 8.23077L6.52632 11L12 5' stroke='black'/%3E%3C/svg%3E%0A\");",
          content: '""',
        },
        'input:hover ~ &': {
          // backgroundColor: '#106ba3',
        },
      },
    }
  } else {
    return {
      root: {
        padding: 12,
      },
      icon: {
        width: 24,
        height: 24,
        backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='22' height='22' rx='3' stroke='%23ABB3BD' stroke-width='2'/%3E%3C/svg%3E%0A\");",
      },
      checkedIcon: {
        '&:before': {
          display: 'block',
          width: 32,
          height: 32,
          marginLeft: -4,
          marginTop: -4,
          backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' viewBox='0 0 24 24'%3E%3Cpath fill='%231E6CEB' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z' /%3E%3C/svg%3E\");",
          content: '""',
        },
        'input:hover ~ &': {
          // backgroundColor: '#106ba3',
        },
      },
    }
  }
})

// Inspired by blueprintjs
function InputCheckbox(props) {
  const classes = useStyles(props)();
  
  return (
    <Checkbox
      className={classes.root}
      // disableRipple
      color="primary"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      // icon={<PrimaryIcon />}
      // checkedIcon={<PrimaryCheckedIcon />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

export default InputCheckbox;