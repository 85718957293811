import apisauce from 'apisauce';
import config from '../config';
import sha256 from 'js-sha256';
import { getItem } from '../libs/storage-wrapper';

const api = apisauce.create({
  baseURL: `${config.apiBase}`,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${getItem('USER_TOKEN')}`
  },
  responseType: 'json'
});

export const authApi = apisauce.create({
  baseURL: `${config.apiBase}`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
});

export default api;