import React from 'react';
import {
  InputBase,
  Select,
  MenuItem,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function SimpleSelect(props) {
  const { id, placeholder, options, width, size, ...rest } = props;
  
  return (
    <Select
      displayEmpty
      className={`simple-select ${size}`}
      IconComponent={ExpandMoreIcon}
      MenuProps={{
        disablePortal: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null,
        id: `${id}-select`,
        PaperProps: {
          elevation: 0,
        }
      }}
      inputProps={{
        id: `${id}`,
        name: `${id}`,
      }}
      input={<InputBase />}
      style={width && {width: width}}
      {...rest}
    >
      {placeholder && (<MenuItem disabled value=""><label className='placeholder'>{placeholder}</label></MenuItem>)}
      {options.map(o => <MenuItem key={o} value={o}><label>{o}</label></MenuItem>)}
    </Select>
  );
}
