import PropTypes from 'prop-types';
import googleOauth from '../../libs/google-oauth';
// import { useDispatch } from 'react-redux';

function GoogleServerLogin({
  render,
  callback,
}) {
  // const dispatch = useDispatch();

  const onClick = () => {
    return googleOauth.login().then(async res => {
      callback(null, res);
    }, err => {
      callback(err);
    });
  }

  return render({
    onClick,
  });
}

GoogleServerLogin.propTypes = {
  render: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

GoogleServerLogin.defaultProps = {
  // size: 'medium'
};

export default GoogleServerLogin;
