import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Box } from '@material-ui/core';
import AuthLoadingContainer from './components/common/AuthLoadingContainer';
import Header from './containers/layout/admin/Header';
import { GlobalSelectors } from './redux/GlobalRedux';
import ProfileActions from './redux/admin/profile';
import { getUserId, getUserToken } from './libs/storage';
import adminRoutes from './routes/admin';

function Admin() {
  const dispatch = useDispatch();
  const user = getUserId();
  const token = useSelector(GlobalSelectors.selectToken);
  const loading = useSelector(GlobalSelectors.selectInitialLoading);

  useEffect(() => {
    const loadProfile = () => {
      dispatch(ProfileActions.loadProfile());
    }
    loadProfile();
  }, [dispatch]);

  if (user.role !== 'admin') {
    window.location.href = '/';
    return null;
  }

  const routes = adminRoutes;

  return (
    <Box className="admin" display="flex" flexDirection="column" minHeight="100vh">
      <AuthLoadingContainer loading={loading}>
        {() => (
          <>
            <Header user={user} />
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  render={props => (
                    <route.component {...props} routes={route.routes} />
                  )}
                  exact
                />
              ))}

              {token ? <Redirect to='/admin' /> : <Redirect to='/login' />}
            </Switch>
          </>
        )}
      </AuthLoadingContainer>
    </Box>
  );
}

export default Admin;
