import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBootstrapInput } from '../../components/form';
import MainLayout from '../../containers/layout/MainLayout';
import AudienceActions, { AudienceSelectors } from '../../redux/audience';
import {
  Box,
  Grid
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { CreateLookalikeFacebookValidationSchema } from './schema';
import { CustomAudienceSelect, CountrySelect, AudienceSizeSelect, LookalikePreview } from './components';
import { LoadingContainer, LoadingButton, Alert } from '../../components/common';
import { params } from '../../libs/utils';

const INITIAL_VALUES = {
  platforms: ['Facebook'],
  customAudience: null,
  countries: [],
  audienceSizes: [1],
  nameSuffix: '',
  namePrefix: '',
};

const refineCustomAudiences = rows => {
  let result = [];
  if (rows) {
    // We can't choose small audiences - delivery_status.code must be 200 but commenting for now, just showing all CAs
    // result = rows.filter(v => v.delivery_status.code === 200);
    result = rows.map(v => {
      return {
        id: v.id,
        name: v.name
      }
    });
  }
  return result;
}

export default function CreateLookalike() {
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const baseAudienceId = params('base');

  const customAudiences = refineCustomAudiences(useSelector(AudienceSelectors.selectCustomAudiences));
  let initialValues = INITIAL_VALUES;
  if (baseAudienceId) {
    const baseAudience = customAudiences.filter(v => v.id === baseAudienceId);
    if (baseAudience.length === 1) initialValues.customAudience = baseAudience[0];
  }

  useEffect(() => {
    const loadCustomAudiences = () => {
      dispatch(AudienceActions.loadCustomAudiences('Facebook'));
    };

    loadCustomAudiences();
  }, [dispatch]);

  const handleSubmit = async (params, { setSubmitting, resetForm, setValues }) => {
    const resp = await dispatch(AudienceActions.createLookalike(params));
    if (resp.ok) {
      resetForm({});
    }
    setSubmitting(false);
    setOpenSnackbar(true);
  };

  const renderForm = (props) => {
    const { values, setFieldValue, errors, touched, isSubmitting } = props;

    return (
      <Form className="audience-form">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomAudienceSelect
              value={values.customAudience}
              setFieldValue={setFieldValue}
              options={customAudiences}
              errors={errors}
              touched={touched}
            />
          </Grid>

          <Grid item xs={12}>
            <CountrySelect value={values.countries} setFieldValue={setFieldValue} errors={errors} touched={touched} />
          </Grid>

          <Grid item xs={12}>
            <AudienceSizeSelect value={values.audienceSizes} setFieldValue={setFieldValue} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-control">
                  <label>Audience Name Prefix</label>
                  <Field
                    component={FormBootstrapInput}
                    name="namePrefix"
                    autoComplete="off"
                    placeholder="Prefix"
                    value={values.namePrefix}
                    onChange={(e) => setFieldValue('namePrefix', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-control">
                  <label>Audience Name Suffix</label>
                  <Field
                    component={FormBootstrapInput}
                    name="nameSuffix"
                    autoComplete="off"
                    placeholder="Suffix"
                    value={values.nameSuffix}
                    onChange={(e) => setFieldValue('nameSuffix', e.target.value)}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <LookalikePreview values={values} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} className="action-row">
            {<LoadingButton loading={isSubmitting} type="submit">Create</LoadingButton>}
          </Grid>
        </Grid>
      </Form>
    )
  }

  const getPageTitle = (platforms) => {
    if (platforms.includes('Facebook')) {
      return 'Create Lookalike Audience (Facebook)';
    } else {
      return 'Create Similar Audience (Google)';
    }
  }

  return (
    <MainLayout className="create-aba-page" title={getPageTitle(initialValues.platforms)}>
      <LoadingContainer />
      <Alert
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <Box className="page-header">
        <h3>{getPageTitle(initialValues.platforms)}</h3>
        <p className="title-note">Reach out new people who are similar to audiences you already care about.</p>
      </Box>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={CreateLookalikeFacebookValidationSchema}
      >
        {props => renderForm(props)}
      </Formik>
    </MainLayout>
  );
}