import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { FormBootstrapInput } from '../../../components/form';
import { LoadingButton } from '../../../components/common';
import ClientActions from '../../../redux/admin/client';

const AddDialogValidationSchema = object().shape({
  email: string().required('Email is required!'),
  shop: string().required('Shop link is required!')
});

export default function AddDialog(props) {
  const dispatch = useDispatch();
  const { row, handleCloseDialog } = props;
  const addClientInitialValues = {
    email: '',
    shop: ''
  }

  const handleSubmit = async (values) => {
    row ?
      await dispatch(ClientActions.update(row.id, values))
      : await dispatch(ClientActions.add(values));
    handleCloseDialog();
  };

  return (
    <Dialog
      open
      className="form-dialog add-dialog"
      disableBackdropClick
      disableEscapeKeyDown
      onClose={handleCloseDialog}
      aria-labelledby="add-dialog-title"
    >
      <Formik
        initialValues={row ? row : addClientInitialValues}
        onSubmit={handleSubmit}
        validationSchema={AddDialogValidationSchema}
      >
        {({ values, setFieldValue, isSubmitting, errors, touched }) => (
          <Form>
            <DialogTitle>Your Client</DialogTitle>
            <DialogContent>
              <Field
                component={FormBootstrapInput}
                name="email"
                value={values.email}
                placeholder="Email Address"
              />

              <Field
                component={FormBootstrapInput}
                name="shop"
                value={values.shop}
                placeholder="Shop Link - ember.myshopify.com"
              />
            </DialogContent>
            <DialogActions>
              <Button className="white-btn" onClick={handleCloseDialog}>Cancel</Button>
              <LoadingButton loading={isSubmitting} type="submit">
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}