import React from 'react';
import { useDispatch } from 'react-redux';
import AdAccountActions from '../../../redux/ad_account';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Box } from '@material-ui/core';
import { LoadingButton } from '../../../components/common';
import global from '../../../redux/GlobalRedux';
import { shop } from '../../../libs/storage';
import vars from '../../../libs/vars';
import { unix2Date } from '../../../libs/utils';

export default function ConnectFacebook(props) {
  const {
    handleComplete,
    facebookAccount
  } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const handleFbLoginClick = renderProps => event => {
    renderProps.onClick();
    setLoading(true);
  }

  const responseFacebook = async resp => {
    if (resp.status) {
      dispatch(global.setAlert({
        message: "There was an error with your Facebook authorization.",
        severity: "error",
      }));
    } else {
      const params = {
        partner: 'Facebook',
        shop: shop(),
        name: resp.name,
        email: resp.email,
        accessToken: resp.accessToken,
        refreshToken: '',
        expireDate: unix2Date(resp.data_access_expiration_time),
        picture: resp.picture.data.url
      };

      await dispatch(AdAccountActions.connectAccount(params));
      handleComplete();
    }
    setLoading(false);
  }

  const disconnectAccount = async () => {
    await dispatch(AdAccountActions.disconnectAccount(facebookAccount.id));
    handleComplete(false);
  }

  const renderFacebookAccountInfo = () => {
    return (
      <div className="connected-facebook">
        <div className="image">
          <img src={facebookAccount.picture} alt="Facebook Account" />
        </div>
        <h4 className="facebook-name">{facebookAccount.name}</h4>
        <LoadingButton onClick={disconnectAccount} color="secondary">Disconnect</LoadingButton>
      </div>
    );
  }

  return (
    <Box className="connect-facebook">
      <h2>Connect Facebook Account</h2>
      <p className="title-note">SyncTap uses your personal Facebook account to access your ad accounts.</p>
      {facebookAccount ? (
        renderFacebookAccountInfo()
      ) : (
        <>
          <FacebookLogin
            appId={vars.REACT_APP_FACEBOOK_APP_ID}
            fields="name,email,picture"
            scope="ads_management,business_management,email"
            callback={responseFacebook}
            autoLoad={false}
            disableMobileRedirect={true}
            textButton="Connect Facebook Account"
            render={renderProps => (
              <LoadingButton
                loading={loading}
                onClick={handleFbLoginClick(renderProps)}
                className="connect"
              >CONNECT FACEBOOK ACCOUNT</LoadingButton>
            )}
          />
        </>
      )}
    </Box>
  );
}