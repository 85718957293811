import React from 'react';
import MainLayout from '../../containers/layout/MainLayout';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  Box
} from '@material-ui/core';
import { Alert } from '../../components/common';
import { CbaList, AbaList, LookalikeList } from './components';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Audiences() {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <MainLayout pclass="full-width" className="my-audiences-page" title="My Saved Audiences">
      <Alert
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />

      <Box className="page-header">
        <h3>My Saved Audiences</h3>
        <p className="title-note">View or edit saved audiences. These audiences are synchronized with your connected ad accounts.</p>
      </Box>

      <Box className="tabs-header">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Customer Based Audiences" {...a11yProps(0)} />
          <Tab label="Activity Based Audiences" {...a11yProps(1)} />
          <Tab label="Lookalike Audiences" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <CbaList
          setOpenSnackbar={setOpenSnackbar}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <AbaList
          setOpenSnackbar={setOpenSnackbar}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <LookalikeList
          setOpenSnackbar={setOpenSnackbar}
        />
      </TabPanel>
      
    </MainLayout>
  )
}