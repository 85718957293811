import api from "../services/api";
import { getItem, removeItem, setItem } from "./storage-wrapper";

export const shop = () => {
  return getItem('STORE_ID', '');
}
export const getStoreId = () => getItem('STORE_ID', '')
export const setStoreId = data => setItem('STORE_ID', data)
export const removeStoreId = () => removeItem('STORE_ID')

export const getUserId = () => getItem('USER_ID', false)
export const setUserId = data => setItem('USER_ID', data)
export const removeUserId = () => removeItem('USER_ID')

export const getGodUserId = () => getItem('GOD_USER_ID', false)
export const setGodUserId = data => setItem('GOD_USER_ID', data)
export const removeGodUserId = () => removeItem('GOD_USER_ID')


export const getUserToken = () => getItem('USER_TOKEN', false)
export const setUserToken = data => {
  api.setHeader('Authorization', `Bearer ${data}`)
  setItem('USER_TOKEN', data)
}
export const removeUserToken = () => removeItem('USER_TOKEN')

export const getGodUserToken = () => getItem('GOD_USER_TOKEN', false)
export const setGodUserToken = data => setItem('GOD_USER_TOKEN', data)
export const removeGodUserToken = () => removeItem('GOD_USER_TOKEN')

export const getPageInfo = () => getItem('PAGE_INFO', false)
export const setPageInfo = data => setItem('PAGE_INFO', data)
export const removePageInfo = () => removeItem('PAGE_INFO')

export const clearInfo = () => {
  removeUserToken();
  removeUserId();
  removeStoreId();
  removePageInfo();
  removeItem('AD_ACCOUNTS');
  removeItem('STORE');
  removeItem('AdAccountId');
  removeItem('EMBEDDED');
}

export default {
  set: setItem,
  get: getItem,
  remove: removeItem,
};
