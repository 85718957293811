import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import MainLayout from '../../containers/layout/MainLayout';
import global from '../../redux/GlobalRedux';
import ProfileActions from '../../redux/profile';
import { LoadingContainer, LoadingButton } from '../../components/common';
import { shop, getUserId } from '../../libs/storage';
import shopify from '../../libs/shopify';

const CheckBoxIcon = () => {
  return (
    <CheckIcon className="checkbox-icon" />
  )
}

function Pricing() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState({free: false, basic: false, pro: false, premium: false, agency: false});
  const user = getUserId();
  const pricing = user.pricing;

  const handleSubmit = pricing => () => {
    const domain = shop();

    setLoading({...loading, [pricing]: true});
    return shopify.upgrade(domain, pricing).then(async res => {
      setLoading({...loading, [pricing]: false});
      dispatch(ProfileActions.loadProfile());
    }, err => {
      dispatch(global.setAlert({
        message: err.message,
        severity: "error"
      }));
      setLoading({...loading, [pricing]: false});
    });
  };

  const renderTable = () => (
    <div className="pricing-container">
      <h2>You're in <b>{`${pricing.charAt(0).toUpperCase() + pricing.slice(1)}`}</b> plan now.</h2>
      <TableContainer component={Paper} elevation={0}>
        <Table className={`table pricing-table no-spacing filled-th ${user.pricing}`} component="div">
          <TableHead component="div" className="gap">
            <TableCell component="div" scope="row"></TableCell>
            <TableCell className={pricing === 'starter' ? 'current' : ''} component="div"></TableCell>
            <TableCell className={pricing === 'basic' ? 'current' : ''} component="div"></TableCell>
            <TableCell className={pricing === 'pro' ? 'current' : ''} component="div"></TableCell>
            <TableCell className={pricing === 'premium' ? 'current' : ''} component="div"></TableCell>
          </TableHead>
          <TableHead component="div">
            <TableRow component="div">
              <TableCell component="div"></TableCell>
              <TableCell className={pricing === 'starter' ? 'current' : ''} component="div"><label className="title">STARTER</label><label className="price">$9.99/mo</label></TableCell>
              <TableCell className={pricing === 'basic' ? 'current' : ''} component="div"><label className="title">BASIC</label><label className="price">$29/mo</label></TableCell>
              <TableCell className={pricing === 'pro' ? 'current' : ''} component="div"><label className="title">PRO</label><label className="price">$49/mo</label></TableCell>
              <TableCell className={pricing === 'premium' ? 'current' : ''} component="div"><label className="title">PREMIUM</label><label className="price">$99/mo</label></TableCell>
            </TableRow>
          </TableHead>
          <TableBody component="div">
            <TableRow component="div">
              <TableCell component="div" scope="row">Number of Stores</TableCell>
              <TableCell className={pricing === 'starter' ? 'current' : ''} component="div">1</TableCell>
              <TableCell className={pricing === 'basic' ? 'current' : ''} component="div">1</TableCell>
              <TableCell className={pricing === 'pro' ? 'current' : ''} component="div">1</TableCell>
              <TableCell className={pricing === 'premium' ? 'current' : ''} component="div">1</TableCell>
            </TableRow>
            <TableRow component="div">
              <TableCell component="div" scope="row">Total Customer Records</TableCell>
              <TableCell className={pricing === 'starter' ? 'current' : ''} component="div">Up to 50k</TableCell>
              <TableCell className={pricing === 'basic' ? 'current' : ''} component="div">Up to 250k</TableCell>
              <TableCell className={pricing === 'pro' ? 'current' : ''} component="div">Up to 500k</TableCell>
              <TableCell className={pricing === 'premium' ? 'current' : ''} component="div">Up to 1M</TableCell>
            </TableRow>
            <TableRow component="div">
              <TableCell component="div" scope="row">Actively Synced Audiences</TableCell>
              <TableCell className={pricing === 'starter' ? 'current' : ''} component="div">5</TableCell>
              <TableCell className={pricing === 'basic' ? 'current' : ''} component="div">20</TableCell>
              <TableCell className={pricing === 'pro' ? 'current' : ''} component="div">40</TableCell>
              <TableCell className={pricing === 'premium' ? 'current' : ''} component="div">Unlimited</TableCell>
            </TableRow>
            <TableRow component="div">
              <TableCell component="div" scope="row">Ad Platform Integrations</TableCell>
              <TableCell className={pricing === 'starter' ? 'current' : ''} component="div">1</TableCell>
              <TableCell className={pricing === 'basic' ? 'current' : ''} component="div">1</TableCell>
              <TableCell className={pricing === 'pro' ? 'current' : ''} component="div">All</TableCell>
              <TableCell className={pricing === 'premium' ? 'current' : ''} component="div">All</TableCell>
            </TableRow>
            <TableRow component="div">
              <TableCell component="div" scope="row">Audience Sync Frequency</TableCell>
              <TableCell className={pricing === 'starter' ? 'current' : ''} component="div">48hrs</TableCell>
              <TableCell className={pricing === 'basic' ? 'current' : ''} component="div">Daily</TableCell>
              <TableCell className={pricing === 'pro' ? 'current' : ''} component="div">Daily</TableCell>
              <TableCell className={pricing === 'premium' ? 'current' : ''} component="div">Daily</TableCell>
            </TableRow>
            <TableRow component="div">
              <TableCell component="div" scope="row">High Converting Audience Templates</TableCell>
              <TableCell className={pricing === 'starter' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'basic' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'pro' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'premium' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
            </TableRow>
            <TableRow component="div">
              <TableCell component="div" scope="row">Bulk Lookalike Audience Tool</TableCell>
              <TableCell className={pricing === 'starter' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'basic' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'pro' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'premium' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
            </TableRow>
            <TableRow component="div">
              <TableCell component="div" scope="row">Custom Audience Creation Tool</TableCell>
              <TableCell className={pricing === 'starter' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'basic' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'pro' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'premium' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
            </TableRow>
            <TableRow component="div">
              <TableCell component="div" scope="row">Activity Based Audience Tool</TableCell>
              <TableCell className={pricing === 'starter' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'basic' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'pro' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'premium' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
            </TableRow>
            <TableRow component="div">
              <TableCell component="div" scope="row">Private Facebook Group</TableCell>
              <TableCell className={pricing === 'starter' ? 'current' : ''} component="div"><ClearIcon color="error" /></TableCell>
              <TableCell className={pricing === 'basic' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'pro' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
              <TableCell className={pricing === 'premium' ? 'current' : ''} component="div"><CheckBoxIcon /></TableCell>
            </TableRow>
            <TableRow component="div" className="gap">
              <TableCell component="div" scope="row"></TableCell>
              <TableCell className={pricing === 'starter' ? 'current' : ''} component="div"></TableCell>
              <TableCell className={pricing === 'basic' ? 'current' : ''} component="div"></TableCell>
              <TableCell className={pricing === 'pro' ? 'current' : ''} component="div"></TableCell>
              <TableCell className={pricing === 'premium' ? 'current' : ''} component="div"></TableCell>
            </TableRow>
          </TableBody>
          <TableFooter component="div">
            <TableRow component="div" className="actions">
              <TableCell component="div" scope="row"></TableCell>
              <TableCell component="div">
                {pricing !== 'starter' && <LoadingButton
                    loading={loading.starter}
                    size="large"
                    onClick={handleSubmit('starter')}
                  >START</LoadingButton>}
              </TableCell>
              <TableCell component="div">
                {pricing !== 'basic' && <LoadingButton
                  loading={loading.basic}
                  size="large"
                  onClick={handleSubmit('basic')}
                >START</LoadingButton>}
              </TableCell>
              <TableCell component="div">
                {pricing !== 'pro' && <LoadingButton
                  loading={loading.pro}
                  size="large"
                  onClick={handleSubmit('pro')}
                >START</LoadingButton>}
              </TableCell>
              <TableCell component="div">
                {pricing !== 'premium' && <LoadingButton
                  loading={loading.premium}
                  size="large"
                  onClick={handleSubmit('premium')}
                >START</LoadingButton>}
              </TableCell>
              {/* <TableCell component="div">
                {pricing !== 'agency' && <LoadingButton
                  loading={loading.agency}
                  size="large"
                  onClick={handleSubmit('agency')}
                >START</LoadingButton>}
              </TableCell> */}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )

  return (
    <MainLayout pclass="full-width" className="pricing-page" title="Pricing">
      <LoadingContainer />
      
      <Box className="page-header">
        <p className="title-note"></p>
      </Box>

      { renderTable() }
    </MainLayout>
  );
}

export default Pricing;
