import { createReducer, createActions } from 'reduxsauce';
// import adminApiCreate from '../../../services/admin';
import global from '../../GlobalRedux'
// import AuthProfile from '../auth'
// import { setItem } from '../../../libs/storage';

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setProfile: ['user'],
});

Creators.loadProfile = () => {
  return async dispatch => {
    // const api = adminApiCreate();
    // const resp = await api.profile();
    // if (resp.ok) {
    //   setItem("USER", resp.data);
    //   dispatch(Creators.setProfile(resp.data));
    //   dispatch(global.setLoading(false));
    //   dispatch(global.setLoggedIn(true));
    // } else {
    //   dispatch(AuthProfile.logout());
    // }

    dispatch(global.setInitialLoading(false));
  };
};

export const ProfileTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const ProfileSelectors = {
  selectProfile: state => state.profile.user,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  profile: null,
};

/* ------------------- Reducers --------------------- */
export const setProfile = (state, { user }) => ({
  ...state,
  user
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PROFILE]: setProfile,
});
