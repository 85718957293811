import { createReducer, createActions } from "reduxsauce";
import { setPageInfo, clearInfo, getUserToken } from "../libs/storage";
import history from "../libs/history";
import { getItem } from "../libs/storage-wrapper";

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  _setPageInfo: ["pageInfo"],
  setLoading: ["loading"],
  setSubLoading: ["subLoading"],
  setLoggedIn: ["loggedIn"],
  setApiError: ["status"],
  setAlert: ["alert"],
  setDataRefresh: ["dataRefresh"],
  setButtonLoading: ["buttonLoading"],
  setInitialLoading: ["initialLoading"],
  setShopifySession: ["shopifySession"],
  setIsEmbedded: ["isEmbedded"],
  setToken: ["token"],
});

Creators.setPageInfo = (pageInfo) => {
  return (dispatch) => {
    setPageInfo(pageInfo);
    dispatch(Creators._setPageInfo(pageInfo));
  };
};

Creators.apiError = (resp) => {
  return (dispatch) => {
    dispatch(Creators.setApiError(resp.status));

    if (resp.status === 400) {
      // BAD_REQUEST code
      dispatch(
        Creators.setAlert({
          message: resp.data.message,
          severity: "error",
        })
      );
    } else if (resp.status === 307) {
      // TEMPORARY_REDIRECT
      if (resp.data.mode === "app") {
        // In app redirect
        history.push(resp.data.redirect);
        // window.location.href = resp.data.redirect;
      } else {
        // type == 'shopify' redirect like shopify auth
        window.top.location.href = resp.data.redirect;
      }
    } else if (resp.status === 308) {
      // PERMANENT_REDIRECT
      if (resp.data.mode === "app") {
        // In app redirect
        window.location.href = resp.data.redirect;
      } else {
        // type == 'shopify' redirect like shopify auth
        window.top.location.href = resp.data.redirect;
      }
    } else if (resp.status === 401) {
      // 401 Unauthorized
      dispatch(Creators.logout());
    } else if (resp.status === 403) {
      // 403 Forbidden - Store is removed, reinstalling
      if (getItem("EMBEDDED")) {
        const store = getItem("STORE_ID");
        window.top.location.href = `https://${store}/admin/apps`;
      } else {
        window.location.href = "/setup";
      }
    } else if (resp.status === 404) {
      dispatch(
        Creators.setAlert({
          message: "Request failed with status code 404",
          severity: "error",
        })
      );
    } else {
      dispatch(
        Creators.setAlert({
          message: "There was a problem with loading data",
          severity: "error",
        })
      );
    }
  };
};

Creators.logout = () => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT" });

    const store = getItem("STORE_ID");
    clearInfo();

    if (getItem("EMBEDDED")) {
      window.top.location.href = `https://${store}/admin/apps`;
    } else {
      window.location.href = "/login";
    }
  };
};

export const GlobalTypes = Types;

export default Creators;

/* --------------------- Selectors ---------------- */
export const GlobalSelectors = {
  selectPageInfo: (state) => state.global.pageInfo,
  selectLoading: (state) => state.global.loading,
  selectSubLoading: (state) => state.global.subLoading,
  selectLoggedIn: (state) => state.global.loggedIn,
  selectAlert: (state) => state.global.alert,
  selectDataRefresh: (state) => state.global.dataRefresh,
  selectButtonLoading: (state) => state.global.buttonLoading,
  selectInitialLoading: (state) => state.global.initialLoading,
  selectShopifySession: (state) => state.global.shopifySession,
  selectIsEmbedded: (state) => state.global.isEmbedded,
  selectToken: (state) => state.global.token,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  pageInfo: {},
  loading: false,
  subLoading: false,
  loggedIn: false,
  alert: null,
  dataRefresh: 0,
  buttonLoading: false,
  initialLoading: false,
  shopifySession: {},
  isEmbedded: false,
  token: getUserToken(),
};

/* ------------------- Reducers --------------------- */
export const _setPageInfo = (state, { pageInfo }) => ({
  ...state,
  pageInfo,
});

export const setLoading = (state, { loading }) => ({
  ...state,
  loading,
});

export const setSubLoading = (state, { subLoading }) => ({
  ...state,
  subLoading,
});

export const setLoggedIn = (state, { loggedIn }) => ({
  ...state,
  loggedIn,
});

export const setApiError = (state, { status }) => ({
  ...state,
  status,
});

export const setAlert = (state, { alert }) => ({
  ...state,
  alert,
});

export const setDataRefresh = (state, { dataRefresh }) => ({
  ...state,
  dataRefresh,
});

export const setButtonLoading = (state, { buttonLoading }) => ({
  ...state,
  buttonLoading,
});

export const setInitialLoading = (state, { initialLoading }) => ({
  ...state,
  initialLoading,
});

export const setShopifySession = (state, { shopifySession }) => ({
  ...state,
  shopifySession,
});

export const setIsEmbedded = (state, { isEmbedded }) => ({
  ...state,
  isEmbedded,
});

export const setToken = (state, { token }) => ({
  ...state,
  token,
});
export const reducer = createReducer(INITIAL_STATE, {
  [Types._SET_PAGE_INFO]: _setPageInfo,
  [Types.SET_LOADING]: setLoading,
  [Types.SET_SUB_LOADING]: setSubLoading,
  [Types.SET_LOGGED_IN]: setLoggedIn,
  [Types.SET_API_ERROR]: setApiError,
  [Types.SET_ALERT]: setAlert,
  [Types.SET_DATA_REFRESH]: setDataRefresh,
  [Types.SET_BUTTON_LOADING]: setButtonLoading,
  [Types.SET_INITIAL_LOADING]: setInitialLoading,
  [Types.SET_SHOPIFY_SESSION]: setShopifySession,
  [Types.SET_IS_EMBEDDED]: setIsEmbedded,
  [Types.SET_TOKEN]: setToken,
});
