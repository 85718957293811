import React from 'react';
import { Field } from 'formik';
import {
  Button,
  Checkbox,
} from '@material-ui/core';
import { FormBootstrapInput } from '../../../components/form';
import { Days } from '../../../libs/const';

const daysRegex = /^[,0-9\b]+$/;

export default function DaySelect(props) {
  const { days, setFieldValue } = props;

  const handleCheckboxClick = (p) => e => {
    let _days = days.split(',');
    if (e.target.checked) _days.push(p);
    else {
      const idx = _days.indexOf(p);
      if (idx > -1) {
        _days.splice(idx, 1);
      }
    }

    setFieldValue("days", _days.join(','));
  }

  const handleButtonClick = (p) => e => {
    let _days = days ? days.split(',') : [];
    const idx = _days.indexOf(p);
    if (idx < 0) _days.push(p);
    else _days.splice(idx, 1);

    _days.sort(function(a, b){
      return a - b;
    });

    setFieldValue("days", _days.join(','));
  }

  const hasValue = p => {
    let _days = days ? days.split(',') : [];
    return _days.includes(p);
  }

  const handleChange = e => {
    const value = e.target.value;
    if (daysRegex.test(e.target.value)) {
      setFieldValue("days", value);
    }
  }

  return (
    <div className="form-control day-select-container">
      <label>Select days</label>
      <div className="days-select-container">
        {Days.map((p, key) => (
          <div className="day-select" key={key}>
            <Checkbox
              checked={hasValue(p)}
              onChange={handleCheckboxClick(p)}
              value={p}
            />
            <Button onClick={handleButtonClick(p)}>{p}</Button>
          </div>
        ))}
        
        <div className="from-control">
          <label>Your Selected Days: (You can add any number 1-180, just keep values separated with a comma)</label>
          <Field
            component={FormBootstrapInput}
            name="days"
            autoComplete="off"
            inputProps={{pattern: '^[,0-9\b]+$'}}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  )
}