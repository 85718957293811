import React from 'react';
import AuthLayout from '../../containers/layout/AuthLayout';
import SetPasswordForm from '../../containers/auth/SetPasswordForm';
import {
  Box,
  Paper
} from '@material-ui/core';

function SetPassword() {

  return (
    <AuthLayout name="auth set-password-page">
      <Box component={Paper} p={6} elevation={0} className="box-shadow login-box">
        <SetPasswordForm />
      </Box>
    </AuthLayout>
  );
}

export default SetPassword;
