import React from 'react';
import { Field } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  FormHelperText,
} from '@material-ui/core';
import { BootstrapInput } from '../../../components/common';
import { Countries } from '../../../libs/const';

export default function CountrySelect(props) {
  const { value, setFieldValue, errors, touched } = props;

  return (
    <div className="form-control autocomplete">
      <label>Select countries</label>
      <div className="countries-select-container">
        <Field
          multiple
          disablePortal
          component={Autocomplete}
          name='countries'
          id="country-select"
          className={errors.countries ? 'Mui-error' : ''}
          value={value}
          style={{ width: 500 }}
          options={Countries}
          getOptionSelected={(option, value) => option.code === value.code}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <BootstrapInput
              {...params}
              variant="outlined"
              placeholder="Countries"
              InputLabelProps={{
                shrink: false
              }}
            />
          )}
          onChange={ (__, value) => {
            setFieldValue("countries", value);
          }}
        />
        {errors.countries && touched.countries && <FormHelperText error>{errors.countries}</FormHelperText>}
      </div>
    </div>
  )
}