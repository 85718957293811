import api, { authApi } from './api';

export default () => {
  const $ = api;
  const login = (email, password) => authApi.post('/admin/login', {email, password});
  const profile = () => $.get('/admin/profile');
  
  // Client APIs
  const clients = () => $.get('/admin/clients');
  const addClient = params => $.post(`/admin/clients`, params);
  const updateClient = (id, params) => $.put(`/admin/clients/${id}`, params);
  const deleteClient = id => $.delete(`/admin/clients/${id}`);

  // USer APIs
  const users = () => $.get('/admin/users');
  const usersGodLogin = (userId) => $.get('/admin/users/god-login/' + userId);

  return {
    login,
    profile,
    clients,
    addClient,
    updateClient,
    deleteClient,

    users,
    usersGodLogin,
  };
};
