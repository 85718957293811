import React from 'react';
import AuthLayout from '../../containers/layout/AuthLayout';
import { Link as ReactLink } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import {
  Box,
  Paper
} from '@material-ui/core';
import { getItem } from '../../libs/storage-wrapper';

function ResetPasswordRequest() {

  const authEmail = getItem('AUTH_EMAIL');

  return (
    <AuthLayout name="auth verify-email-page">
      <Box component={Paper} p={6} elevation={0} className="box-shadow login-box">
        <h2>Password Reset Requested</h2>
        <Alert className="static-alert" icon={false} severity="success">We've sent an email with password reset instructions to:<br/><b>{authEmail}</b></Alert>
        <p>If the email doesn't show up soon, check your spam folder. We sent it from <b>josh@synctap.io</b>.</p>
        
        <div className="login-footer">
          <p><ReactLink to="/login">Return to SyncTap</ReactLink></p>
        </div>
      </Box>
    </AuthLayout>
  );
}

export default ResetPasswordRequest;
