import React from 'react';
import { Field } from 'formik';
import { Select } from '../../../components/common';

const Ops = {
  all: [
    { id: '=', name: 'Equal to' },
    { id: '>', name: 'Greater than' },
    { id: '>=', name: 'Greater than or equal to' },
    { id: '<', name: 'Less than' },
    { id: '<=', name: 'Less than or equal to' }
  ],
  greaterThan: [
    { id: '=', name: 'Equal to' },
    { id: '>', name: 'Greater than' },
    { id: '>=', name: 'Greater than or equal to' }
  ],
  product: [
    { id: '=', name: 'Purchased' },
    { id: '!=', name: 'Hasn\'t Purchased' },
  ],
  tag: [
    { id: '=', name: 'Tag is' },
    { id: '!=', name: 'Tag is not' },
  ],
  becameCustomer: [
    { id: '==', name: 'On' },
    { id: '<', name: 'Before' },
    { id: '>', name: 'After' },
    { id: '<>', name: 'Between' },
  ],
}

const CriteriaSelector = {
  'repeat-purchases': Ops.greaterThan,
  'product': Ops.product,
  'tag': Ops.tag,
  'became-customer': Ops.becameCustomer
}

export default function Op(props) {
  const { index, value, criteria, setFieldValue, errors, touched } = props;
  const ops = CriteriaSelector[criteria] || Ops.all;

  const errorOpClass = () => {
    if (errors.criterias && errors.criterias[index] && errors.criterias[index].op
      && touched.criterias && touched.criterias[index] && touched.criterias[index].op) return 'Mui-error';
    else return '';
  }

  const handleChange = () => event => {
    setFieldValue(`criterias[${index}].op`, event.target.value);
  };

  return (
    <Field
      component={Select}
      size={`small operator-field ${errorOpClass()}`}
      id={`criterias[${index}].op`}
      name={`criterias[${index}].op`}
      placeholder='Select option'
      onChange={handleChange()}
      value={value}
      options={ops}
      width="200px"
    />
  )
}