import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MainLayout from '../../containers/layout/MainLayout';
import { LoadingContainer, Alert } from '../../components/common';
import { GlobalSelectors } from '../../redux/GlobalRedux';
import TemplateActions, { TemplateSelectors } from '../../redux/template';
import {
  Box,
  Grid,
  Paper,
  Link
} from '@material-ui/core';
import { LoadingButton } from '../../components/common';
import { DialogInitialOpenState } from '../../libs/const';
import CreateDialog from './CreateDialog';

export default function Templates() {
  const dispatch = useDispatch();
  const refresh = useSelector(GlobalSelectors.selectDataRefresh);
  const items = useSelector(TemplateSelectors.selectTemplates);
  const buttonLoading = useSelector(GlobalSelectors.selectButtonLoading);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [actionType, setActionType] = React.useState(0);
  
  const [openDialog, setOpenDialog] = React.useState(DialogInitialOpenState);
  const handleCloseDialog = name => () => {
    setOpenDialog({ ...openDialog, [name]: false });
  };

  useEffect(() => {
    const loadTemplates = () => {
      dispatch(TemplateActions.loadTemplates());
    };

    loadTemplates();
  }, [dispatch, refresh]);

  const handleCreateAudience = row => async event => {
    setSelectedItem(items.find(i => i.id === row.id));
    setActionType(1);
    if (['order-count', 'total-spent'].includes(row.handle)) {
      // Open create dialog
      setOpenDialog({ ...openDialog, ...{'createTemplate': true} });
    } else {
      // Otherwise create audience directly
      row.loading = true;
      await dispatch(TemplateActions.createAudience(row.id));
      setOpenSnackbar(true);
    }
  }

  const handleRemoveTemplate = row => async event => {
    setSelectedItem(items.find(i => i.id === row.id));
    setActionType(2);
    await dispatch(TemplateActions.removeTemplate(row.id));
    setOpenSnackbar(true);
  }

  const renderTable = () => (
    <Grid container spacing={2} className="templates-list">
      {items.map((row, i) => (
        <Grid item xs={4} key={i}>
          <Paper>
            <h4>{row.name}{row.selected}</h4>
            <p dangerouslySetInnerHTML={{__html: row.description}} />
            <div className="action-buttons">
              {row.created ? (<LoadingButton size="small" disabled>Created</LoadingButton>) : (
                <LoadingButton
                  size="small"
                  loading={row.loading || (buttonLoading && selectedItem && selectedItem.id === row.id && actionType === 1)}
                  onClick={handleCreateAudience(row)}
                >
                  Create Audience
                </LoadingButton>
              )}
              {row.type === 'user' && (
                <Link
                  className="remove-button"
                  size="small"
                  onClick={handleRemoveTemplate(row)}
                >
                  Remove Template
                </Link>
              )}
            </div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )

  return (
    <MainLayout className="templates-page" title="Templates">
      <Alert
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <LoadingContainer />
      <Box className="page-header">
        <h3>Use Audience Templates</h3>
        <p className="title-note">Create or use pre-configured templates of audiences</p>
      </Box>

      {openDialog.createTemplate && (
        <CreateDialog
          templateId={selectedItem.id}
          criteria={selectedItem.handle}
          handleCloseDialog={handleCloseDialog('createTemplate')}
          setOpenSnackbar={setOpenSnackbar}
        />
      )}

      { renderTable() }

    </MainLayout>
  )
}