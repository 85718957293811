import Login from '../pages/admin/Login';
import Clients from '../pages/admin/clients';
import Users from '../pages/admin/users';

const routes = [
  {
    path: '/admin/users',
    component: Users
  },
  {
    path: '/admin/clients',
    component: Clients
  },
  {
    path: '/admin/login',
    component: Login
  }
]

export default routes
