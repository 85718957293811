import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import { object, string, array, mixed, number } from 'yup';
import { LoadingButton } from '../../components/common';
import TemplateActions from '../../redux/template';
import Criteria from '../audiences/components/Criteria';

const CreateDialogValidationSchema = object().shape({
  criterias: array()
    .of(
      object().shape({
        field: string().required('Field is required!'),
        op: string()
          .when('field', {
            is: (field) => ['order-count', 'total-spent', 'average-order-value'].indexOf(field) >= 0,
            then: string().required('Operator is required!'),
            otherwise: string().notRequired(),
          }),
        value: mixed()
          .when('field', {
            is: (field) => ['order-count', 'last-purchase', 'no-purchases'].indexOf(field) >= 0,
            then: number().required().positive().integer(),
          })
          .when('field', {
            is: (field) => ['total-spent', 'average-order-value'].indexOf(field) >= 0,
            then: string().required()
              .test(
                'is-decimal',
                'invalid decimal',
                value => (value + "").match(/^[0-9]+([,.][0-9]+)?$/),
              ),
          }),
      })
    )
});

export default function CreateDialog(props) {
  const dispatch = useDispatch();
  const { templateId, criteria, handleCloseDialog, setOpenSnackbar } = props;
  const createTemplateInitialValues = {
    criterias: [{field: criteria, op: '', value: ''}],
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    await dispatch(TemplateActions.createAudience(templateId, values.criterias));
    handleCloseDialog();
    setOpenSnackbar(true);
  };

  return (
    <Dialog
      open
      className="form-dialog criteria-dialog"
      disableBackdropClick
      disableEscapeKeyDown
      onClose={handleCloseDialog}
      aria-labelledby="create-dialog-title"
    >
      <Formik
        initialValues={createTemplateInitialValues}
        onSubmit={handleSubmit}
        validationSchema={CreateDialogValidationSchema}
      >
        {({ values, setFieldValue, isSubmitting, errors, touched }) => (
          <Form>
            <DialogTitle id="rename-dialog-title">Enter Your Value</DialogTitle>
            <DialogContent>
              <FieldArray
                name="criterias"
                render={() => (
                  <div className="criteria-list">
                    {values.criterias.map((row, index) => (
                      <div className="criteria-row" key={index}>
                        <Criteria
                          row={row}
                          index={index}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          width="220px"
                          disableCriteria
                        />
                      </div>
                    ))}
                  </div>
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button className="white-btn" onClick={handleCloseDialog}>Cancel</Button>
              <LoadingButton loading={isSubmitting} type="submit">
                Finish
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}