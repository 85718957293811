import React from 'react';
import AuthLayout from '../../containers/layout/AuthLayout';
import LoginForm from '../../containers/auth/LoginForm';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

function Login() {

  return (
    <AuthLayout name="auth login-page">
      <Box component={Paper} p={6} elevation={0} className="box-shadow login-box">
        <LoginForm />
      </Box>      
    </AuthLayout>
  );
}

export default Login;
