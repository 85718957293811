import { object, string } from 'yup';

export default object().shape({
  token: string().required('Token is invalid'),
  password: string().required('New password is required').min(7, 'Too short'),
  confirm_password: string()
    .when('password', {
      is: (password) => password && password.length > 0,
      then: string().required('Confirm new password is required')
        .test('passwords-match', 'Passwords must match!', function(value) {
          return this.parent.password === value;
        })
    }),
});