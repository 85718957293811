import React from 'react';
import { Field } from 'formik';
import { Select } from '../../../components/common';
import { CriteriaFieldOptions } from '../../../libs/const';
import {
  Box,
  InputAdornment,
  FormControl
} from '@material-ui/core';
import { FormBootstrapInput } from '../../../components/form';
import { Op } from '.';
import ProductPicker from '../../../containers/shopify/ProductPicker';
import CustomerTagPicker from '../../../containers/shopify/CustomerTagPicker';
import { DateTimeField, DateField } from '../../../components/form';

export default function Criteria(props) {
  const { row, index, setFieldValue, errors, touched, disableCriteria, width } = props;

  const handleCriteriaChange = () => event => {
    setFieldValue(`criterias[${index}].field`, event.target.value);
    setFieldValue(`criterias[${index}].op`, '');
    setFieldValue(`criterias[${index}].value`, '');
  };

  const errorFieldClass = () => {
    if (errors.criterias && errors.criterias[index] && errors.criterias[index].field
      && touched.criterias && touched.criterias[index] && touched.criterias[index].field) return 'Mui-error';
    else return '';
  }
  
  const renderOperatorAndValue = () => {
    if (row.field === 'order-count' || row.field === 'repeat-purchases') {
      if (row.op !== '') {
        const inputProps = row.field === 'repeat-purchases' ? {min: 2} : {};
        return (
          <>
            <Op
              index={index}
              value={row.op}
              criteria={row.field}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
            <Field
              className="small value-field"
              component={FormBootstrapInput}
              type="number"
              name={`criterias[${index}].value`}
              autoComplete="off"
              inputProps={{...inputProps}}
              placeholder="Enter a value"
            />
          </>
        )
      } else {
        return (
          <>
            <Op
              index={index}
              value={row.op}
              criteria={row.field}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
          </>
        )
      }
    } else if (['total-spent', 'average-order-value'].indexOf(row.field) >= 0) {
      if (row.op !== '') {
        return (
          <>
            <Op
              index={index}
              value={row.op}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
            <Field
              className="small value-field"
              component={FormBootstrapInput}
              type="text"
              name={`criterias[${index}].value`}
              autoComplete="off"
              placeholder="Enter a value"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </>
        )
      } else {
        return (
          <>
            <Op
              index={index}
              value={row.op}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
          </>
        )
      }
    } else if (['last-purchase', 'no-purchases'].indexOf(row.field) >= 0) {
      return (
        <>
          <span className="note">within the last</span>
          <Field
            className="small value-field"
            component={FormBootstrapInput}
            type="number"
            name={`criterias[${index}].value`}
            autoComplete="off"
            placeholder="Enter a value"
          />
          <span className="note">days</span>
        </>
      )
    } else if (['product'].indexOf(row.field) >= 0) {
      if (row.op !== '') {
        return (
          <>
            <Op
              index={index}
              value={row.op}
              criteria={row.field}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
            <ProductPicker
              size="small"
              limit={null}
              selectedProducts={row.value}
              setSelectedProducts={(products) => setFieldValue(`criterias[${index}].value`, products)}
            />
          </>
        )
      } else {
        return (
          <>
            <Op
              index={index}
              value={row.op}
              criteria={row.field}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
          </>
        )
      }
    } else if (['tag'].indexOf(row.field) >= 0) {
      if (row.op !== '') {
        return (
          <>
            <Op
              index={index}
              value={row.op}
              criteria={row.field}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
            <CustomerTagPicker
              selectedTags={row.value}
              setSelectedTags={(tags) => setFieldValue(`criterias[${index}].value`, tags)}
            />
          </>
        )
      } else {
        return (
          <>
            <Op
              index={index}
              value={row.op}
              criteria={row.field}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
          </>
        )
      }
    } else if (['became-customer'].indexOf(row.field) >= 0) {
      return (
        <>
          <Op
            index={index}
            value={row.op}
            criteria={row.field}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
          {row.op === '==' && <Field
              component={DateField}
              name={`criterias[${index}].value`}
              placeholder='On'
              onChange={(ev) => setFieldValue(`criterias[${index}].value`, ev.target.value)}
              value={row.value}
            />
          }
          {(row.op === '>' || row.op === '<' || row.op === '<>') && <Field
              component={DateTimeField}
              name={`criterias[${index}].value`}
              placeholder='On'
              onChange={(ev) => setFieldValue(`criterias[${index}].value`, ev.target.value)}
              value={row.value}
            />
          }
          {(row.op === '<>') && <Field
              component={DateTimeField}
              name={`criterias[${index}].value1`}
              placeholder='On'
              onChange={(ev) => setFieldValue(`criterias[${index}].value1`, ev.target.value)}
              value={row.value1}
            />
          }
        </>
      )
    }
  }

  return (
    <Box className={`${row.field}`}>
      <FormControl disabled={disableCriteria} className="criteria-field">
        <Field
          component={Select}
          size={`small ${errorFieldClass()}`}
          id={`criterias[${index}].field`}
          name={`criterias[${index}].field`}
          value={row.field}
          onChange={handleCriteriaChange()}
          options={CriteriaFieldOptions}
          placeholder="Select criteria"
          errors={errors}
          touched={touched}
          width={width}
        />
      </FormControl>

      {renderOperatorAndValue()}
    </Box>
  );
}
