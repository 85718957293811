import React from 'react';
import AuthLayout from '../../containers/layout/AuthLayout';
import ResendEmailForm from '../../containers/auth/ResendEmailForm';
import {
  Box,
  Paper
} from '@material-ui/core';

function ResendEmail() {

  return (
    <AuthLayout name="auth resend-email-page">
      <Box component={Paper} p={6} elevation={0} className="box-shadow login-box">
        <ResendEmailForm />
      </Box>
    </AuthLayout>
  );
}

export default ResendEmail;
