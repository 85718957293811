import { createReducer, createActions } from 'reduxsauce';
import apiCreate from '../../../services/admin';
import global from '../../GlobalRedux'

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setClients: ['clients'],
});

Creators.loadClients = () => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.clients();
    if (resp.ok) {
      dispatch(Creators.setClients(resp.data));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setSubLoading(false));
  };
};

Creators.add = (params) => {
  return async dispatch => {
    dispatch(global.setButtonLoading(true));

    const api = apiCreate();
    const resp = await api.addClient(params);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: `Your client has been added.`,
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setButtonLoading(false));
    dispatch(global.setDataRefresh(Math.random()));
  };
};

Creators.update = (id, params) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.updateClient(id, params);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your client has been updated.",
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setDataRefresh(Math.random()));
    dispatch(global.setSubLoading(false));
  };
};

Creators.delete = id => {
  return async dispatch => {
    dispatch(global.setButtonLoading(true));

    const api = apiCreate();
    const resp = await api.deleteClient(id);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your client has been deleted!",
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setButtonLoading(false));
    dispatch(global.setDataRefresh(Math.random()));
  };
};

export const ClientTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const ClientSelectors = {
  selectClients: state => state.client.clients,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  clients: [],
};

/* ------------------- Reducers --------------------- */
export const setClients = (state, { clients }) => ({
  ...state,
  clients
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CLIENTS]: setClients,
});
