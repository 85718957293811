import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Link as ReactLink } from 'react-router-dom';
import { Alert, LoadingButton } from '../../../components/common';
import { FormBootstrapInput } from '../../../components/form';
import AuthActions from '../../../redux/auth';
import validationSchema from './schema';

const INITIAL_VALUES = {
  email: '',
  password: '',
};

function LoginForm() {
  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(AuthActions.login(values.email, values.password, () => setSubmitting(false)));
  };

  return (
    <div className="login-form">
      <div className="logo-wrap">
        <img className="logo" alt="Logo" src="/logo121.png" />
      </div>
      <Alert />
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-control auth">
              <label className="form-label" htmlFor="email">Email Address</label>
              <Field component={FormBootstrapInput} name="email" autoComplete="on" placeholder='Enter your email address' />
            </div>
            <div className="form-control auth">
              <label className="form-label" htmlFor="email">Password</label>
              <Field component={FormBootstrapInput} name="password" type="password" placeholder='Enter your password' />              
            </div>
            <div className="form-control form-submit auth">
              <LoadingButton loading={isSubmitting} size="large" type="submit" fullWidth={true}>Sign In</LoadingButton>
            </div>
          </Form>
        )}
      </Formik>

      <div className="login-footer">
        <p>Don't have an account? <ReactLink to="/signup">Start your free trial</ReactLink></p>
        <ReactLink to="/forgot-password">Forgot your password?</ReactLink>
      </div>
    </div>
  );
}

export default LoginForm;
