import React from 'react';
import { useDispatch } from 'react-redux';
import AdAccountActions from '../../../redux/ad_account';
import { Box } from '@material-ui/core';
import { LoadingButton, GoogleServerLogin } from '../../../components/common';
import global from '../../../redux/GlobalRedux';
import { shop } from '../../../libs/storage';
import vars from '../../../libs/vars';
import { unix2Date } from '../../../libs/utils';

export default function ConnectGoogle(props) {
  const {
    handleComplete,
    googleAccount
  } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const handleGoogleLoginClick = renderProps => event => {
    renderProps.onClick()
    setLoading(true);
  }

  const responseGoogle = async (err, resp) => {
    if (err) {
      dispatch(global.setAlert({
        message: "There was an error with your Facebook authorization.",
        severity: "error",
      }));
    } else {
      const params = {
        partner: 'Google',
        shop: shop(),
        name: resp.name,
        email: resp.email,
        accessToken: resp.accessToken,
        refreshToken: resp.refreshToken,
        expireDate: unix2Date(resp.expiry_date),
        picture: resp.picture
      };

      await dispatch(AdAccountActions.connectAccount(params));
      handleComplete();
    }
    setLoading(false);
  }

  const disconnectAccount = async () => {
    await dispatch(AdAccountActions.disconnectAccount(googleAccount.id));
    handleComplete(false);
  }

  const rendergoogleAccountInfo = () => {
    return (
      <div className="connected-google">
        <div className="image">
          <img src={googleAccount.picture} alt="Google Account" />
        </div>
        <h4 className="google-name">{googleAccount.name}</h4>
        <LoadingButton onClick={disconnectAccount} color="secondary">Disconnect</LoadingButton>
      </div>
    );
  }

  return (
    <Box className="connect-google">
      <h2>Connect Google Account</h2>
      <p className="title-note">SyncTap uses your personal Google account to access your ad accounts.</p>
      {googleAccount ? (
        rendergoogleAccountInfo()
      ) : (
        <>
          <GoogleServerLogin
            callback={responseGoogle}
            render={renderProps => (
              <LoadingButton
                loading={loading}
                onClick={handleGoogleLoginClick(renderProps)}
                className="connect"
              >CONNECT GOOGLE ACCOUNT</LoadingButton>
            )}
          />
        </>
      )}
    </Box>
  );
}