import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalSelectors } from '../../redux/GlobalRedux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { LoadingButton } from '.';

export default function ConfirmDialog(props) {
  const loading = useSelector(GlobalSelectors.selectButtonLoading);
  const {
    title,
    content,
    handleCloseDialog,
    handleConfirmDialog,
    confirmButton,
    confirmColor
  } = props;

  return (
    <Dialog className="form-dialog confirm-dialog" open onClose={handleCloseDialog} aria-labelledby="confirm-dialog-title">
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </DialogContent>
      <DialogActions>
        <Button className="white-btn" onClick={handleCloseDialog}>Cancel</Button>
        <LoadingButton loading={loading} color={confirmColor || ''} onClick={handleConfirmDialog}>
          { confirmButton || "Confirm" }
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}