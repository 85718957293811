import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

function LoadingButton({
  loading,
  children,
  disabled,
  size,
  ...rest
}) {
  return (
    <Box className={`loading-btn ${size} ${loading && 'loading'}`}>
      <Button
        disabled={loading || disabled}
        size={size}
        {...rest}
      >
        {children}
        {loading && (
          <Box
            component={CircularProgress}
            size="28px"
          />
        )}
      </Button>
    </Box>
  );
}

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

LoadingButton.defaultProps = {
  size: 'medium'
};

export default LoadingButton;
