import Install from '../pages/Install';
import Error from '../pages/Error';
import Login from '../pages/auth/Login';
import SignUp from '../pages/auth/SignUp';
import ActivateAccount from '../pages/auth/ActivateAccount';
import VerifyEmail from '../pages/auth/VerifyEmail';
import ResendEmail from '../pages/auth/ResendEmail';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPasswordRequest from '../pages/auth/ResetPasswordRequest';
import SetPassword from '../pages/auth/SetPassword';
import StripeDirectCheckout from '../pages/pricing/StripeDirectCheckout';

const routes = [
  {
    path: '/install',
    component: Install
  },
  {
    path: '/error',
    component: Error
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/signup',
    component: SignUp
  },
  {
    path: '/activate-account',
    component: ActivateAccount
  },
  {
    path: '/verify-email',
    component: VerifyEmail
  },
  {
    path: '/resend-email',
    component: ResendEmail
  },
  {
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    path: '/reset-password-request',
    component: ResetPasswordRequest
  },
  {
    path: '/set-password',
    component: SetPassword
  },
  {
    path: '/secure-checkout/direct',
    component: StripeDirectCheckout
  }
]

export default routes
