import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  InputBase,
  Select,
  MenuItem,
} from '@material-ui/core';
import ReactTooltip from "react-tooltip";

export default function StyledSelect(props) {
  const { id, placeholder, options, width, size, ...rest } = props;
  
  const dispatch = useDispatch();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [dispatch]);

  return (
    <Select
      displayEmpty
      className={`select ${size}`}
      MenuProps={{
        disablePortal: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center"
        },
        getContentAnchorEl: null,
        id: `${id}-menu`,
        PaperProps: {
          elevation: 0,
        }
      }}
      inputProps={{
        id: `${id}`,
        name: `${id}`,
      }}
      input={<InputBase />}
      style={width && {width: width}}
      {...rest}
    >
      {placeholder && (<MenuItem disabled value=""><label className='placeholder'>{placeholder}</label></MenuItem>)}
      {options.map(o => (
        <MenuItem key={o.id} data-delay-show='400' data-tip={o.description || ''} data-for={`tooltip_${o.id}`} value={o.id}>
          <label>{o.name}</label>
          {o.description && <ReactTooltip
            id={`tooltip_${o.id}`}
            place="right" type="dark" effect="solid"
          />}
        </MenuItem>
      ))}
    </Select>
  );
}
