import { createReducer, createActions } from 'reduxsauce';
import { setUserToken, setUserId } from '../../libs/storage';
import apiCreate from '../../services/auth';
import global from '../GlobalRedux';
import history from '../../libs/history';

const api = apiCreate();

const fireConversionPixels = (user) => {
  // Facebook pixel
  window.fbq("track", "CompleteRegistration", {
    content_category: "Registration"
  });
  
  // Global Site Tag (gtag.js)
  window.gtag('event', 'CompleteRegistration', {
    event_category: 'Registration',
    event_label: 'Completed'
  });
}

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setEmail: ['email'],
  setInvalid: ['invalid'],
  setSnackbar: ['snackbar'],
  setResetToken: ['resetToken'],
  setPasswordCreated: ['passwordCreated'],
  setResetPasswordSent: ['resetPasswordSent'],
  setStripeSession: ['stripeSession']
});

Creators.login = (email, password, callback = null) => {
  return async dispatch => {
    dispatch(global.setLoading(true));
    const resp = await api.login(email, password);

    if (callback) callback();
    
    if (resp.ok) {
      setUserToken(resp.data.token);
      const user = resp.data.user;
      setUserId(user);

      user.role === 'admin' ? document.location.href = "/admin"
        : document.location.href = "/";
    } else {
      dispatch(global.setAlert({
        message: resp.data,
        severity: "error",
        icon: false
      }));
    }
    
    dispatch(global.setLoading(false));
  };
};

Creators.signup = (params, callback = null) => {
  return async dispatch => {
    dispatch(global.setLoading(true));
    const resp = await api.signup(params);

    if (callback) callback();
    
    if (resp.ok) {
      fireConversionPixels(resp.data.user);

      // Redirecting to verify email
      history.push('/verify-email' + window.location.search);
    } else {
      dispatch(global.setAlert({
        message: resp.data,
        severity: "error",
      }));
    }
    
    dispatch(global.setLoading(false));
  };
};

Creators.activateAccount = (store, params, callback = null) => {
  return async dispatch => {
    dispatch(global.setLoading(true));
    const resp = await api.activateAccount(store, params);

    if (callback) callback();
    
    if (resp.ok) {
      fireConversionPixels(resp.data.user);

      setUserToken(resp.data.token);
      setUserId(resp.data.user);

      document.location.href = "/";
    } else {
      dispatch(global.setAlert({
        message: resp.data,
        severity: "error",
      }));
    }
    
    dispatch(global.setLoading(false));
  };
};

Creators.sendVerificationEmail = (email, callback = null) => {
  return async dispatch => {
    dispatch(global.setLoading(true));
    const resp = await api.sendVerificationEmail(email);
    
    if (callback) callback();

    if (resp.ok) {
      history.push('/verify-email' + window.location.search);
    } else {
      dispatch(global.setAlert({
        message: resp.data,
        severity: "error",
        icon: false
      }));
    }
    
    dispatch(global.setLoading(false));
  };
};

Creators.resetPassword = (email, callback = null) => {
  return async dispatch => {
    dispatch(global.setLoading(true));
    const resp = await api.resetPassword(email);
    
    if (callback) callback();

    if (resp.ok) {
      // Redirecting to reset password request page
      history.push('/reset-password-request' + window.location.search);
    } else {
      dispatch(global.setAlert({
        message: resp.data,
        severity: "error",
        icon: false
      }));
    }
    
    dispatch(global.setLoading(false));
  };
};

Creators.setPassword = (resetToken, password, callback = null) => {
  return async dispatch => {
    dispatch(global.setLoading(true));
    const resp = await api.setPassword(resetToken, password);

    if (callback) callback();
    
    if (resp.ok) {
      history.push('/login' + window.location.search);
      dispatch(global.setAlert({
        message: "Your password has been updated",
        severity: "success",
        icon: false
      }));
    } else {
      dispatch(global.setAlert({
        message: resp.data,
        severity: "error",
        icon: false
      }));
    }

    dispatch(global.setLoading(false));
  };
};

Creators.loadResetToken = (token, type) => {
  return async dispatch => {
    if (type === 'verify') {
      dispatch(global.setLoading(true));
      const resp = await api.verify(token);
  
      if (resp.ok) {
        dispatch(Creators.setResetToken(resp.data.resetToken));
      } else {
        dispatch(Creators.setResetToken('invalid'));
        dispatch(global.apiError(resp));
      }
  
      dispatch(global.setLoading(false));
    } else {
      dispatch(Creators.setResetToken(token));
    }
  };
};

Creators.loadStripeSession = (sessionId) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));
    const resp = await api.getStripeSession(sessionId);

    if (resp.ok) {
      dispatch(Creators.setStripeSession(resp.data));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setSubLoading(false));
  };
};

export const AuthTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const AuthSelectors = {
  selectEmail: state => state.auth.email,
  selectInvalid: state => state.auth.invalid,
  selectSnackbar: state => state.auth.snackbar,
  selectResetToken: state => state.auth.resetToken,
  selectPasswordCreated: state => state.auth.passwordCreated,
  selectResetPasswordSent: state => state.auth.resetPasswordSent,
  selectStripeSession: state => state.auth.stripeSession
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  email: null,
  invalid: null,
  snackbar: null,
  resetToken: null,
  passwordCreated: null,
  resetPasswordSent: null,
  stripeSession: null
};

/* ------------------- Reducers --------------------- */
export const setEmail = (state, { email }) => ({
  ...state,
  email
});

export const setInvalid = (state, { invalid }) => ({
  ...state,
  invalid
});

export const setSnackbar = (state, { snackbar }) => ({
  ...state,
  snackbar
});

export const setResetToken = (state, { resetToken }) => ({
  ...state,
  resetToken
});

export const setPasswordCreated = (state, { passwordCreated }) => ({
  ...state,
  passwordCreated
});

export const setResetPasswordSent = (state, { resetPasswordSent }) => ({
  ...state,
  resetPasswordSent
});

export const setStripeSession = (state, { stripeSession }) => ({
  ...state,
  stripeSession
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_EMAIL]: setEmail,
  [Types.SET_INVALID]: setInvalid,
  [Types.SET_SNACKBAR]: setSnackbar,
  [Types.SET_RESET_TOKEN]: setResetToken,
  [Types.SET_PASSWORD_CREATED]: setPasswordCreated,
  [Types.SET_RESET_PASSWORD_SENT]: setResetPasswordSent,
  [Types.SET_STRIPE_SESSION]: setStripeSession
});
