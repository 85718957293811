import Setup from '../pages/setup';
import AdAccounts from '../pages/ad_accounts';
import AgencyStores from '../pages/stores/AgencyStores';
import Audiences from '../pages/audiences';
import CreateCba from '../pages/audiences/create_cba';
import CreateAba from '../pages/audiences/create_aba';
import CreateLookalikeFacebook from '../pages/audiences/create_lookalike_facebook';
import CreateLookalikeGoogle from '../pages/audiences/create_lookalike_google';
import Templates from '../pages/templates';
import AddShopify from '../pages/stores/AddShopify';
import Profile from '../pages/profile';
import Pricing from '../pages/pricing';
import StripeCheckout from '../pages/pricing/StripeCheckout';
import Error from '../pages/Error';

const routes = [
  {
    path: '/',
    component: AdAccounts
  },
  {
    path: '/setup',
    component: Setup
  },
  {
    path: '/my-stores',
    component: AgencyStores
  },
  {
    path: '/my-accounts',
    component: AdAccounts
  },
  {
    path: '/my-audiences',
    component: Audiences
  },
  {
    path: '/create-cba',
    component: CreateCba
  },
  {
    path: '/my-audiences/:id',
    component: CreateCba
  },
  {
    path: '/create-aba',
    component: CreateAba
  },
  {
    path: '/create-lookalike-facebook',
    component: CreateLookalikeFacebook,
  },
  {
    path: '/create-lookalike-google',
    component: CreateLookalikeGoogle,
  },
  {
    path: '/templates',
    component: Templates
  },
  {
    path: '/my-stores/add',
    component: AddShopify
  },
  {
    path: '/integrations/shopify/add',
    component: AddShopify
  },
  {
    path: '/settings/my-profile',
    component: Profile
  },
  {
    path: '/pricing',
    component: Pricing
  },
  {
    path: '/secure-checkout',
    component: StripeCheckout
  },
  {
    path: '/error',
    component: Error
  }
]

export default routes
