import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Alert, LoadingButton } from '../../../components/common';
import { FormBootstrapInput } from '../../../components/form';
import AuthActions from '../../../redux/auth';
import validationSchema from './schema';
import { splitName, params } from '../../../libs/utils';

const INITIAL_VALUES = {
  name: '',
  email: '',
  password: '',
};

function ActivateAccountForm() {
  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting }) => {
    const { firstName, lastName } = splitName(values.name);
    const store = params('shop');
    const p = {
      firstName,
      lastName,
      email: values.email,
      password: values.password
    }

    dispatch(AuthActions.activateAccount(store, p, () => setSubmitting(false)));
  };

  return (
    <div className="activate-account-form">
      <div className="signup-header">
        <h2>Almost there! Register your SyncTap account</h2>
      </div>
      <Alert />
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-control auth">
              <label className="form-label" htmlFor="name">Your Name</label>
              <Field component={FormBootstrapInput} name="name" autoComplete="off" />
            </div>
            <div className="form-control auth">
              <label className="form-label" htmlFor="email">Email Address</label>
              <Field component={FormBootstrapInput} name="email" autoComplete="off" />
            </div>
            <div className="form-control auth">
              <label className="form-label" htmlFor="email">Password</label>
              <Field component={FormBootstrapInput} name="password" type="password" />              
            </div>
            <div className="form-control form-submit auth">
              <LoadingButton loading={isSubmitting} size="large" type="submit" fullWidth={true}>Activate Account</LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ActivateAccountForm;
