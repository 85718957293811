import { createReducer, createActions } from 'reduxsauce';
import apiCreate from '../../../services/admin';
import global from '../../GlobalRedux'
import { getUserToken, getUserId, setGodUserToken, setGodUserId, setUserToken, setUserId } from '../../../libs/storage';

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setUsers: ['users'],
});

Creators.godLogin = (userId) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.usersGodLogin(userId);
    if (resp.ok) {
      // login
      const currentToken = getUserToken();
      const currentUserId = getUserId();
      setGodUserToken(currentToken);
      setGodUserId(currentUserId);

      setUserToken(resp.data.token);
      const user = resp.data.user;
      setUserId(user);

      user.role === 'admin' ? document.location.href = "/admin"
        : document.location.href = "/";
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setSubLoading(false));
  };
}

Creators.loadUsers = () => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.users();
    if (resp.ok) {
      dispatch(Creators.setUsers(resp.data));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setSubLoading(false));
  };
};

Creators.add = (params) => {
  return async dispatch => {
    dispatch(global.setButtonLoading(true));

    const api = apiCreate();
    const resp = await api.addUser(params);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: `Your user has been added.`,
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setButtonLoading(false));
    dispatch(global.setDataRefresh(Math.random()));
  };
};

Creators.update = (id, params) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.updateUser(id, params);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your user has been updated.",
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setDataRefresh(Math.random()));
    dispatch(global.setSubLoading(false));
  };
};

Creators.delete = id => {
  return async dispatch => {
    dispatch(global.setButtonLoading(true));

    const api = apiCreate();
    const resp = await api.deleteUser(id);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your user has been deleted!",
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setButtonLoading(false));
    dispatch(global.setDataRefresh(Math.random()));
  };
};

export const UserTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const UserSelectors = {
  selectUsers: state => state.user.users,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  users: [],
};

/* ------------------- Reducers --------------------- */
export const setUsers = (state, { users }) => ({
  ...state,
  users
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_USERS]: setUsers,
});
