import api from './api';
import { shop } from '../libs/storage';

export default () => {
  const $ = api;
  const getTemplates = () => $.get(`/app/templates?shop=${shop()}`);
  const createAudience = (id, criteria) => $.post(`/app/templates/createAudience?shop=${shop()}&id=${id}`, criteria);
  const removeTemplate = id => $.post(`/app/templates/removeTemplate?shop=${shop()}&id=${id}`);

  return {
    getTemplates,
    createAudience,
    removeTemplate
  };
};
