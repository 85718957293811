
import { isMobile, isShopifyEmbedded } from "@shopify/app-bridge-utils";

// Works as simple wrapper around async storage
const APP_ID = 'ET_';
// Coming from index.html cdn universl cookie
// eslint-disable-next-line no-undef
const cookies = new UniversalCookie();

const storage = {}

export const setItem = (key, data) => {
  // localStorage.setItem(APP_ID + key, JSON.stringify(data))
  // cookies.set(APP_ID + key, JSON.stringify({ value: data }), {
  //   maxAge: 2147483647
  // })

  if (isShopifyEmbedded()) {
    storage[APP_ID + key] = data
  } else {
    localStorage.setItem(APP_ID + key, JSON.stringify(data))
  }
}

export const getItem = (key, defaultVal) => {
  try {
    // return JSON.parse(localStorage.getItem(APP_ID + key)) || defaultVal
    // console.log('getItem', cookies.get(APP_ID + key))
    // return cookies.get(APP_ID + key) && cookies.get(APP_ID + key).value
    //   ? cookies.get(APP_ID + key).value
    //   : defaultVal
    if (isShopifyEmbedded()) {
      return storage[APP_ID + key] || defaultVal
    } else {
      return JSON.parse(localStorage.getItem(APP_ID + key)) || defaultVal
    }
  } catch (e) {
    return defaultVal || false
  }
}

export const removeItem = key => {
  // localStorage.removeItem(APP_ID + key)
  // cookies.remove(APP_ID + key)
  if (isShopifyEmbedded()) {
    delete storage[APP_ID + key]
  } else {
    localStorage.removeItem(APP_ID + key)
  }
}