import React from 'react';
import MainLayout from '../containers/layout/MainLayout';

function Error() {
  return (
    <MainLayout title="Welcome">
      <h2>Something went wrong! You're not authorized to use this app.</h2>
    </MainLayout>
  );
}

export default Error;
