import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { LoadingContainer } from '../../components/common';
import vars from '../../libs/vars';

function StripeCheckout() {
  const dispatch = useDispatch();

  useEffect(() => {
    const redirectToCheckout = async () => {
      const stripePromise = loadStripe(vars.REACT_APP_STRIPE_PUBLISHABLE_KEY);
      const stripe = await stripePromise;
      const response = await fetch(`${vars.REACT_APP_API_BASE}/stripe/create-session${window.location.search}`, { method: 'POST' });
      const session = await response.json();

      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        alert('Failed due to your browser or network error - ' + result.error.message);
      }
    };

    redirectToCheckout();
  }, [dispatch]);

  return (
    <LoadingContainer />
  );
}

export default StripeCheckout;
