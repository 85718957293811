import React from 'react';
import AuthLayout from '../../containers/layout/AuthLayout';
import ActivateAccountForm from '../../containers/auth/ActivateAccountForm';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

function ActivateAccount() {

  return (
    <AuthLayout name="auth sign-up-page">
      <Box component={Paper} p={6} elevation={0} className="login-box">
        <ActivateAccountForm />
      </Box>      
    </AuthLayout>
  );
}

export default ActivateAccount;
