import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { GlobalSelectors } from '../../../redux/GlobalRedux';
import {
  AppBar,
  Box,
} from '@material-ui/core';
import {
  LinearProgress,
} from '../../../components/common';

function Header({user}) {
  const loading = useSelector(GlobalSelectors.selectLoading);

  const MenuBar = () => (
    <>
      {loading && <LinearProgress />}
      <Box className="menu-bar">
        <NavLink to={'/admin/clients'}>
          Clients
        </NavLink>
        <NavLink to={'/admin/users'}>
          Users
        </NavLink>
      </Box>
    </>
  )

  return (
    <AppBar position="static" elevation={0}>
      {user && <MenuBar />}
    </AppBar>
  );
}

export default withRouter(Header);
