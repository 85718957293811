import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { object, string } from "yup";
import AdAccountActions, {
  AdAccountSelectors,
} from "../../../redux/ad_account";
import { Select, LoadingButton } from "../../../components/common";
import { setItem } from "../../../libs/storage-wrapper";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const INITIAL_VALUES = {
  adAccountId: "",
};

const ConnectValidationSchema = object().shape({
  adAccountId: string().required("Ad account ID is required!"),
});

export default function ConnectAdAccount(props) {
  const { handleComplete, facebookAccount } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const loadAdAccountsForConnection = () => {
      if (!facebookAccount || !facebookAccount.accessToken) return;
      dispatch(
        AdAccountActions.loadAdAccountsForConnection(
          facebookAccount.partner,
          facebookAccount.accessToken
        )
      );
    };

    loadAdAccountsForConnection();
  }, [dispatch, facebookAccount]);

  const rows = useSelector(AdAccountSelectors.selectAdAccountsForConnection);
  if (rows === null) return null;

  const options = rows.map((r) => ({
    id: r.account_id,
    name: `${r.name} (${r.account_id})`,
  }));

  const handleChange = (setFieldValue) => (event) => {
    setFieldValue(`adAccountId`, event.target.value);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const params = {
      ...facebookAccount,
      adAccountId: values.adAccountId,
      adAccountName: "",
    };

    const result = rows.filter((v) => v.account_id === values.adAccountId);
    if (result.length > 0) params.adAccountName = result[0].name;

    await dispatch(AdAccountActions.connectAdAccount(params));

    setSubmitting(false);
    handleComplete();
    setItem("AdAccountId", values.adAccountId);
  };

  const disconnectAdAccount = async () => {
    await dispatch(AdAccountActions.disconnectAdAccount(facebookAccount.id));
    handleComplete(false);
  };

  const renderFacebookAdAccountInfo = () => {
    return (
      <div className="connected-ad-account">
        <div className="image">
          <AccountCircleIcon />
        </div>
        <div className="ad-account-info">
          <h4 className="ad-account-name">
            <label>{facebookAccount.adAccountName}</label>
            <a
              href={`https://business.facebook.com/adsmanager/audiences?act=${facebookAccount.adAccountId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OpenInNewIcon />
            </a>
          </h4>
          <div className="ad-account-id">{`Ad Account ID: ${facebookAccount.adAccountId}`}</div>
        </div>
        <LoadingButton onClick={disconnectAdAccount} color="secondary">
          Disconnect
        </LoadingButton>
      </div>
    );
  };

  return (
    <Box className="connect-facebook-ad-account">
      <h2>Connect Ad Account</h2>
      <p className="title-note">
        Select the ad account your custom audiences will be created by SyncTap.
        You can only connect to accounts you have admin access to.
      </p>
      {facebookAccount.adAccountId ? (
        renderFacebookAdAccountInfo()
      ) : (
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={handleSubmit}
          validationSchema={ConnectValidationSchema}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="form-control light">
                <Field
                  component={Select}
                  id="adAccountId"
                  name="adAccountId"
                  value={values.adAccountId}
                  onChange={handleChange(setFieldValue)}
                  options={options}
                  placeholder="Select Ad Account"
                />
              </div>
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                className="connect"
              >
                CONNECT AD ACCOUNT
              </LoadingButton>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
}
