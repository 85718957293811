/* Same as InputField but support date time field as default */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputFieldStyles from '../../styles/input';

const inpuFieldtStyles = theme => InputFieldStyles(theme);

function InputField(props) {
  const { field, classes, form, helperText, disabled, InputProps, ...rest } = props;
  const inputProps = {...InputProps, ...{classes: classes}};
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  return (
    <FormControl error={!!(touched && error)}>
      <TextField
        type="date"
        id={field.name}
        error={!!(touched && error)}
        disabled={form.isSubmitting || disabled}
        InputLabelProps={{
          shrink: false
        }}
        InputProps={inputProps}
        {...field}
        {...rest}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

InputField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  disabled: PropTypes.bool,
  helperText: PropTypes.string
};

export default withStyles(inpuFieldtStyles)(InputField);
