import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MainLayout from '../../containers/layout/MainLayout';
import GlobalActions, { GlobalSelectors } from '../../redux/GlobalRedux';
import AdAccountActions, { AdAccountSelectors } from '../../redux/ad_account';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@material-ui/core';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleServerLogin, Select, ConfirmDialog, LoadingContainer, Alert } from '../../components/common';
import { shop } from '../../libs/storage';
import { PlatformOptions, DialogInitialOpenState } from '../../libs/const';
import vars from '../../libs/vars';
import { unix2Date, formatDate } from '../../libs/utils';
import { Refresh as RefreshIcon, Delete as DeleteIcon } from '@material-ui/icons';
import ConnectDialog from './ConnectDialog';
import { Link as ReactLink } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import { ProfileSelectors } from '../../redux/profile';
import ConnectShopify from '../setup/components/ConnectShopify';
import GoogleButton from 'react-google-button'

function AdAccounts() {
  const dispatch = useDispatch();
  const user = useSelector(ProfileSelectors.selectProfile);
  const refresh = useSelector(GlobalSelectors.selectDataRefresh);
  const rows = useSelector(AdAccountSelectors.selectAdAccounts);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const shouldReconnectShopify = user.store && user.store.status === 'needs-reauth';
  const [store, setStore] = React.useState(user.store);

  useEffect(() => {
    const loadAdAccounts = () => {
      dispatch(AdAccountActions.loadAdAccounts());
    };

    loadAdAccounts();
  }, [dispatch, refresh]);

  const [adAccount, setAdAccount] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(DialogInitialOpenState);
  const [loading, setLoading] = React.useState(false);

  const handleOpenDialog = (name, row = null) => event => {
    setOpenDialog({ ...openDialog, [name]: true });
    if (row) setSelectedRow(row);
  };
  const handleCloseDialog = name => () => {
    setOpenDialog({ ...openDialog, [name]: false });
  };

  const [selectedAdAccount, setSelectedAdAccount] = React.useState('Facebook');
  const handleAdAccountChange = (event) => {
    setSelectedAdAccount(event.target.value);
  };

  let canAddMore = true;
  if (rows && rows.length === 1 &&
    user && 
    (user.pricing === 'starter' || user.pricing === 'basic' || user.pricing === 'free')) {
    canAddMore = false
  }

  const AdAccountSelect = () => (
    <Select
      name="ad-account-select"
      value={selectedAdAccount}
      onChange={handleAdAccountChange}
      options={PlatformOptions}
      placeholder="Select Service"
      width="300px"
    />
  )

  const handleFbLoginClick = renderProps => event => {
    renderProps.onClick();
    dispatch(GlobalActions.setLoading(true));
  }

  const responseFacebook = async resp => {
    // status is set if not successful, otherwise not
    if (resp.status || !resp.accessToken) {
      dispatch(GlobalActions.setAlert({
        message: "There was an error with your Facebook authorization.",
        severity: "error",
      }));
    } else {
      setAdAccount({
        partner: 'Facebook',
        shop: shop(),
        name: resp.name,
        email: resp.email,
        accessToken: resp.accessToken,
        refreshToken: '',
        tokenError: false,
        expireDate: unix2Date(resp.data_access_expiration_time),
        picture: resp.picture.data.url
      });
      
      const newOpenDialog = { ...openDialog };
      newOpenDialog.adAccountConnect = true;
      setOpenDialog({ ...newOpenDialog });
    }
    dispatch(GlobalActions.setLoading(false));
  }

  const handleGoogleLoginClick = renderProps => event => {
    renderProps.onClick()
    setLoading(true);
  }

  const responseGoogle = async (err, resp) => {
    if (err) {
      dispatch(GlobalActions.setAlert({
        message: "There was an error with your Google authorization.",
        severity: "error",
      }));
    } else {
      setAdAccount({
        partner: 'Google',
        shop: shop(),
        name: resp.name,
        email: resp.email,
        accessToken: resp.accessToken,
        refreshToken: resp.refreshToken,
        tokenError: false,
        expireDate: unix2Date(resp.expiry_date),
        picture: resp.picture
      });

      const newOpenDialog = { ...openDialog };
      newOpenDialog.adAccountConnect = true;
      setOpenDialog({ ...newOpenDialog });
    }
    setLoading(false);
  }
  
  const deleteAdAccount = async () => {
    await dispatch(AdAccountActions.delete(selectedRow.id));
    handleCloseDialog('delete')();
  }

  const renderTableRow = (row, i) => {
    let expireDate = formatDate(row.expireDate);
    if (row.partner === 'Google') {
      expireDate = 'Never'
    }

    return (
      <TableRow component="div" key={i}>
        <TableCell component="div" scope="row" align="center">{i + 1}</TableCell>
        <TableCell component="div" align="left">{row.partner} {row.tokenError ? '(Invalid)' : ''}</TableCell>
        <TableCell component="div" align="left">{row.name}</TableCell>
        <TableCell component="div" align="left">{`${row.adAccountName} (${row.adAccountId})`}</TableCell>
        <TableCell component="div" align="left">{formatDate(row.updatedAt)}</TableCell>
        <TableCell component="div" align="left">{expireDate}</TableCell>
        <TableCell component="div" align="center" className="row-actions">
          <Box className="action-menu">
            {row.partner === 'Facebook' && (
              <FacebookLogin
                appId={vars.REACT_APP_FACEBOOK_APP_ID}
                fields="name,email,picture"
                scope="ads_management,business_management,email"
                autoLoad={false}
                disableMobileRedirect={true}
                callback={responseFacebook}
                textButton="Login with Facebook"
                render={renderProps => {
                  return !row.tokenError ? (
                    <IconButton
                      aria-label="Refresh"
                      onClick={handleFbLoginClick(renderProps)}
                      title="Refresh"
                    >
                      <RefreshIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <Button
                      color="secondary"
                      onClick={handleFbLoginClick(renderProps)}
                      className="add-row"
                    >Refresh Facebook</Button>
                  )
                }}
              />
            )}
            <IconButton
              aria-label="Delete"
              onClick={handleOpenDialog('delete', row)}
              title="Delete"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    );
  }

  const renderTable = () => (
    <TableContainer component={Paper} elevation={0}>
      <Table className="table no-spacing filled-th" component="div">
        <TableHead component="div">
          <TableRow component="div">
            <TableCell component="div" align="center" width="140px">ID</TableCell>
            <TableCell component="div" align="left" width="18%">Service</TableCell>
            <TableCell component="div" align="left" width="18%">User</TableCell>
            <TableCell component="div" align="left" width="18%">Ad Account</TableCell>
            <TableCell component="div" align="left" width="18%">Date Added</TableCell>
            <TableCell component="div" align="left" width="18%">Expire Date</TableCell>
            <TableCell component="div">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody component="div">
          {rows && rows.map((row, i) => renderTableRow(row, i))}
        </TableBody>
      </Table>
      {(rows && rows.length === 0) && <div className="no-rows">No Data Found</div>}

    </TableContainer>
  )

  const renderNeedsShopifyReauth = () => {
    const handleComplete = () => {
      // pass
    }
    
    return <Box className="page-header">
      <h3>Connect Shopify</h3>
      <p className="title-note">Please connect Shopify</p>

      <ConnectShopify
        store={store}
        setStore={setStore}
        handleComplete={handleComplete}
      />
    </Box>
  }

  return (
    <MainLayout className="my-account-page" title="My Account">
      <LoadingContainer />
      <Alert
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <Box className="page-header">
        <h3>Connected Ad Accounts</h3>
        <p className="title-note">Connect and sync audiences to your ad accounts</p>
      </Box>

      { canAddMore && <Box className="add-account">
        <AdAccountSelect />
        {selectedAdAccount === 'Facebook' && (
          <FacebookLogin
            appId={vars.REACT_APP_FACEBOOK_APP_ID}
            fields="name,email,picture"
            scope="ads_management,business_management,email"
            callback={responseFacebook}
            textButton="Login with Facebook"
            render={renderProps => (
              <Button
                onClick={handleFbLoginClick(renderProps)}
                className="add-row"
              >Login with Facebook</Button>
            )}
          />
        )}
        {selectedAdAccount === 'Google' && (
          <GoogleServerLogin
            callback={responseGoogle}
            render={renderProps => (
              <GoogleButton
                onClick={handleGoogleLoginClick(renderProps)}
              />
            )}
          />
        )}
      </Box>}

      {!canAddMore && (
        <Box className="alert-box">
          <MuiAlert
            elevation={0}
            severity="warning"
          >
            <strong>Warning!</strong>
            <div className="alert-text">
              You can only connect 1 platform. To connect more, please upgrade your plan <ReactLink to="/pricing" className="alert-link">here</ReactLink>.
            </div>
          </MuiAlert>
        </Box>
      )}

      {openDialog.delete && (
        <ConfirmDialog
          handleCloseDialog={handleCloseDialog('delete')}
          handleConfirmDialog={deleteAdAccount}
          confirmButton="Delete"
          confirmColor="secondary"
          title="Delete Ad Account"
          content="Are you sure you want to permanently delete this ad account?"
        />
      )}

      {openDialog.adAccountConnect && (
        <ConnectDialog
          adAccount={adAccount}
          handleCloseDialog={handleCloseDialog('adAccountConnect')}
          setOpenSnackbar={setOpenSnackbar}
        />
      )}

      { renderTable() }


      {shouldReconnectShopify && renderNeedsShopifyReauth() }
    </MainLayout>
  );
}

export default AdAccounts;
