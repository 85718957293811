import { createReducer, createActions } from 'reduxsauce';
import apiCreate from '../../services/template';
import global from '../GlobalRedux'

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setTemplates: ['templates'],
});

Creators.loadTemplates = () => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.getTemplates();
    if (resp.ok) {
      dispatch(Creators.setTemplates(resp.data));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setSubLoading(false));
  };
};

Creators.createAudience = (id, criterias = null) => {
  return async dispatch => {
    dispatch(global.setButtonLoading(true));

    const api = apiCreate();
    const resp = await api.createAudience(id, { criterias });
    if (resp.ok) {
      dispatch(global.setAlert({
        message: `Your audience has been created.`,
        link: `/my-audiences`,
        linkText: 'View Audiences',
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setButtonLoading(false));
    dispatch(global.setDataRefresh(Math.random()));
  };
};

Creators.removeTemplate = id => {
  return async dispatch => {
    dispatch(global.setButtonLoading(true));

    const api = apiCreate();
    const resp = await api.removeTemplate(id);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: `Your template has been removed.`,
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setButtonLoading(false));
    dispatch(global.setDataRefresh(Math.random()));
  };
};

export const TemplateTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const TemplateSelectors = {
  selectTemplates: state => state.template.templates,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  templates: [],
};

/* ------------------- Reducers --------------------- */
export const setTemplates = (state, { templates }) => ({
  ...state,
  templates
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_TEMPLATES]: setTemplates,
});
