import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import AuthLoadingContainer from "./components/common/AuthLoadingContainer";
import Header from "./containers/layout/Header";
import globalActions, { GlobalSelectors } from "./redux/GlobalRedux";
import ProfileActions, { ProfileSelectors } from "./redux/profile";
import { getItem } from "./libs/storage-wrapper";
import userRoutes from "./routes/user";
import guestRoutes from "./routes/guest";
import { isAdAccountConnected } from "./libs/utils";
import { useAppBridge, useAppBridgeState } from "@shopify/app-bridge-react";
import { NavigationMenu } from "@shopify/app-bridge-react";
import { isMobile, isShopifyEmbedded } from "@shopify/app-bridge-utils";
import { getSessionToken } from "@shopify/app-bridge-utils";
import CircularProgress from "./components/common/CircularProgress";
import api from "./services/api";

const getRoutes = (user) => {
  let routes = [];
  if (user) {
    const adAccounts = getItem("AD_ACCOUNTS");

    if (user.onboarding !== 9) {
      // Setup is not done yet
      routes = userRoutes.filter((v) => v.path === "/setup");
    } else if (adAccounts && !adAccounts.length) {
      // Accessing my accounts page only
      routes = userRoutes.filter((v) => {
        return (
          v.path === "/my-accounts" ||
          v.path === "/pricing" ||
          v.path === "/my-stores" ||
          v.path.startsWith("/settings") ||
          v.path.startsWith("/oauth/")
        );
      });
    } else {
      routes = userRoutes;
    }
  } else {
    routes = guestRoutes;
  }

  return routes;
};

const className = (user, path) => {
  const embedded = getItem("EMBEDDED");
  let cls = [];
  if (user) cls.push("logged-in");
  embedded ? cls.push("embedded") : cls.push("standalone");

  const guestPaths = guestRoutes.map((v) => v.path);
  if (guestPaths.includes(path)) cls.push("auth-page");
  return cls.join(" ");
};

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(ProfileSelectors.selectProfile);
  const loading = useSelector(GlobalSelectors.selectInitialLoading);
  const token = useSelector(GlobalSelectors.selectToken);
  const isEmbedded = useSelector(GlobalSelectors.selectIsEmbedded);

  useEffect(() => {
    const loadProfile = () => {
      dispatch(ProfileActions.loadProfile(isEmbedded));
    };

    loadProfile();
  }, [dispatch, isEmbedded]);

  let routes = getRoutes(user);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      className={className(user, location.pathname)}
    >
      {/* {isEmbedded && (
        <NavigationMenu
          navigationLinks={[
            // {
            //   label: "Page name",
            //   destination: "/pagename",
            // },
          ]}
        />
      )} */}
      <AuthLoadingContainer loading={loading}>
        {() => (
          <>
            <Header user={user} />
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  render={(props) => (
                    <route.component {...props} routes={route.routes} />
                  )}
                  exact
                />
              ))}

              {user && user.onboarding !== 9 && <Redirect to="/setup" />}
              {user && !isAdAccountConnected(user) && (
                <Redirect to="/my-accounts" />
              )}
              {user && <Redirect to="/templates" />}
              {!token && <Redirect to="/login" />}
            </Switch>
          </>
        )}
      </AuthLoadingContainer>
    </Box>
  );
}

function EmbeddedApp() {
  const location = useLocation();
  const app = useAppBridge();
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const prepareShopifySession = async () => {
      try {
        const sessionToken = await getSessionToken(app);
        console.log('Loaded session token', sessionToken)

        dispatch(globalActions.setShopifySession(sessionToken));
        const token = '$shopify$ ' + sessionToken
        dispatch(globalActions.setToken(token));
        api.setHeader('Authorization', `Bearer ${token}`)
        dispatch(globalActions.setIsEmbedded(true));
        console.log(location.search)
        history.push('/' + location.search)

        setIsLoading(false)
      } catch (err) {
        // pass
        console.log(err)
      }
    };

    prepareShopifySession();
  }, [app]);

  if (isLoading) {
    const boxProps = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 1,
      p: 4,
      minHeight: "200px",
    };

    return (
      <Box {...boxProps}>
        <CircularProgress />
      </Box>
    );
  }

  return <App />
}

function AppWrapper() {
  // const location = useLocation();
  const isEmbedded = isShopifyEmbedded();

  if (isEmbedded) {
    return <EmbeddedApp />;
  } else {
    return <App />;
  }
}

export default AppWrapper;
