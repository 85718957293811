import modal from './modal';

const login = shop => new Promise((resolve, reject) => {
  modal.open(`${process.env.REACT_APP_API_BASE}/oauth/google/login`)
    .then(res => resolve(res)).catch(err => reject(err));
});

export default {
  login,
}
