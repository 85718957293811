import React from 'react';
import AuthLayout from '../../containers/layout/AuthLayout';
import ForgotPasswordForm from '../../containers/auth/ForgotPasswordForm';
import {
  Box,
  Paper
} from '@material-ui/core';

function ForgotPassword() {

  return (
    <AuthLayout name="auth resend-email-page">
      <Box component={Paper} p={6} elevation={0} className="box-shadow login-box">
        <ForgotPasswordForm />
      </Box>
    </AuthLayout>
  );
}

export default ForgotPassword;
