import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { object, string } from 'yup';
import AdAccountActions, { AdAccountSelectors }  from '../../redux/ad_account';
import { Select, LoadingButton } from '../../components/common';
import storage from '../../libs/storage';

const INITIAL_VALUES = {
  adAccountId: '',
};

const ConnectValidationSchema = object().shape({
  adAccountId: string().required('Ad account ID is required!'),
});

export default function ConnectDialog(props) {
  const { handleCloseDialog, adAccount, setOpenSnackbar } = props;
  const dispatch = useDispatch();
  
  useEffect(() => {
    const loadAdAccountsForConnection = () => {
      dispatch(AdAccountActions.loadAdAccountsForConnection(adAccount.partner, adAccount.accessToken));
    };

    loadAdAccountsForConnection();
  }, [dispatch, adAccount]);

  const rows = useSelector(AdAccountSelectors.selectAdAccountsForConnection);
  if (rows === null) return null;

  const options = rows.map(r => ({"id": r.account_id, "name": `${r.name} (${r.account_id})`}));
  
  const handleChange = (setFieldValue) => event => {
    setFieldValue(`adAccountId`, event.target.value);
  };
  
  const handleSubmit = async (values, { setSubmitting }) => {
    const params = {
      ...adAccount,
      'adAccountId': values.adAccountId
    }

    const result = rows.filter(v => v.account_id === values.adAccountId);
    if (result.length > 0) params.adAccountName = result[0].name;

    await dispatch(AdAccountActions.create(params, () => {
      setSubmitting(false);
      handleCloseDialog();
      setOpenSnackbar(true);
    }));
    storage.set("AdAccountId", values.adAccountId);
  };
  
  return (
    <Dialog
      className="form-dialog"
      open={rows.length > 0}
      disableBackdropClick
      disableEscapeKeyDown
      onClose={handleCloseDialog}
      aria-labelledby="connect-dialog-title"
    >
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={ConnectValidationSchema}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <DialogTitle id="rename-dialog-title">Connect Ad Account</DialogTitle>
            <DialogContent>
              <div className="form-control light">
                <Field
                  component={Select}
                  id="adAccountId"
                  name="adAccountId"
                  value={values.adAccountId}
                  onChange={handleChange(setFieldValue)}
                  options={options}
                  placeholder="Select Ad Account"
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button className="white-btn" onClick={handleCloseDialog}>Cancel</Button>
              <LoadingButton loading={isSubmitting} type="submit">
                Connect
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}