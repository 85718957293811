import React from 'react';
import { useSelector } from 'react-redux';
import _ from "lodash";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Checkbox
} from '@material-ui/core';
import { ProductSelectors } from '../../../redux/product';
import { LoadingButton, BootstrapInput } from '../../../components/common';
import CircularProgress from '../../../components/common/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const allProducts = {id: 1, handle: 'all-products', title: 'All Products'};
let previousQuery = '', currentQuery = '', selected = [];
let firstTimeAccessed = 1;

const ListItem = ({v, checked, handleProductChange}) => {
  const src = v.image ? v.image.src.replace(/.([^.]*)$/, "_x40.$1") : "/images/no-image.svg";
  const inputRef = React.useRef();

  const handleListClick = () => (event) => {
    // Prevent double firing when you click input
    if (event.target === inputRef.current) return;
    inputRef.current.click();
  }

  const handleToggle = v => () => {
    // Prevent click event if all the allowed products are selected
    if (inputRef.current.closest('.product-list').classList.contains('limited')
      && inputRef.current.closest('.product-item').classList.contains('unchecked')) return;
    handleProductChange(v);
  }

  return (
    <MuiListItem className={checked ? "product-item checked" : "product-item unchecked"} button onClick={handleListClick()}>
      <ListItemIcon className="checkbox">
        <Checkbox
          disableRipple
          inputRef={inputRef}
          value={`${v.id}`}
          checked={checked}
          onChange={handleToggle(v)}
          color="primary"
        />
      </ListItemIcon>
      <ListItemIcon className={!v.image ? 'image-wrap no-image' : 'image-wrap image'}>
        <img src={src} alt="Product" />
      </ListItemIcon>
      <ListItemText primary={v.title} />
    </MuiListItem>
  )
};

const SearchInput = ({ defaultValue, search, searching }) => {
  const inputRef = React.useRef();
  let typingTimer = 0;                //timer identifier
  const doneTypingInterval = 1200;    //time in ms, 5 second for example

  //user is "finished typing," do something
  const doneTyping = () => {
    if (!inputRef.current) return;
    if (previousQuery === inputRef.current.value) return;
    currentQuery = inputRef.current.value;
    search(currentQuery);
    previousQuery = inputRef.current.value
  }

  const handleKeyUp = () => (e) => {
    if (e.key === 'Enter') {
      doneTyping();
    } else {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(doneTyping, doneTypingInterval);
    }
  }

  return (
    <div className="search-input">
      <BootstrapInput
        autoFocus
        inputRef={inputRef}
        defaultValue={defaultValue}
        placeholder="Search products"
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        onKeyUp={handleKeyUp()}
        onKeyDown={() => clearTimeout(typingTimer)}
      />
      {searching && <div className="searching">
        <CircularProgress width={24} />
      </div>}
    </div>
  );
}

const Loader = () => {
  const productLoading = useSelector(ProductSelectors.selectProductLoading);

  if (!productLoading) return null;
  return (
    <div className="progress-wrap">
      <CircularProgress />
    </div>
  )
}

const Product = ({v, limit}) => {
  const [checked, setChecked] = React.useState(selected.findIndex(e => e.id === v.id) !== -1);

  const updateSelectionInfo = () => {
    document.getElementById("selection-info").innerText = `${selected.length}${limit ? `/${limit}` : ''} products selected`;
  }

  const handleProductChange = v => {
    const currentIndex = selected.findIndex(e => e.id === v.id);

    if (currentIndex === -1) {
      selected.push(v);
      if (selected.length === limit) document.getElementById('product-list').classList.add('limited');
    } else {
      if (selected.length === limit) document.getElementById('product-list').classList.remove('limited');
      selected.splice(currentIndex, 1);
    }

    setChecked(!checked);
    updateSelectionInfo();
  };

  return (
    <ListItem
      v={v}
      checked={selected.findIndex(e => e.id === v.id) !== -1}
      handleProductChange={handleProductChange}
    />
  );
}

export default function ProductDialog(props) {
  const {
    limit,
    keyword,
    products,
    enableAll,
    search,
    searching,
    loadNext,
    closeDialog,
    addProducts,
    selectedProducts
  } = props;

  let loading = 0;
  if (firstTimeAccessed) {
    if (selectedProducts) selected = _.uniqBy(selected.concat(_.cloneDeep(selectedProducts)), 'id');
    firstTimeAccessed = 0; 
  }

  if (!products) return null;

  const handleAdd = () => {
    // Remove all-products selection if other products are selected
    const allProductsIndex = selected.findIndex(e => e.id === 1);
    if (allProductsIndex > -1 && selected.length > 1) selected.splice(allProductsIndex, 1);

    addProducts(selected);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    selected = [];
    firstTimeAccessed = 1;
    closeDialog();
  };

  const handleScroll = async event => {
    if (loading) return;
    const target = event.target;

    // Reached bottom and load next products
    if ((target.scrollHeight - target.scrollTop) === target.clientHeight) {
      loading = 1;
      await loadNext(currentQuery);
      loading = 0;
    }
  }

  const renderCheckboxes = () => {
    return (
      <List
        className={selected.length === limit ? "product-list limited" : "product-list"}
        id="product-list"
        component="div"
      >
        {enableAll && <Product key={1} v={allProducts} limit={limit} />}
        {products.map((v, k) => <Product key={k} v={v} limit={limit} />)}
      </List>
    );
  }

  const searchProducts = query => {
    search(query);
    document.getElementById('product-list').scrollIntoView();
  }

  return (
    <Dialog
      open={true}
      className="product-picker-dialog form-dialog"
      disableBackdropClick
      disableEscapeKeyDown
      onClose={handleCloseDialog}
      aria-labelledby="Select Products"
    >
      <DialogTitle>
        Select Products
        <IconButton aria-label="close" className="close-icon" onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogActions className="product-search-wrap">
        <SearchInput
          search={searchProducts}
          searching={searching}
          defaultValue={keyword}
        />
      </DialogActions>
      <DialogContent
        id="product-list-wrap"
        onScroll={handleScroll}
        className="inset-3"
      >
        {renderCheckboxes()}
        <Loader />
      </DialogContent>
      <DialogActions>
        <div id="selection-info">
          {selected.length}{limit ? `/${limit}` : ''} products selected
        </div>
        <Button className="white-btn" onClick={handleCloseDialog}>Cancel</Button>
        <LoadingButton loading={false} onClick={handleAdd}>
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}