import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Link as ReactLink } from 'react-router-dom';
import { Alert, LoadingButton } from '../../../components/common';
import { FormBootstrapInput } from '../../../components/form';
import AuthActions, { AuthSelectors } from '../../../redux/auth';
import validationSchema from './schema';
import { splitName, params } from '../../../libs/utils';
import { setItem, getItem } from '../../../libs/storage-wrapper';

const INITIAL_VALUES = {
  name: '',
  email: '',
  password: '',
};

function SignUpForm() {
  const dispatch = useDispatch();
  const stripeSession = useSelector(AuthSelectors.selectStripeSession);
  const sessionId = getItem('SESSION_ID');

  useEffect(() => {
    const loadStripeSession = async () => {
      dispatch(AuthActions.loadStripeSession(sessionId));
    };

    params('pricing') && loadStripeSession();
  }, [dispatch, sessionId]);

  const handleSubmit = (values, { setSubmitting }) => {
    const { firstName, lastName } = splitName(values.name);
    const p = {
      firstName,
      lastName,
      email: values.email,
      password: values.password,
      role: 'owner',
      stripeSessionId: stripeSession ? stripeSession.sessionId : ''
    }

    setItem('AUTH_EMAIL', values.email);
    dispatch(AuthActions.signup(p, () => setSubmitting(false)));
  };

  const initialValues = stripeSession ? { ...INITIAL_VALUES, email: stripeSession.customerEmail } : INITIAL_VALUES;

  return (
    <div className="login-form">
      <div className="logo-wrap">
        <img className="logo" alt="Logo" src="/logo121.png" />
      </div>
      <div className="signup-header">
        <h2>Register your SyncTap account</h2>
      </div>
      <Alert />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-control auth">
              <label className="form-label" htmlFor="name">Your Name</label>
              <Field component={FormBootstrapInput} name="name" autoComplete="off" />
            </div>
            <div className="form-control auth">
              <label className="form-label" htmlFor="email">Email Address</label>
              <Field component={FormBootstrapInput} name="email" autoComplete="off" />
            </div>
            <div className="form-control auth">
              <label className="form-label" htmlFor="email">Password</label>
              <Field component={FormBootstrapInput} name="password" type="password" />              
            </div>
            <div className="form-control form-submit auth">
              <LoadingButton loading={isSubmitting} size="large" type="submit" fullWidth={true}>Register Account</LoadingButton>
            </div>
          </Form>
        )}
      </Formik>

      <div className="login-footer">
        <p>Already have an account? <ReactLink to="/login">Sign in here</ReactLink></p>
      </div>
    </div>
  );
}

export default SignUpForm;
