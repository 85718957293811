import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MainLayout from '../../containers/layout/MainLayout';
import AudienceActions, { AudienceSelectors } from '../../redux/audience';
import AdAccountActions, { AdAccountSelectors } from '../../redux/ad_account';
import {
  Box,
  Checkbox,
  Link,
  FormControlLabel,
  FormHelperText,
  Radio,
} from '@material-ui/core';
import { Formik, Field, Form, FieldArray } from 'formik';
import { FormBootstrapInput } from '../../components/form';
import { CreateCbaValidationSchema } from './schema';
import Criteria from './components/Criteria';
// import { Platforms } from '../../libs/const';
import { getItem } from '../../libs/storage-wrapper';
import {
  Operator
} from './components';
import { LoadingContainer, LoadingButton, Alert } from '../../components/common';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ClearIcon from '@material-ui/icons/Clear';

const INITIAL_VALUES = {
  name: '',
  platforms: [],
  operator: 'any',
  criterias: [{ field: '', op: '', value: '', value1: '' }],
  template: 0,
};

export default function CreateCba({ match }) {
  const store = getItem('STORE');
  const { id } = match.params;
  const dispatch = useDispatch();
  const row = useSelector(AudienceSelectors.selectAudience);
  const adAccounts = useSelector(AdAccountSelectors.selectAdAccounts);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  
  useEffect(() => {
    const loadAdAccounts = () => {
      dispatch(AdAccountActions.loadAdAccounts());
    };

    loadAdAccounts();
  }, [dispatch]);

  useEffect(() => {
    const loadAudience = () => {
      dispatch(AudienceActions.loadAudience(id));
    };

    loadAudience();
  }, [dispatch, id]);

  const availablePlatforms = [];
  if (adAccounts) {
    adAccounts.forEach(ac => {
      if (availablePlatforms.indexOf(ac.partner) === -1) {
        availablePlatforms.push(ac.partner);
      }
    });
  }

  const rowInitialValues = row ? row : INITIAL_VALUES;

  const handleMorePeopleClick = arrayHelpers => () => {
    arrayHelpers.push({ field: '', op: '', value: '' });
  }
  const handleFurtherNarrowClick = (arrayHelpers) => () => {
    arrayHelpers.push({ field: '', op: '', value: '' });
  }
  const handleRemoveClick = (arrayHelpers, index) => event => {
    arrayHelpers.remove(index);
  };

  const handleSubmit = async (params, { setSubmitting }) => {
    let resp = null;
    if (row) {
      resp = await dispatch(AudienceActions.update(id, params));
    } else {
      resp = await dispatch(AudienceActions.create(params));
      // history.push(`/my-audiences`);
    }
    if (resp.ok) setSubmitSuccess(true);
    setSubmitting(false);
    setOpenSnackbar(true);
  };

  let timezone =''
  if (store && store.details && store.details.timezone) {
    timezone = store.details.timezone
  }
  const renderForm = (props) => {
    const { values, setFieldValue, errors, touched, isSubmitting } = props;

    return (
      <LoadingContainer>
        <Form className="audience-form">
          <>
            <div className="form-control">
              <Field component={FormBootstrapInput} name="name" autoComplete="off" placeholder="Name Audience" />
            </div>

            <div className="form-control">
              <FieldArray
                name="platforms"
                render={arrayHelpers => (
                  <>
                    {availablePlatforms.map((p, key) => (
                      <FormControlLabel
                        key={key}
                        className="ad-platform-select"
                        label={p}
                        control={
                          <Radio
                            checked={values.platforms.includes(p)}
                            onChange={e => {
                              values.platforms.map((p, idx) => arrayHelpers.remove(idx));
                              arrayHelpers.push(p);
                            }}
                            value={p}
                            color="primary"
                          />
                        }
                      />
                    ))}
                  </>
                )}
              />
              {errors.platforms && touched.platforms && <FormHelperText error>{errors.platforms}</FormHelperText>}
            </div>

            <Operator value={values.operator} setFieldValue={setFieldValue} />
            
            <FieldArray
              name="criterias"
              render={arrayHelpers => (
                <div className="form-control criteria-list">
                  {values.criterias.map((row, index) => (
                    <div className="criteria-wrap" key={index}>
                      {index > 0 && (
                        <div className="criteria-actions">
                          <div className="criteria-operator">
                            {values.operator === 'any' && (<><AddCircleIcon />OR</>)}
                            {values.operator === 'all' && (<><RemoveCircleIcon />AND</>)}
                          </div>
                          <Link onClick={handleRemoveClick(arrayHelpers, index)} className="remove remove-criteria">
                            <ClearIcon />
                          </Link>
                        </div>
                      )}

                      <div className="criteria-row">
                        <Criteria
                          row={row}
                          index={index}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          width="300px"
                        />
                        {row.field === 'became-customer' &&
                          <p className="desc-field">The timezone will be shopify's timzeone - {timezone}</p>
                        }
                      </div>
                    </div>
                  ))}

                  <div className="add-criteria-row">
                    {values.operator === 'any' ? (
                      <Link onClick={handleMorePeopleClick(arrayHelpers)}>
                        <AddCircleIcon /> Include More People
                      </Link>
                    ) : (
                      <Link onClick={handleFurtherNarrowClick(arrayHelpers)}>
                        <RemoveCircleIcon /> Further Narrow
                      </Link>
                    )}
                  </div>
                </div>
              )}
            />

            <div className="form-control">
              <FormControlLabel
                label='Save As Template'
                control={
                  <Checkbox
                    name="template"
                    onChange={() => setFieldValue('template', 1 - values.template)}
                    checked={values.template === 1}
                    value={values.template}
                    color="primary"
                  />
                }
              />
            </div>

            <div className="action-row">
              {row ? <LoadingButton loading={isSubmitting} type="submit">Update</LoadingButton> :
                submitSuccess ? <LoadingButton disabled type="submit">Done!</LoadingButton> :
                <LoadingButton loading={isSubmitting} type="submit">Create</LoadingButton>}
            </div>
          </>
        </Form>
      </LoadingContainer>
    );
  }

  return (
    <MainLayout className="create-cba-page" title="Create Customer Based Audience">
      <Alert
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <Box className="page-header">
        <h3>Create Customer Based Audience</h3>
        <p className="title-note">Create an audience based on your past customers</p>
      </Box>

      <Formik
        enableReinitialize
        initialValues={rowInitialValues}
        onSubmit={handleSubmit}
        validationSchema={CreateCbaValidationSchema}
      >
        {props => renderForm(props)}

      </Formik>
    </MainLayout>
  );
}