import React from 'react';
import { Field } from 'formik';
import { OperatorOptions } from '../../../libs/const';
import { Select } from '../../../components/common';

export default function Operator(props) {
  const { value, setFieldValue } = props;

  const handleChange = () => event => {
    setFieldValue(`operator`, event.target.value);
  };

  return (
    <div className="form-control operator-row">
      <p>Include people who meet</p>
      <Field
        component={Select}
        size="small"
        id={`operator`}
        name={`operator`}
        value={value || 'any'}
        onChange={handleChange()}
        options={OperatorOptions}
        width="110px"
      />
      <p>of the following criteria:</p>
    </div>
  )
}