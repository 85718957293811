import { authApi } from './api';

export default () => {

  const login = (email, password) => authApi.post('/auth/login', {email, password});
  const signup = (params) => authApi.post(`/auth/signup`, params);
  const activateAccount = (store, params) => authApi.post(`/auth/activate-account?shop=${store}`, params);
  const sendVerificationEmail = email => authApi.post(`/auth/send-verification-email`, {email});
  const resetPassword = email => authApi.post(`/auth/reset-password`, {email});
  const setPassword = (token, password) => authApi.post(`/auth/set-password`, {token, password});
  const getStripeSession = (sessionId) => authApi.get(`/stripe/get-session?sessionId=${sessionId}`);

  return {
    login,
    signup,
    activateAccount,
    sendVerificationEmail,
    resetPassword,
    setPassword,
    getStripeSession
  };
};
