import { createReducer, createActions } from 'reduxsauce';
import apiCreate from '../../services/store';
import global from '../GlobalRedux'

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setCustomerTags: ['customerTags'],
  setCustomerTagsLoading: ['customerTagsLoading']
});

Creators.loadCustomerTags = () => {
  return async dispatch => {
    dispatch(Creators.setCustomerTagsLoading(true));

    const api = apiCreate();
    const resp = await api.getCustomerTags();
    
    if (resp.ok) {
      dispatch(Creators.setCustomerTags(resp.data));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(Creators.setCustomerTagsLoading(false));
  };
};

export const ProfileTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const CustomerTagSelectors = {
  selectCustomerTags: state => state.customerTag.customerTags,
  selectCustomerTagsLoading: state => state.customerTag.customerTagsLoading,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  customerTags: [],
  customerTagsLoading: false
};

/* ------------------- Reducers --------------------- */
export const setCustomerTags = (state, { customerTags }) => ({
  ...state,
  customerTags
});

export const setCustomerTagsLoading = (state, { customerTagsLoading }) => ({
  ...state,
  customerTagsLoading
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CUSTOMER_TAGS]: setCustomerTags,
  [Types.SET_CUSTOMER_TAGS_LOADING]: setCustomerTagsLoading
});
