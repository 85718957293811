import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton
} from '@material-ui/core';
import MainLayout from '../../../containers/layout/MainLayout';
import { ConfirmDialog, LoadingContainer, Alert } from '../../../components/common';
import { GlobalSelectors } from '../../../redux/GlobalRedux';
import ClientActions, { ClientSelectors } from '../../../redux/admin/client';
import { DialogInitialOpenState } from '../../../libs/const';
import vars from '../../../libs/vars';
import { formatDate, addHttps } from '../../../libs/utils';
import AddDialog from './AddDialog';

export default function Clients() {
  const dispatch = useDispatch();
  const refresh = useSelector(GlobalSelectors.selectDataRefresh);
  const rows = useSelector(ClientSelectors.selectClients);

  useEffect(() => {
    const loadClients = () => {
      dispatch(ClientActions.loadClients());
    };

    loadClients();
  }, [dispatch, refresh]);

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(DialogInitialOpenState);
  const handleOpenDialog = (name, row = null) => event => {
    setOpenDialog({ ...openDialog, [name]: true });
    if (row) setSelectedRow(row);
  };
  const handleCloseDialog = name => () => {
    setOpenDialog({ ...openDialog, [name]: false });
  };

  const handleAddClient = () => event => {
    setOpenDialog({ ...openDialog, ...{'add': true} });
  }

  const deleteClient = async () => {
    await dispatch(ClientActions.delete(selectedRow.id));
    handleCloseDialog('delete')();
  }

  const renderTableRow = (row, i) => (
    <TableRow component="div" key={i}>
      <TableCell component="div" scope="row" align="center">{i + 1}</TableCell>
      <TableCell component="div" align="left">{row.email}</TableCell>
      <TableCell component="div" align="left">
        <a href={addHttps(row.shop)} target="_blank" rel="noopener noreferrer">
          {row.shop}
        </a>
      </TableCell>
      <TableCell component="div" align="left">
        <a href={`${vars.REACT_APP_UI_URL}/install?shop=${row.shop}&direct=1`} target="_blank" rel="noopener noreferrer">
          {`${vars.REACT_APP_UI_URL}/install?shop=${row.shop}&direct=1`}
        </a>
      </TableCell>
      <TableCell component="div" align="left">{row.status}</TableCell>
      <TableCell component="div" align="left">{formatDate(row.createdAt)}</TableCell>
      <TableCell component="div" align="center" className="row-actions">
        <Box className="action-menu">
          <IconButton
            aria-label="Edit"
            onClick={handleOpenDialog('edit', row)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="Delete"
            onClick={handleOpenDialog('delete', row)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  )

  const renderTable = () => (
    <TableContainer component={Paper} elevation={0}>
      <Table className="table no-spacing filled-th" component="div">
        <TableHead component="div">
          <TableRow component="div">
            <TableCell component="div" align="center" width="140px">ID</TableCell>
            <TableCell component="div" align="left">Email</TableCell>
            <TableCell component="div" align="left">Shop</TableCell>
            <TableCell component="div" align="left">Setup Link</TableCell>
            <TableCell component="div" align="left">Status</TableCell>
            <TableCell component="div" align="left" style={{minWidth: '100px'}}>Created At</TableCell>
            <TableCell component="div">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody component="div">
          {rows.map((row, i) => renderTableRow(row, i))}
        </TableBody>
      </Table>
      {!rows.length && <div className="no-rows">No Data Found</div>}
    </TableContainer>
  )

  return (
    <MainLayout className="clients-page" title="Clients">
      <Alert />
      <LoadingContainer />
      <Box className="page-header">
        <h3>Clients</h3>
        <Button
          size="small"
          className="add-row"
          onClick={handleAddClient()}
        >Add</Button>
      </Box>

      { renderTable() }

      {openDialog.add && (
        <AddDialog
          handleCloseDialog={handleCloseDialog('add')}
        />
      )}
      {openDialog.edit && (
        <AddDialog
          row={selectedRow}
          handleCloseDialog={handleCloseDialog('edit')}
        />
      )}
      {openDialog.delete && (
        <ConfirmDialog
          handleCloseDialog={handleCloseDialog('delete')}
          handleConfirmDialog={deleteClient}
          confirmButton="Delete"
          confirmColor="secondary"
          title="Delete Client"
          content="Are you sure you want to permanently delete this client?"
        />
      )}
    </MainLayout>
  )
}