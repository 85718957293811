import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MainLayout from '../../containers/layout/MainLayout';
import { ProfileSelectors } from '../../redux/profile';
import AdAccountActions, { AdAccountSelectors } from '../../redux/ad_account';
import {
  Box,
  Stepper,
  Step,
  StepContent,
  StepButton
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { LoadingContainer, Alert } from '../../components/common';
import ConnectShopify from './components/ConnectShopify';
import ConnectFacebook from './components/ConnectFacebook';
import ConnectGoogle from './components/ConnectGoogle';
import ConnectFacebookAdAccount from './components/ConnectFacebookAdAccount';
import ConnectGoogleAdAccount from './components/ConnectGoogleAdAccount';
import TermsAndConditions from './components/TermsAndConditions';

const getInitialCompleted = user => {
  const num = user.store ? Math.min(user.onboarding, 4) : 0
  let completed = [];
  for (let i = 0; i < num; i++ ) {
    completed.push(i);
  }
  return completed;
}

export default function Setup() {
  const dispatch = useDispatch();
  const user = useSelector(ProfileSelectors.selectProfile);
  const adAccounts = useSelector(AdAccountSelectors.selectAdAccountsByPlatform);
  const facebookAccount = adAccounts ? adAccounts.facebook : null;
  const googleAccount = adAccounts ? adAccounts.google : null;

  const [store, setStore] = React.useState(user.store);
  const [completed, setCompleted] = React.useState(new Set(getInitialCompleted(user)));
  const [activeStep, setActiveStep] = React.useState(user.store ? Math.min(3, user.onboarding) : 0);
  const stepsCount = 9;

  useEffect(() => {
    const loadAdAccounts = () => {
      store && dispatch(AdAccountActions.loadAdAccountsByPlatform());
    };

    loadAdAccounts();
  }, [dispatch, store]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = step => () => {
    setActiveStep(step);
  };

  const handleComplete = (moving = true, __adAccounts = null) => {
    const newCompleted = new Set(completed);

    if (moving) {
      newCompleted.add(activeStep);
    } else {
      for (let i = activeStep; i < 5; i++)
        newCompleted.delete(i);
    };


    if (__adAccounts) {
      const __facebookAccount = __adAccounts.filter(v => v.partner === 'Facebook');
      const __googleAccount = __adAccounts.filter(v => v.partner === 'Google');

      if (__facebookAccount.length > 0) {
        newCompleted.add(1);
        if (__facebookAccount[0].adAccountId) newCompleted.add(2);
      } else if (__googleAccount.length > 0) {
        newCompleted.add(1);
        if (__googleAccount[0].adAccountId) newCompleted.add(2);
      }
    }
    
    setCompleted(newCompleted);

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (moving && completed.size !== stepsCount) {
      handleNext();
    }
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  const finishSetup = async () => {
    const account = facebookAccount || googleAccount;
    await dispatch(AdAccountActions.finishSetup(account.id));
    window.location.href = '/templates';
  }

  const renderSetup = () => {
    return (
      <div className="setup-panel">
        <Stepper activeStep={activeStep} orientation="vertical">
          
          <Step>
            <StepButton
              onClick={handleStep(0)}
              completed={isStepComplete(0)}
            >
              <label>Connect Store</label>
              {activeStep === 0 ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </StepButton>
            <StepContent>
              <ConnectShopify
                store={store}
                setStore={setStore}
                handleComplete={handleComplete}
              />
            </StepContent>
          </Step>

          <Step>
            <StepButton
              onClick={handleStep(1)}
              completed={isStepComplete(1)}
              disabled={completed.size < 1}
            >
              <label>Ad Account</label>
              {activeStep === 1 ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </StepButton>
            <StepContent>
              <ConnectFacebook
                handleComplete={handleComplete}
                facebookAccount={facebookAccount}
              />
              <div className="or-separator">OR</div>
              <ConnectGoogle
                handleComplete={handleComplete}
                googleAccount={googleAccount}
              />
            </StepContent>
          </Step>

          <Step>
            <StepButton
              onClick={handleStep(2)}
              completed={isStepComplete(2)}
              disabled={completed.size < 2}
            >
              <label>Ad Account</label>
              {activeStep === 2 ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </StepButton>
            <StepContent>
              {facebookAccount && <ConnectFacebookAdAccount
                handleComplete={handleComplete}
                facebookAccount={facebookAccount}
              />}
              {googleAccount && <ConnectGoogleAdAccount
                handleComplete={handleComplete}
                googleAccount={googleAccount}
              />}
            </StepContent>
          </Step>

          <Step>
            <StepButton
              onClick={handleStep(3)}
              completed={isStepComplete(3)}
              disabled={completed.size < 3}
            >
              <label>Terms and Conditions</label>
              {activeStep === 3 ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </StepButton>
            <StepContent>
              {(facebookAccount || googleAccount) && <TermsAndConditions
                handleComplete={handleComplete}
                finishSetup={finishSetup}
                facebookAccount={facebookAccount}
                googleAccount={googleAccount}
              />}
            </StepContent>
          </Step>
        </Stepper>
      </div>
    )
  }

  return (
    <MainLayout
      pclass="align-center bg-gray"
      className="box setup-page"
      title="Welcome to SyncTap!"
    >
      <LoadingContainer />
      <Alert />
      <Box className="page-header">
        <h1>Welcome to SyncTap!</h1>
      </Box>

      {renderSetup()}
    </MainLayout>
  );
}
