import React, { useEffect } from 'react';
import { Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { BootstrapInput } from '../../../components/common';
import CustomerTagActions, { CustomerTagSelectors } from '../../../redux/customer-tag';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Chip,
} from '@material-ui/core';

function CustomerTagPicker(props) {
  const {
    selectedTags,
    setSelectedTags
  } = props;

  const dispatch = useDispatch();
  
  useEffect(() => {
    const loadCustomerTags = () => {
      dispatch(CustomerTagActions.loadCustomerTags());
    };
    
    loadCustomerTags();
  }, [dispatch]);

  useEffect(() => {
    if (!Array.isArray(selectedTags)) {
      setSelectedTags([]);
    }
  }, [selectedTags, setSelectedTags]);
  
  const allTags = useSelector(CustomerTagSelectors.selectCustomerTags);

  return (
    <div
      className="autocomplete"
      style={{ width: '100%' }}
    >
      <Field
        multiple
        fullWidth
        disabled={!allTags || allTags.length === 0}
        component={Autocomplete}
        name='customer-tags'
        id="customer-tags-select"
        value={selectedTags || []}
        options={allTags}
        getOptionSelected={(option, value) => option === value}
        getOptionLabel={(option) => option}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => <Chip
            label={`${option}`}
            {...getTagProps({ index })}
          />)
        }
        renderInput={(params) => (
          <BootstrapInput
            {...params}
            placeholder="Tags"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ padding: 0}}
          />
        )}
        onChange={ (__, value) => {
          setSelectedTags(value);
        }}
      />
    </div>
  );
}

export default CustomerTagPicker;
