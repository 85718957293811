import React from 'react';
import { FieldArray } from 'formik';
import {
  Button,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';
import { Events } from '../../../libs/const';

export default function EventSelect(props) {
  const { productSelected, events, errors, touched } = props;

  const handleCheckboxClick = (arrayHelpers, p) => (e) => {
    if (e.target.checked) arrayHelpers.push(p);
    else {
      const idx = events.indexOf(p);
      arrayHelpers.remove(idx);
    }
  }
  
  const handleButtonClick = (arrayHelpers, p) => (e) => {
    const idx = events.indexOf(p);
    if (idx < 0) arrayHelpers.push(p);
    else arrayHelpers.remove(idx);
  }

  return (
    <div className="form-control">
      <label>Select events</label>
      <FieldArray
        name="events"
        render={arrayHelpers => (
          <>
            {Events.map((p, key) => (
              <div className="event-select" key={key}>
                <Checkbox
                  disabled={productSelected && p === 'Page View'}
                  checked={events.includes(p)}
                  onChange={handleCheckboxClick(arrayHelpers, p)}
                  value={p}
                />
                <Button disabled={productSelected && p === 'Page View'} onClick={handleButtonClick(arrayHelpers, p)}>{p}</Button>
              </div>
            ))}
          </>
        )}
      />
      {errors.events && touched.events && <FormHelperText error>{errors.events}</FormHelperText>}
    </div>
  )
}

