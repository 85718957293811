import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BootstrapInput } from '../../../components/common';
import ProductActions, { ProductSelectors } from '../../../redux/product';
import SearchIcon from '@material-ui/icons/Search';
import {
  Box,
  Button,
  IconButton
} from '@material-ui/core';
import ProductDialog from './ProductDialog';
import CloseIcon from '@material-ui/icons/Close';
import '../../../styles/product-select.scss';

const productLimit = 20;

const isCharacterKeyPress = (keycode) =>
  (keycode > 47 && keycode < 58)   || // number keys
  (keycode > 64 && keycode < 91)   || // letter keys
  (keycode > 95 && keycode < 112)  || // numpad keys
  (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
  (keycode > 218 && keycode < 223);   // [\]' (in order)

function ProductPicker(props) {
  const {
    size = "large",
    enableAll = false,
    hideButton = false,
    hideInput = false,
    browseButtonText = "Browse",
    limit,
    selectedProducts,
    setSelectedProducts
  } = props;

  const dispatch = useDispatch();
  
  useEffect(() => {
    const loadProducts = () => {
      dispatch(ProductActions.loadProducts([], productLimit, null));
    };
    
    loadProducts();
  }, [dispatch]);
  
  const [openDialog, setOpenDialog] = React.useState(false);
  const [keyword, setKeyword] = React.useState('');
  
  const rows = useSelector(ProductSelectors.selectProducts);
  const pageInfo = rows && rows.params && rows.params.page_info;
  const [searching, setSearching] = React.useState(false);
  const products = rows.products;

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const search = async (query) => {
    setSearching(true);
    if (query) {
      await dispatch(ProductActions.searchProducts([], query));
    } else {
      await dispatch(ProductActions.loadProducts([], productLimit));
    }
    setSearching(false);
    setKeyword(query);
  }

  const loadNext = async (query = null) => {
    if (!pageInfo) return;
    if (query) {
      await dispatch(ProductActions.searchProducts(products, query, pageInfo));
    } else {
      await dispatch(ProductActions.loadProducts(products, productLimit, pageInfo));
    }
  }

  const addProducts = products => {
    setSelectedProducts(products);
  };

  const handleProductDelete = (id) => () => {
    const newSelectedProducts = selectedProducts.filter(v => v.id !== id);
    setSelectedProducts(newSelectedProducts);
  }

  const handleKeyDown = () => (e) => {
    if (isCharacterKeyPress(e.keyCode)) {
      handleOpenDialog();
      setKeyword(e.key);
    }
    e.preventDefault();
  }

  const handleBrowse = () => {
    handleOpenDialog();
  }

  return (
    <div className={`product-select ${size}`}>
      <Box className="browse-product">
        {!hideInput && <BootstrapInput
          placeholder="Search products"
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          onChange={() => setOpenDialog(true)}
          onKeyDown={handleKeyDown()}
        />}
        {!hideButton && <Button onClick={handleBrowse}>{browseButtonText}</Button>}
      </Box>
      <Box className="selected-products product-list">
        {selectedProducts && selectedProducts.map(v => {
          const src = v.image ? v.image.src.replace(/.([^.]*)$/, "_x40.$1") : "/images/no-image.svg";
          return (
            <div className="product-item" key={v.id}>
              <div className={!v.image ? 'image-wrap no-image' : 'image-wrap image'}>
                <img src={src} alt="Product" />
              </div>
              <div className="title">{v.title}</div>
              <IconButton aria-label="close" className="close-icon" onClick={handleProductDelete(v.id)}>
                <CloseIcon />
              </IconButton>
            </div>
          )
        })}
      </Box>
      
      {openDialog && (
        <ProductDialog
          enableAll={enableAll}
          limit={limit}
          keyword={keyword}
          products={products}
          loadNext={loadNext}
          search={search}
          searching={searching}
          closeDialog={handleCloseDialog}
          addProducts={addProducts}
          selectedProducts={selectedProducts}
        />
      )}
    </div>
  );
}

export default ProductPicker;
