import api from './api';

export default () => {
  const $ = api;
  const getProfile = () => $.get('/user/profile');
  const updateProfile = params => $.post('/user/profile', params);
  const changePassword = (currentPassword, newPassword) => $.post('/user/change-password', { currentPassword, newPassword});
  
  return {
    getProfile,
    updateProfile,
    changePassword
  };
};
