import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Link as ReactLink } from 'react-router-dom';
import { Alert, LoadingButton } from '../../../components/common';
import { FormBootstrapInput } from '../../../components/form';
import AuthActions from '../../../redux/auth';
import validationSchema from './schema';
import { setItem } from '../../../libs/storage-wrapper';

const INITIAL_VALUES = {
  email: ''
};

function ResendEmailForm() {
  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting }) => {
    setItem('AUTH_EMAIL', values.email);
    dispatch(AuthActions.sendVerificationEmail(values.email, () => setSubmitting(false)));
  };

  return (
    <div className="login-form">
      <h2>Send verification email</h2>
      <p>Enter the email address associated with your account and we'll send you a link to verify your email.</p>

      <Alert />
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-control auth">
              <label className="form-label" htmlFor="email">Email Address</label>
              <Field component={FormBootstrapInput} name="email" autoComplete="off" />
            </div>
            <div className="form-control form-submit auth">
              <LoadingButton loading={isSubmitting} size="large" type="submit" fullWidth={true}>Send Verification Email</LoadingButton>
            </div>
          </Form>
        )}
      </Formik>

      <div className="login-footer">
        <p>Already have an account? <ReactLink to="/login">Sign in here</ReactLink></p>
      </div>
    </div>
  );
}

export default ResendEmailForm;
