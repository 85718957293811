import { createReducer, createActions } from 'reduxsauce';
import apiCreate from '../../services/lookalike';
import global from '../GlobalRedux';

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setLookalike: ['lookalike'],
  setLookalikes: ['lookalikes'],
});

Creators.loadLookalike = (id) => {
  return async dispatch => {
    if (id) {
      dispatch(global.setSubLoading(true));

      const api = apiCreate();
      const resp = await api.getLookalike(id);
      if (resp.ok) {
        dispatch(Creators.setLookalike(resp.data));
      } else {
        dispatch(global.apiError(resp));
      }
      
      dispatch(global.setSubLoading(false));
    } else {
      dispatch(Creators.setLookalike(null));
    }
  };
};

Creators.loadLookalikes = () => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.getLookalikes();
    if (resp.ok) {
      dispatch(Creators.setLookalikes(resp.data));
    } else {
      dispatch(global.apiError(resp));
    }
    
    dispatch(global.setSubLoading(false));
  };
};

Creators.delete = id => {
  return async dispatch => {
    dispatch(global.setButtonLoading(true));
    
    const api = apiCreate();
    const resp = await api.deleteLookalike(id);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your lookalike audience has been deleted!",
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setButtonLoading(false));
    dispatch(global.setDataRefresh(Math.random()));
  };
};

export const LookalikeTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const LookalikeSelectors = {
  selectLookalike: state => state.lookalike.lookalike,
  selectLookalikes: state => state.lookalike.lookalikes
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  lookalike: null,
  lookalikes: []
};

/* ------------------- Reducers --------------------- */
export const setLookalike = (state, { lookalike }) => ({
  ...state,
  lookalike
});

export const setLookalikes = (state, { lookalikes }) => ({
  ...state,
  lookalikes
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOOKALIKE]: setLookalike,
  [Types.SET_LOOKALIKES]: setLookalikes
});
