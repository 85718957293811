import React from 'react';
import MainLayout from '../../containers/layout/MainLayout';
import {
  Box,
} from '@material-ui/core';
import { LoadingContainer, Alert } from '../../components/common';
import PersonalInformationForm from '../../containers/profile/PersonalInformationForm';
import ChangePasswordForm from '../../containers/profile/ChangePasswordForm';

function Profile() {

  return (
    <MainLayout className="my-profile-page" title="My Profile">
      <LoadingContainer />
      <Alert />
      <Box className="page-header">
        <h3>My Profile</h3>
      </Box>

      <PersonalInformationForm />
      <ChangePasswordForm />
    </MainLayout>
  );
}

export default Profile;
