import { createReducer, createActions } from 'reduxsauce';
import apiCreate from '../../services/audience';
import global from '../GlobalRedux';

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setAudience: ['audience'],
  setAudiences: ['audiences'],
  setCustomAudiences: ['customAudiences'],
});

Creators.loadAudience = (id) => {
  return async dispatch => {
    if (id) {
      dispatch(global.setSubLoading(true));

      const api = apiCreate();
      const resp = await api.getAudience(id);
      if (resp.ok) {
        dispatch(Creators.setAudience(resp.data));
      } else {
        dispatch(global.apiError(resp));
      }
      
      dispatch(global.setSubLoading(false));
    } else {
      dispatch(Creators.setAudience(null));
    }
  };
};

Creators.loadAudiences = () => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.getAudiences();
    if (resp.ok) {
      dispatch(Creators.setAudiences(resp.data));
    } else {
      dispatch(global.apiError(resp));
    }
    
    dispatch(global.setSubLoading(false));
  };
};

Creators.loadCustomAudiences = (platform) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.getCustomAudiences(platform);
    if (resp.ok) {
      dispatch(Creators.setCustomAudiences(resp.data));
    } else {
      dispatch(global.apiError(resp));
      dispatch(Creators.setCustomAudiences([]));
    }

    dispatch(global.setSubLoading(false));
  };
};

Creators.create = (params) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.create(params);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your audience has been created.",
        link: `/my-audiences`,
        linkText: 'View Audiences',
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }
    dispatch(global.setSubLoading(false));
    return resp;
  };
};

Creators.createLookalike = params => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.createLookalike(params);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your lookalike audience has been created.",
        severity: "success"
      }));
    } else {
      dispatch(global.apiError(resp));
    }
    dispatch(global.setSubLoading(false));
    return resp;
  };
};

Creators.createWebsite = params => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.createWebsite(params);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your activity based audience has been created.",
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }
    dispatch(global.setSubLoading(false));
    return resp;
  };
};

Creators.update = (id, params) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.update(id, params);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your audience has been updated.",
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }
    dispatch(global.setSubLoading(false));
    return resp;
  };
};

Creators.refresh = id => {
  return async dispatch => {
    const api = apiCreate();
    const resp = await api.refresh(id);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your ad account has been resynced!",
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }
  };
};

Creators.delete = id => {
  return async dispatch => {
    dispatch(global.setButtonLoading(true));
    
    const api = apiCreate();
    const resp = await api.deleteAudience(id);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: "Your audience has been deleted!",
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setButtonLoading(false));
    dispatch(global.setDataRefresh(Math.random()));
  };
};

export const AudienceTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const AudienceSelectors = {
  selectAudience: state => state.audience.audience,
  selectAudiences: state => state.audience.audiences,
  selectCustomAudiences: state => state.audience.customAudiences,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  audience: null,
  audiences: [],
  customAudiences: null,
};

/* ------------------- Reducers --------------------- */
export const setAudience = (state, { audience }) => ({
  ...state,
  audience
});

export const setAudiences = (state, { audiences }) => ({
  ...state,
  audiences
});

export const setCustomAudiences = (state, { customAudiences }) => ({
  ...state,
  customAudiences
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_AUDIENCE]: setAudience,
  [Types.SET_AUDIENCES]: setAudiences,
  [Types.SET_CUSTOM_AUDIENCES]: setCustomAudiences,
});
