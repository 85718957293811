import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as ReactLink } from 'react-router-dom';
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AdAccountActions, { AdAccountSelectors } from '../../../redux/ad_account';

function LookalikeMenu() {
  const dispatch = useDispatch();
  const anchorMenuRef = React.useRef(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const adAccounts = useSelector(AdAccountSelectors.selectAdAccounts) || [];

  const handleMenuToggle = () => {
    setMenuOpen(prevOpen => !prevOpen);
  };
  const handleMenuClose = event => {
    if (anchorMenuRef.current && anchorMenuRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  useEffect(() => {
    const loadAdAccounts = () => {
      dispatch(AdAccountActions.loadAdAccounts());
    };

    loadAdAccounts();
  }, [dispatch]);
  const availablePartners = adAccounts.map(ad => ad.partner);

  if (availablePartners.length === 0) {
    return null;
  }

  return (
    <div className="dropdown-menu" open={menuOpen}>
      <a
        ref={anchorMenuRef}
        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleMenuToggle}
        className="dropdown-nav-menu"
      >
        Create Lookalike
        <ExpandMoreIcon />
      </a>
      <Popper
        open={menuOpen}
        anchorEl={anchorMenuRef.current}
        role={undefined}
        placement="bottom-end"
        className="action-menu"
        transition
        disablePortal
        style={{ width: '200px' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper className="box-shadow-2">
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList
                  autoFocusItem={menuOpen}
                  id="menu-list-grow"
                >
                  {availablePartners.includes('Facebook') &&
                    <MenuItem onClick={handleMenuClose}>
                      <ReactLink to={'/create-lookalike-facebook?platform=Facebook'}>Lookalike (Facebook)</ReactLink>
                    </MenuItem>
                  }
                  {availablePartners.includes('Google') &&
                    <MenuItem onClick={handleMenuClose}>
                      <ReactLink to={'/create-lookalike-google?platform=Google'}>Similar (Google)</ReactLink>
                    </MenuItem>
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default LookalikeMenu;