import React from 'react';
import {
  Button,
} from '@material-ui/core';
import { getGodUserToken, getGodUserId, setGodUserToken, setGodUserId, setUserToken, setUserId } from '../../../libs/storage';

function GodModeMenu() {
  const godToken = getGodUserToken();

  const logout = event => {
    
    const currentToken = getGodUserToken();
    const currentUserId = getGodUserId();
    setGodUserToken('');
    setGodUserId('');

    setUserToken(currentToken);
    setUserId(currentUserId);
    document.location.href = "/admin"
  }

  if (!godToken) {
    return null
  }

  return (
    <Button
      className="white-btn"
      onClick={logout}
    >
      Back to Admin
    </Button>
  )
}

export default GodModeMenu;