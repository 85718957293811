import React from 'react';
import AuthLayout from '../containers/layout/AuthLayout';

function Install() {

  return (
    <AuthLayout>
      <h2>Loading...</h2>
    </AuthLayout>
  );
}

export default Install;
