import { createReducer, createActions } from 'reduxsauce';
import apiCreate from '../../services/store';
import global from '../GlobalRedux'
import { sleep } from '../../libs/utils';

// Pick only src property from product image
function filterProperties(products) {
  if (!products) return products;

  return products.map(({id, title, handle, image}) => {
    const __image = image ? { src: image.src } : null;
    return {
      id,
      title,
      handle,
      image: __image
    }
  });
}

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setProducts: ['products'],
  setProductLoading: ['productLoading']
});

Creators.loadProducts = (products, limit = 250, page_info = null) => {
  return async dispatch => {
    dispatch(Creators.setProductLoading(true));

    const api = apiCreate();
    const t = new Date().getTime();
    const resp = await api.getProducts(limit, page_info);
    
    if (resp.ok) {
      // Prevent scrolling after next products are loaded if the response is pretty fast
      if ((new Date().getTime() - t) / 1000 < 1) {
        await sleep(500);
      }
      const filtered = filterProperties(resp.data.products);

      dispatch(Creators.setProducts({
        products: products.concat(filtered),
        params: resp.data.params
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(Creators.setProductLoading(false));
  };
};

Creators.searchProducts = (products, query, cursor = null) => {
  // Params: cursor = page_info
  return async dispatch => {
    dispatch(Creators.setProductLoading(true));

    const api = apiCreate();
    const t = new Date().getTime();
    const resp = await api.searchProducts(query, cursor);
    
    if (resp.ok) {
      // Prevent scrolling after next products are loaded if the response is pretty fast
      if ((new Date().getTime() - t) / 1000 < 1) {
        await sleep(500);
      }
      const filtered = filterProperties(resp.data.products);

      dispatch(Creators.setProducts({
        products: products.concat(filtered),
        params: { page_info: resp.data.cursor }
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(Creators.setProductLoading(false));
  };
};

export const ProfileTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const ProductSelectors = {
  selectProducts: state => state.product.products,
  selectProductLoading: state => state.product.productLoading,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  products: [],
  productLoading: false
};

/* ------------------- Reducers --------------------- */
export const setProducts = (state, { products }) => ({
  ...state,
  products
});

export const setProductLoading = (state, { productLoading }) => ({
  ...state,
  productLoading
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PRODUCTS]: setProducts,
  [Types.SET_PRODUCT_LOADING]: setProductLoading
});
