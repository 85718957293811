import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { GlobalSelectors } from '../../redux/GlobalRedux';
import {
  AppBar,
  Box,
} from '@material-ui/core';
import {
  LinearProgress,
} from '../../components/common';
import AccountMenu from './components/AccountMenu';
import GodModeMenu from './components/GodModeMenu';
import LookalikeMenu from './components/LookalikeMenu';
import { params } from '../../libs/utils';

function Header({user}) {
  const loading = useSelector(GlobalSelectors.selectLoading);

  // Google analytics for paid customers
  /* Not Using GA for now
  ReactGA.initialize('UA-173389050-1', {
    gaOptions: {
      userId: user.email
    }
  });
  ReactGA.set({ dimension1: getActiveDays(user) > 14 ? "yes" : "no" });
  ReactGA.pageview(window.location.pathname + window.location.search);*/

  if (params('blank') || !user) return null;
  
  const MenuBar = () => 
    <>
      <Box className="menu-bar">
        <div className="header-logo">
          <img src="/logo-color-300x.png" alt="logo" />
        </div>
        {user.onboarding === 9 && (
          <>
            <NavLink
              to={'/my-accounts'}
              isActive={(match, location) => ["/", "/my-accounts"].includes(location.pathname) ? true : false}
            >
              My Accounts
            </NavLink>
            <NavLink to={'/templates'}>
              Templates
            </NavLink>
            <NavLink to={'/create-cba'}>
              Create Customer Based Audience
            </NavLink>
            <NavLink to={'/create-aba'}>
              Create Activity Based Audience
            </NavLink>
            <LookalikeMenu />
            <NavLink to={'/my-audiences'}>
              My Saved Audiences
            </NavLink>
            <NavLink to={'/pricing'}>
              Pricing
            </NavLink>
            {/* {user.pricing === 'agency' && (
              <NavLink to={'/my-stores'}>
                My Stores
              </NavLink>
            )} */}
          </>
        )}
      </Box>
      <Box className="right-side">
        <GodModeMenu />
        <AccountMenu />
      </Box>
    </>

  return (
    <>
      {loading && <LinearProgress />}
      <AppBar position="static" elevation={0}>
        {user && <MenuBar />}
      </AppBar>
    </>
  );
}

export default withRouter(Header);
