import api from './api';
import { shop } from '../libs/storage';

export default () => {
  const $ = api;
  const getAdAccounts = () => $.get(`/app/ad-accounts?shop=${shop()}`);
  const getAdAccountsByPlatform = () => $.get(`/app/ad-accounts-by-platform?shop=${shop()}`);
  const getAdAccountsForConnection = (partner, accessToken) => $.get(`/app/ad-accounts-for-connection?shop=${shop()}&partner=${partner}&accessToken=${accessToken}`);
  const getPixels = () => $.get(`/app/ad-accounts/pixels?shop=${shop()}`);
  const create = params => $.post(`/app/ad-accounts?shop=${shop()}`, params);
  const refresh = id => $.post(`/app/ad-accounts/${id}/refresh?shop=${shop()}`);
  const deleteAdAccount = id => $.delete(`/app/ad-accounts/${id}?shop=${shop()}`);
  
  // Ad account setup APIs
  const connectAccount = params => $.post(`/app/ad-accounts/connect-account?shop=${shop()}`, params);
  const connectAdAccount = params => $.post(`/app/ad-accounts/connect-ad-account?shop=${shop()}`, params);
  const disconnectAccount = id => $.post(`/app/disconnect-account/${id}?shop=${shop()}`);
  const disconnectAdAccount = id => $.post(`/app/disconnect-ad-account/${id}?shop=${shop()}`);
  const acceptTermsAndConditions = id => $.post(`/app/ad-accounts/${id}/accept-tos?shop=${shop()}`);
  const finishSetup = id => $.post(`/app/ad-accounts/${id}/finish-setup?shop=${shop()}`);

  return {
    getAdAccounts,
    getAdAccountsByPlatform,
    getAdAccountsForConnection,
    getPixels,
    create,
    connectAccount,
    connectAdAccount,
    refresh,
    deleteAdAccount,
    disconnectAccount,
    disconnectAdAccount,
    acceptTermsAndConditions,
    finishSetup
  };
};
