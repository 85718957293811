import { createReducer, createActions } from 'reduxsauce';
import apiCreate from '../../services/store';
import global from '../GlobalRedux';
import ProfileActions from '../profile';
import { setStoreId } from '../../libs/storage';

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setStores: ['stores'],
  setAgencyStores: ['agencyStores'],
});

Creators.loadStores = () => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.getStores();
    if (resp.ok) {
      dispatch(Creators.setStores(resp.data));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setSubLoading(false));
  };
};

Creators.addUserToStore = (shop, storeId) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.addUser(shop, storeId);
    dispatch(global.setSubLoading(false));

    if (resp.ok) {
      setStoreId(shop);
    } else {
      dispatch(global.apiError(resp));
    }

    return resp.data;
  };
};

Creators.loadAgencyStores = () => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.getAgencyStores();
    if (resp.ok) {
      dispatch(Creators.setAgencyStores(resp.data));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setSubLoading(false));
  };
};

Creators.switchStore = (shop) => {
  return async dispatch => {
    dispatch(global.setSubLoading(true));

    const api = apiCreate();
    const resp = await api.switchStore(shop);
    if (resp.ok) {
      await dispatch(ProfileActions.loadProfile());
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setSubLoading(false));
  };
};

Creators.removeStore = id => {
  return async dispatch => {
    dispatch(global.setButtonLoading(true));

    const api = apiCreate();
    const resp = await api.removeStore(id);
    if (resp.ok) {
      dispatch(global.setAlert({
        message: `Your store has been removed.`,
        severity: "success",
      }));
    } else {
      dispatch(global.apiError(resp));
    }

    dispatch(global.setButtonLoading(false));
    dispatch(global.setDataRefresh(Math.random()));
  };
};

export const StoreTypes = Types;
export default Creators;

/* --------------------- Selectors ---------------- */
export const StoreSelectors = {
  selectStores: state => state.store.stores,
  selectAgencyStores: state => state.store.agencyStores,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  stores: null,
  agencyStores: null,
};

/* ------------------- Reducers --------------------- */
export const setStores = (state, { stores }) => ({
  ...state,
  stores
});

export const setAgencyStores = (state, { agencyStores }) => ({
  ...state,
  agencyStores
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_STORES]: setStores,
  [Types.SET_AGENCY_STORES]: setAgencyStores,
});
