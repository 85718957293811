import modal from './modal';
import { getUserToken } from './storage';

const install = shop => new Promise((resolve, reject) => {
  modal.open(
    `${process.env.REACT_APP_API_BASE}/oauth/shopify/install?shop=${shop}`,
    'install'
  ).then(res => resolve(res)).catch(err => reject(err));
});

const add = shop => new Promise((resolve, reject) => {
  modal.open(
    `${process.env.REACT_APP_API_BASE}/oauth/shopify/install?shop=${shop}&agency=1`,
    'install'
  ).then(res => resolve(res)).catch(err => reject(err));
});

const addExtra = () => new Promise((resolve, reject) => {
  let url = `${process.env.REACT_APP_UI_URL}/secure-checkout?blank=1&extra=1&token=${getUserToken()}`;
  modal.open(
    url,
    'upgrade'
  ).then(res => resolve(res)).catch(err => reject(err));
});

const upgrade = (shop, pricing) => new Promise((resolve, reject) => {
  let url;
  if (pricing === 'agency') {
    url = `${process.env.REACT_APP_UI_URL}/secure-checkout?blank=1&pricing=${pricing}&shop=${shop}&token=${getUserToken()}`;
  } else {
    url = `${process.env.REACT_APP_API_BASE}/user/upgrade?pricing=${pricing}&shop=${shop}&token=${getUserToken()}`;
  }
  modal.open(
    url,
    'upgrade'
  ).then(res => resolve(res)).catch(err => reject(err));
});

export default {
  add,
  addExtra,
  install,
  upgrade
}
