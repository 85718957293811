import React from 'react';
import { refineDays } from '../../../libs/utils';

export default function AbaPreview(props) {
  const { values } = props;
  const { products, events, days } = values;
  const _days = days && refineDays(days).split(',');

  if (!products.length || !events.length || !_days.length) return null;

  const size = products.length * events.length * _days.length;

  const genName = (title, e, d) => {
    const prefix = values.namePrefix ? `${values.namePrefix} - ` : ''
    const suffix = values.nameSuffix ? ` - ${values.nameSuffix}` : ''
    return `${prefix}SyncTap - ${title} - ${e} - ${d}d${suffix}`
  }

  return (
    <div className="ca-preview form-control">
      <label>Preview ({size})</label>
      <ul className="ca-preview-list">
        {products.map(p => {
          return events.map(e => {
            return _days.map(d => (<li key={`${p.id}${e}${d}`}>{genName(p.title, e, d)}</li>))
          })
        })}
      </ul>
    </div>
  )
}