import React from 'react';
import { useDispatch } from 'react-redux';
import AudienceActions from '../../redux/audience';
import MainLayout from '../../containers/layout/MainLayout';
import {
  Box,
  Grid,
  FormHelperText
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { CreateAbaValidationSchema } from './schema';
import { FormBootstrapInput } from '../../components/form';
import { PixelSelect, EventSelect, DaySelect, AbaPreview } from './components';
import { LoadingContainer, LoadingButton, Alert } from '../../components/common';
import { refineDays } from '../../libs/utils';
import ProductPicker from '../../containers/shopify/ProductPicker';

const INITIAL_VALUES = {
  platforms: ['Facebook'],
  products: [{id: 1, title: 'All Products', handle: 'all-products', image: null}],
  pixel: '',
  events: [],
  days: '',
  nameSuffix: '',
  namePrefix: '',
};

export default function CreateAba() {
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleSubmit = async (params, { setSubmitting, resetForm }) => {
    params.days = refineDays(params.days);
    const resp = await dispatch(AudienceActions.createWebsite(params));
    if (resp.ok) {
      resetForm({});
    }
    setSubmitting(false);
    setOpenSnackbar(true);
  };

  const renderForm = (props) => {
    const { values, setFieldValue, errors, touched, isSubmitting } = props;

    return (
      <Form className="audience-form">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="form-control">
              <label>Select products<span> - (all products are selected by default)</span></label>
              <ProductPicker
                enableAll={true}
                limit={null}
                selectedProducts={values.products}
                setSelectedProducts={(products) => setFieldValue("products", products)}
              />
              {errors.products && touched.products && <FormHelperText error>{errors.products}</FormHelperText>}
            </div>

            {/* Disable original product dropdown
              <ProductSelect products={values.products} setFieldValue={setFieldValue} errors={errors} touched={touched} />
            */}
          </Grid>

          <Grid item xs={12}>
            <PixelSelect pixel={values.pixel} setFieldValue={setFieldValue} errors={errors} touched={touched} />
          </Grid>

          <Grid item xs={12}>
            <EventSelect productSelected={values.products.length !== 0} events={values.events} setFieldValue={setFieldValue} errors={errors} touched={touched} />
          </Grid>

          <Grid item xs={12}>
            <DaySelect days={values.days} setFieldValue={setFieldValue} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-control">
                  <label>Audience Name Prefix</label>
                  <Field
                    component={FormBootstrapInput}
                    name="namePrefix"
                    autoComplete="off"
                    placeholder="Prefix"
                    value={values.namePrefix}
                    onChange={(e) => setFieldValue('namePrefix', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-control">
                  <label>Audience Name Suffix</label>
                  <Field
                    component={FormBootstrapInput}
                    name="nameSuffix"
                    autoComplete="off"
                    placeholder="Suffix"
                    value={values.nameSuffix}
                    onChange={(e) => setFieldValue('nameSuffix', e.target.value)}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <AbaPreview values={values} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} className="action-row">
          {<LoadingButton loading={isSubmitting} type="submit">Create</LoadingButton>}
          </Grid>
        </Grid>
      </Form>
    )
  }

  return (
    <MainLayout className="create-aba-page" title="Create Activity Based Audience">
      <LoadingContainer />
      <Alert
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <Box className="page-header">
        <h3>Create Activity Based Audience</h3>
        <p className="title-note">Create an audience based on your store visitors' activity</p>
      </Box>

      <Formik
        enableReinitialize
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={CreateAbaValidationSchema}
      >
        {props => renderForm(props)}
      </Formik>
    </MainLayout>
  );
}