import React from 'react';
import { useDispatch } from 'react-redux';
import StoreActions from '../../redux/store/index';
import global from '../../redux/GlobalRedux';
import MainLayout from '../../containers/layout/MainLayout';
import { Formik, Form, Field } from 'formik';
import { AddShopifySchema } from './schema';
import { LoadingContainer, LoadingButton, Alert } from '../../components/common';
import {
  Box,
  InputAdornment
} from '@material-ui/core';
import { FormBootstrapInput } from '../../components/form';
import shopify from '../../libs/shopify';

const INITIAL_VALUES = {
  storeUrl: ''
};

export default function AddShopify() {
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleSubmit = async (params, { setSubmitting }) => {
    const shop = `${params.storeUrl}.myshopify.com`;

    return shopify.add(shop).then(async res => {
      await dispatch(StoreActions.addUserToStore(shop, res.storeId));
      window.location.href = '/my-stores';
    }, err => {
      dispatch(global.setAlert({
        message: err.message,
        severity: "error"
      }));
    });
  };

  const renderForm = (props) => {
    const { isSubmitting } = props;

    return (
      <Form>
        <Box className="form-header">
          <h1>Enter your Shopify URL <img src="/images/shopify.png" alt="Shopify Logo" /></h1>
          <p className="title-note">If your store is <b>https://awesome-stuff.myshopify.com</b>, just enter <b>awesome-stuff</b>. You will automatically start on a free 14-day trial after approving the installation from Shopify.</p>
        </Box>

        <div className="form-control store large">
          <label className="form-label" htmlFor="storeUrl"></label>
          <Field
            component={FormBootstrapInput}
            name="storeUrl"
            InputProps={{
              endAdornment: <InputAdornment position="end">.myshopify.com</InputAdornment>,
            }}
          />
        </div>
        <div className="form-control form-submit store">
          <LoadingButton loading={isSubmitting} size="large" type="submit" fullWidth={true}>CONNECT</LoadingButton>
        </div>
        <div className="form-footer">
          <p>or <a href="https://apps.shopify.com/synctap-1" target="_blank" rel="noopener noreferrer">install directly</a> from the Shopify App Store <img src="/images/shopify.png" alt="Shopify Logo" /></p>
        </div>
      </Form>
    )
  }

  return (
    <MainLayout
      pclass="align-center bg-gray"
      className="box add-shopify-page"
      title="Enter your Shopify URL"
    >
      <LoadingContainer />
      <Alert
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />

      <Formik
        enableReinitialize
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={AddShopifySchema}
      >
        {props => renderForm(props)}
      </Formik>
    </MainLayout>
  );
}