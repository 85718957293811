import React from 'react';
import { Field } from 'formik';
import {
  FormHelperText,
  Chip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BootstrapInput } from '../../../components/common';

export default function CustomAudienceSelect(props) {
  const { value, options, setFieldValue, errors, touched } = props;

  return (
    <div className="form-control autocomplete">
      <label>Select custom audience</label>
      <Field
        disablePortal
        disabled={options === null}
        component={Autocomplete}
        name='custom-audience'
        id="custom-audience-select"
        className={errors.customAudience ? 'Mui-error' : ''}
        value={value}
        style={{ width: 500 }}
        options={options}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option && `${option.name} - ${option.id}`}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => <Chip
            label={`${option.name} - ${option.id}`}
            {...getTagProps({ index })}
          />)
        }
        renderInput={(params) => (
          <BootstrapInput
            {...params}
            placeholder="Custom Audiences"
            InputLabelProps={{
              shrink: false
            }}
          />
        )}
        onChange={ (__, value) => {
          setFieldValue("customAudience", value);
        }}
      />
      {errors.customAudience && touched.customAudience && <FormHelperText error>Custom audience is required!</FormHelperText>}

    </div>
  );
}
