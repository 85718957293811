import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as ReactLink } from "react-router-dom";
import { ConfirmDialog, LoadingContainer } from "../../../components/common";
import { GlobalSelectors } from "../../../redux/GlobalRedux";
import AudienceActions, { AudienceSelectors } from "../../../redux/audience";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Link,
} from "@material-ui/core";
import { DialogInitialOpenState } from "../../../libs/const";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Platform } from ".";
import { formatDate } from "../../../libs/utils";
import ReactTooltip from "react-tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { getItem } from "../../../libs/storage-wrapper";

export default function CbaList(props) {
  const dispatch = useDispatch();
  const refresh = useSelector(GlobalSelectors.selectDataRefresh);
  const rows = useSelector(AudienceSelectors.selectAudiences);
  const { setOpenSnackbar } = props;

  useEffect(() => {
    const loadAudiences = () => {
      dispatch(AudienceActions.loadAudiences());
    };

    loadAudiences();
  }, [dispatch, refresh]);

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(DialogInitialOpenState);
  const handleOpenDialog =
    (name, row = null) =>
    (event) => {
      setOpenDialog({ ...openDialog, [name]: true });
      if (row) setSelectedRow(row);
    };
  const handleCloseDialog = (name) => () => {
    setOpenDialog({ ...openDialog, [name]: false });
  };

  const deleteAudience = async () => {
    await dispatch(AudienceActions.delete(selectedRow.id));
    handleCloseDialog("delete")();
    setOpenSnackbar(true);
  };

  const renderAudienceSize = (row) => {
    if (row.platforms.includes("Google")) {
      return row.deliveryStatus;
    } else {
      let size = "";

      if (row.approximateCount === 1000) {
        size = "Below 1000";
      } else if (row.approximateCount === null) {
        size = "-";
      } else {
        size = row.approximateCount;
      }

      return size;
    }
  };

  const renderAudienceStatus = (row) => {
    let status = "",
      desc = "";
    if (row.status === "deleted") {
      // Audience is removed on Facebook Audience manager
      desc = `Audience was removed on ${row.platforms.join(", ")}.`;
      status = "deleted";
    } else if (row.status === "error") {
      desc = `There was an error creating this audience on ${row.platforms.join(
        ", "
      )}.`;
      status = "error";
    } else {
      if (row.deliveryStatus) {
        if (row.platforms.includes("Google")) {
          desc = "Ready";
          status = "normal";
        } else if (row.platforms.includes("Facebook")) {
          desc = row.deliveryStatus.description;
          status = row.deliveryStatus.code === 300 ? "small" : "normal";
        }
      } else {
        desc = "Populating";
        status = "populating";
      }
    }

    return (
      <div className={`audience-status ${status}`}>
        <p className="status">{status}</p>
        <InfoIcon
          data-tip={desc}
          data-for="tooltip_audience_status"
          data-html={true}
        />
        <ReactTooltip
          id="tooltip_audience_status"
          place="right"
          type="dark"
          effect="solid"
          clickable
          delayHide={400}
        />
      </div>
    );
  };

  const renderTableRow = (row, i) => (
    <TableRow component="div" key={i}>
      <TableCell component="div" scope="row" align="center">
        {i + 1}
      </TableCell>
      <TableCell component="div" align="left">
        {row.name}
      </TableCell>
      <TableCell component="div" align="left">
        <Platform row={row} />
      </TableCell>
      <TableCell component="div" align="left">
        {renderAudienceStatus(row)}
      </TableCell>
      <TableCell component="div" align="left">
        {renderAudienceSize(row)}
      </TableCell>
      <TableCell component="div" align="left">
        {row.orders || "-"}
      </TableCell>
      <TableCell component="div" align="left">
        {row.aov ? `$${row.aov}` : "-"}
      </TableCell>
      <TableCell component="div" align="left">
        {formatDate(row.syncedAt) || "-"}
      </TableCell>
      {row.platforms.includes("Facebook") && (
        <TableCell component="div" align="left">
          {row.deliveryStatus && row.deliveryStatus.code === 200 && (
            <ReactLink
              to={`/create-lookalike-facebook/?base=${row.customAudienceId}&platform=Facebook`}
            >
              Create LAA
            </ReactLink>
          )}
        </TableCell>
      )}
      {row.platforms.includes("Google") && (
        <TableCell component="div" align="left">
          <ReactLink
            to={`/create-lookalike-google/?base=${row.customAudienceId}&platform=Google`}
          >
            Create Similar Audience
          </ReactLink>
        </TableCell>
      )}
      <TableCell component="div" align="left">
        {row.platforms.includes("Google") && (
          <Link
            href={`https://ads.google.com/aw/audiences/management`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View in Ad Account
          </Link>
        )}
        {row.platforms.includes("Facebook") && (
          <Link
            href={`https://business.facebook.com/adsmanager/audiences?act=${getItem(
              "AdAccountId"
            )}&ids=${row.customAudienceId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View in Ad Account
          </Link>
        )}
      </TableCell>
      <TableCell component="div" align="center" className="row-actions">
        <Box className="action-menu">
          <IconButton
            aria-label="Edit"
            component={ReactLink}
            to={`/my-audiences/${row.id}`}
            title="Edit"
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="Delete"
            onClick={handleOpenDialog("delete", row)}
            title="Delete"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );

  const renderTable = () => (
    <TableContainer component={Paper} elevation={0}>
      <Table className="table no-spacing filled-th" component="div">
        <TableHead component="div">
          <TableRow component="div">
            <TableCell component="div" align="center" width="140px">
              ID
            </TableCell>
            <TableCell component="div" align="left">
              Name
            </TableCell>
            <TableCell component="div" align="left">
              Platforms
            </TableCell>
            <TableCell component="div" align="left">
              Status
            </TableCell>
            <TableCell component="div" align="left">
              Size
            </TableCell>
            <TableCell component="div" align="left">
              Orders
            </TableCell>
            <TableCell component="div" align="left">
              AOV
            </TableCell>
            <TableCell component="div" align="left">
              Last Sync
            </TableCell>
            <TableCell component="div" align="left"></TableCell>
            <TableCell component="div" align="left"></TableCell>
            <TableCell component="div">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody component="div">
          {rows.map((row, i) => renderTableRow(row, i))}
        </TableBody>
      </Table>
      {!rows.length && <div className="no-rows">No Data Found</div>}
    </TableContainer>
  );

  return (
    <div className="cba-list">
      <LoadingContainer />

      {renderTable()}

      {openDialog.delete && (
        <ConfirmDialog
          handleCloseDialog={handleCloseDialog("delete")}
          handleConfirmDialog={deleteAudience}
          confirmButton="Delete"
          confirmColor="secondary"
          title="Delete Audience"
          content="Are you sure you want to permanently delete this audience?<br/>When you delete a Custom Audience, it'll be permanently removed from your Facebook ad account and your ads using it will stop running. You won't be able to restart any ads that used this audience in the past."
        />
      )}
    </div>
  );
}
